import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
var ParecerComponent = /** @class */ (function () {
    function ParecerComponent(imagemService, pessoaService, casaComunitariaService, missionarioMissaoAtualService, questionarioFormandoBuilder, questionarioFormandoService, parecerResponsavelBuilder, parecerResponsavelService, route, router, swtAlert2Service) {
        this.imagemService = imagemService;
        this.pessoaService = pessoaService;
        this.casaComunitariaService = casaComunitariaService;
        this.missionarioMissaoAtualService = missionarioMissaoAtualService;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelBuilder = parecerResponsavelBuilder;
        this.parecerResponsavelService = parecerResponsavelService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.pessoaBuilder = new PessoaBuilder();
        this.membrosCasaComunitaria = new Array();
        this.d2sCasaComunitaria = new Array();
        this.busca = {
            casaComunitariaId: null,
            formaVidaId: null,
            pageNumber: 0,
            quantityOfElements: 40
        };
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
    }
    ParecerComponent.prototype.ngOnInit = function () {
        this.initializer();
    };
    ParecerComponent.prototype.initializer = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.carregarDadosDeDetalhamentoDaMissaoAtualDoUsuarioLogado()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.carregarCasaComunitaria()];
                    case 2:
                        _a.sent();
                        if (!(this.casaComunitaria.id != undefined)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.carregarMembrosCasaComunitaria()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ParecerComponent.prototype.carregarMembrosCasaComunitaria = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, i, blogImage, questionarioFormando, parecerResponsavelAtual, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 10, , 11]);
                        this.busca.casaComunitariaId = this.casaComunitaria.id;
                        return [4 /*yield*/, this.pessoaService.buscarMembrosCasaComunitaria(this.busca)];
                    case 1:
                        response = _a.sent();
                        this.membrosCasaComunitaria = response.entity;
                        this.totalDePaginas = response.quantity;
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.membrosCasaComunitaria.length)) return [3 /*break*/, 9];
                        if (!(this.membrosCasaComunitaria[i].nivelFormativoNome == 'D2' || this.membrosCasaComunitaria[i].nivelFormativoNome == 'D2 Ext.')) return [3 /*break*/, 8];
                        if (!(this.membrosCasaComunitaria[i].imagemId != null)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getPessoaImagem(this.membrosCasaComunitaria[i].imagemId)];
                    case 3:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.membrosCasaComunitaria[i]);
                        _a.label = 4;
                    case 4: return [4 /*yield*/, this.carregarQuestionario(this.membrosCasaComunitaria[i].pessoaId)];
                    case 5:
                        questionarioFormando = _a.sent();
                        if (!(questionarioFormando != null)) return [3 /*break*/, 7];
                        this.membrosCasaComunitaria[i].statusQuestionario = questionarioFormando.status;
                        this.membrosCasaComunitaria[i].idQuestionario = questionarioFormando.id;
                        return [4 /*yield*/, this.carregarParecer(questionarioFormando.id)];
                    case 6:
                        parecerResponsavelAtual = _a.sent();
                        if (parecerResponsavelAtual != null) {
                            this.membrosCasaComunitaria[i].statusParecer = parecerResponsavelAtual.status;
                            this.membrosCasaComunitaria[i].idParecer = parecerResponsavelAtual.id;
                        }
                        else {
                            this.membrosCasaComunitaria[i].statusParecer = 'Não iniciado';
                            this.membrosCasaComunitaria[i].idParecer = null;
                        }
                        _a.label = 7;
                    case 7:
                        if (questionarioFormando == null) {
                            this.membrosCasaComunitaria[i].statusQuestionario = 'Não iniciado';
                            this.membrosCasaComunitaria[i].idQuestionario = null;
                        }
                        this.d2sCasaComunitaria.push(this.membrosCasaComunitaria[i]);
                        console.log(this.d2sCasaComunitaria);
                        _a.label = 8;
                    case 8:
                        i++;
                        return [3 /*break*/, 2];
                    case 9: return [3 /*break*/, 11];
                    case 10:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 11];
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    ParecerComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerComponent.prototype.carregarDadosDeDetalhamentoDaMissaoAtualDoUsuarioLogado = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.missionarioMissaoAtualService.detalhamentoPorUsuarioLogado()];
                    case 1:
                        response = _a.sent();
                        this.dadosUsuarioLogado = response;
                        if (this.casaComunitariaId == undefined || this.casaComunitariaId == 0) {
                            this.casaComunitariaId = this.dadosUsuarioLogado.casaComunitariaId;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_1.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerComponent.prototype.carregarCasaComunitaria = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!(this.casaComunitariaId != undefined)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.casaComunitariaService.find(this.casaComunitariaId)];
                    case 1:
                        response = _a.sent();
                        this.casaComunitaria = response.entity;
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_2.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ParecerComponent.prototype.carregarQuestionario = function (pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.questionarioFormandoService.buscarPorPessoaId(pessoaId)];
                    case 1:
                        response = _a.sent();
                        if (response != undefined && response != null) {
                            return [2 /*return*/, response];
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerComponent.prototype.carregarParecer = function (idQuestionario) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.parecerResponsavelService.buscarPorQuestionarioFormandoId(idQuestionario)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                    case 2:
                        err_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerComponent.prototype.cadastrarVinculoQuestionarioD2 = function (pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.questionarioFormando.anoFormativoId = 2;
                        this.questionarioFormando.questionarioId = 1;
                        this.questionarioFormando.pessoaId = pessoaId;
                        return [4 /*yield*/, this.questionarioFormandoService.cadastrar(this.questionarioFormando)];
                    case 1:
                        response = _a.sent();
                        if (response != undefined && response != null) {
                            this.questionarioFormando = response;
                            this.cadastrarVinculoParecerD2(this.questionarioFormando.id);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_5.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerComponent.prototype.cadastrarVinculoQuestionarioD2Ext = function (pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.questionarioFormando.anoFormativoId = 2;
                        this.questionarioFormando.questionarioId = 2;
                        this.questionarioFormando.pessoaId = pessoaId;
                        return [4 /*yield*/, this.questionarioFormandoService.cadastrar(this.questionarioFormando)];
                    case 1:
                        response = _a.sent();
                        if (response != undefined && response != null) {
                            this.questionarioFormando = response;
                            this.cadastrarVinculoParecerD2(this.questionarioFormando.id);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_6 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_6.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerComponent.prototype.cadastrarVinculoParecerD2 = function (questionarioFormandoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.parecerResponsavel.questionarioFormandoId = questionarioFormandoId;
                        this.parecerResponsavel.parecer_id = 1;
                        this.parecerResponsavel.pessoaResponsavelId = this.dadosUsuarioLogado.pessoaId;
                        return [4 /*yield*/, this.parecerResponsavelService.cadastrar(this.parecerResponsavel)];
                    case 1:
                        response = _a.sent();
                        if (response != undefined && response != null) {
                            this.parecerResponsavel = response;
                            this.router.navigate(['/secured/parecerd2-answer/' + this.parecerResponsavel.id]);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_7 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_7.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerComponent.prototype.consultarVinculoQuestionarioD2 = function (formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!(formando.idQuestionario != null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.parecerResponsavelService.buscarPorQuestionarioFormandoId(formando.idQuestionario)];
                    case 1:
                        response = _a.sent();
                        if (response != undefined && response != null) {
                            this.parecerResponsavel = response;
                            this.router.navigate(['/secured/parecerd2-answer/' + this.parecerResponsavel.id]);
                        }
                        else {
                            this.cadastrarVinculoParecerD2(formando.idQuestionario);
                        }
                        _a.label = 2;
                    case 2:
                        if (formando.idQuestionario == null) {
                            this.cadastrarVinculoQuestionarioD2(formando.pessoaId);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_8 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_8.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ParecerComponent.prototype.cliqueNoBotaoListener = function (botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.carregarMembrosCasaComunitaria();
    };
    return ParecerComponent;
}());
export { ParecerComponent };
