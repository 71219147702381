import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import "select2";
var DadosVocacionaisEditComponent = /** @class */ (function () {
    function DadosVocacionaisEditComponent(missaoService, casaComunitariaService, usuarioService, pessoaService, paisService, estadoService, cidadeService, elementoGrupoDominioService, filhoService, dadoVocacionalService, celibatarioService, apostoladoService, historicoMissionarioMissaoService, historicoMissionarioMissaoApostoladoService, relacionamentoService, sacerdoteService, pessoaBuilder, pessoaInfoBuilder, filhoBuilder, dadoVocacionalBuilder, celibatarioBuilder, apostoladoBuilder, historicoMissionarioMissaoBuilder, historicoMissionarioMissaoApostoladoBuilder, relacionamentoBuilder, sacerdoteBuilder, missionarioMissaoAtualService, salvarMissionarioMissaoAtualBuilder, dadosDetalhementoMissionarioMissaoAtualBuilder, swtAlert2Service, route, router) {
        this.missaoService = missaoService;
        this.casaComunitariaService = casaComunitariaService;
        this.usuarioService = usuarioService;
        this.pessoaService = pessoaService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.filhoService = filhoService;
        this.dadoVocacionalService = dadoVocacionalService;
        this.celibatarioService = celibatarioService;
        this.apostoladoService = apostoladoService;
        this.historicoMissionarioMissaoService = historicoMissionarioMissaoService;
        this.historicoMissionarioMissaoApostoladoService = historicoMissionarioMissaoApostoladoService;
        this.relacionamentoService = relacionamentoService;
        this.sacerdoteService = sacerdoteService;
        this.pessoaBuilder = pessoaBuilder;
        this.pessoaInfoBuilder = pessoaInfoBuilder;
        this.filhoBuilder = filhoBuilder;
        this.dadoVocacionalBuilder = dadoVocacionalBuilder;
        this.celibatarioBuilder = celibatarioBuilder;
        this.apostoladoBuilder = apostoladoBuilder;
        this.historicoMissionarioMissaoBuilder = historicoMissionarioMissaoBuilder;
        this.historicoMissionarioMissaoApostoladoBuilder = historicoMissionarioMissaoApostoladoBuilder;
        this.relacionamentoBuilder = relacionamentoBuilder;
        this.sacerdoteBuilder = sacerdoteBuilder;
        this.missionarioMissaoAtualService = missionarioMissaoAtualService;
        this.salvarMissionarioMissaoAtualBuilder = salvarMissionarioMissaoAtualBuilder;
        this.dadosDetalhementoMissionarioMissaoAtualBuilder = dadosDetalhementoMissionarioMissaoAtualBuilder;
        this.swtAlert2Service = swtAlert2Service;
        this.route = route;
        this.router = router;
        this.formaVidaSelecionadaId = null;
        this.estadoVidaSelecionadoId = null;
        this.paisSelecionadoId = null;
        this.estadoSelecionadoId = null;
        this.missaoSelecionadaId = null;
        this.tipoApostoladoSelecionadoId = null;
        this.pessoa = this.pessoaBuilder.getInstance();
        this.filho = this.filhoBuilder.getInstance();
        this.pessoaPaiSelecionado = this.pessoaInfoBuilder.getInstance();
        this.pessoaMaeSelecionado = this.pessoaInfoBuilder.getInstance();
        this.pessoaFilhoSelecionado = this.pessoaInfoBuilder.getInstance();
        this.pessoaFormadorPessoalSelecionado =
            this.pessoaInfoBuilder.getInstance();
        this.pessoaRelacionamentoSelecionada = this.pessoaInfoBuilder.getInstance();
        this.dadoVocacional = this.dadoVocacionalBuilder.getInstance();
        this.celibatario = this.celibatarioBuilder.getInstance();
        this.apostolado = this.apostoladoBuilder.getInstance();
        this.historicoMissionarioMissao =
            this.historicoMissionarioMissaoBuilder.getInstance();
        this.historicoMissionarioMissaoApostolado =
            this.historicoMissionarioMissaoApostoladoBuilder.getInstance();
        this.relacionamento = this.relacionamentoBuilder.getInstance();
        this.sacerdote = this.sacerdoteBuilder.getInstance();
        this.filhos = new Array();
        this.paises = new Array();
        this.estados = new Array();
        this.cidades = new Array();
        this.apostolados = new Array();
        this.historicosMissionarioMissao = new Array();
        this.historicosMissionarioMissaoApostolados =
            new Array();
        this.salvarMissionarioMissaoAtual =
            this.salvarMissionarioMissaoAtualBuilder.getInstance();
        this.dadosDetalhamentoMissionarioMissaoAtual =
            this.dadosDetalhementoMissionarioMissaoAtualBuilder.getInstance();
    }
    DadosVocacionaisEditComponent.prototype.ngOnInit = function () {
        this.initialization();
    };
    DadosVocacionaisEditComponent.prototype.initialization = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.carregarMissoes();
                        this.carregarFormasVida();
                        this.carregarEstadoVida();
                        this.carregarNivelFormacao();
                        this.carregarTipoRelacionamento();
                        this.carregarPaises();
                        this.carregarTipoApostolado();
                        this.carregarApostolados();
                        return [4 /*yield*/, this.getUsuarioAtual()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.carregarPessoa()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.carregarDadoVocacional()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.carregarCelibatario()];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, this.carregarRelacionamento()];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, this.carregarSacerdote()];
                    case 6:
                        _a.sent();
                        this.carregarFilhos();
                        this.carregarHistoricoMissionarioMissao();
                        return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.carregarMissoes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.missaoService.getAllAnyStatus()];
                    case 1:
                        response = _a.sent();
                        this.missoes = response.entity;
                        this.missoes = this.missoes.sort(function (a, b) {
                            return a.nome.localeCompare(b.nome, "fi");
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.carregarCasasComunitarias = function (missaoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.casaComunitariaService.porMissao(missaoId)];
                    case 1:
                        response = _a.sent();
                        this.casasComunitarias = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.carregarFormasVida = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = {
                            grupoNome: "FORMA_VIDA",
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request)];
                    case 2:
                        response = _a.sent();
                        this.formasVida = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.carregarEstadoVida = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = {
                            grupoNome: "ESTADO_VIDA",
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request)];
                    case 2:
                        response = _a.sent();
                        this.estadosVida = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        err_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.carregarDadoVocacional = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.dadoVocacionalService.porPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response.entity != undefined && response.entity != null) {
                            this.dadoVocacional = response.entity;
                            this.pessoaFormadorPessoalSelecionado =
                                this.pessoaInfoBuilder.getInstance();
                            this.pessoaFormadorPessoalSelecionado.nome =
                                this.dadoVocacional.formadorPessoalPessoaNome;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_5.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.carregarCelibatario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.celibatarioService.porPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response.entity != undefined && response.entity != null) {
                            this.celibatario = response.entity;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_6 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_6.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.carregarRelacionamento = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.relacionamentoService.porPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response.entity != undefined && response.entity != null) {
                            this.relacionamento = response.entity;
                            this.pessoaRelacionamentoSelecionada =
                                this.pessoaInfoBuilder.getInstance();
                            if (this.relacionamento.pessoa1Id == this.pessoa.id) {
                                this.pessoaRelacionamentoSelecionada.nome =
                                    this.relacionamento.pessoa2Nome;
                            }
                            else if (this.relacionamento.pessoa2Id == this.pessoa.id) {
                                this.pessoaRelacionamentoSelecionada.nome =
                                    this.relacionamento.pessoa1Nome;
                            }
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_7 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_7.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.carregarSacerdote = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.sacerdoteService.porPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response.entity != undefined && response.entity != null) {
                            this.sacerdote = response.entity;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_8 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_8.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.carregarHistoricoMissionarioMissao = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.historicoMissionarioMissaoService.porPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response.entity != undefined && response.entity != null) {
                            this.historicosMissionarioMissao = response.entity;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_9 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_9.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.carregarHistoricoMissionarioMissaoApostolado = function (historicoMissionarioMissaoID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_10;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.historicoMissionarioMissaoApostoladoService.findByHistoricoMissionarioMissaoId(historicoMissionarioMissaoID)];
                    case 1:
                        response = _a.sent();
                        if (response.entity != undefined && response.entity != null) {
                            this.historicosMissionarioMissaoApostolados = response.entity;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_10 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_10.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.carregarNivelFormacao = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, err_11;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = {
                            grupoNome: "NIVEL_FORMACAO",
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request)];
                    case 2:
                        response = _a.sent();
                        this.niveisFormacao = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        err_11 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_11.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.carregarTipoRelacionamento = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, err_12;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = {
                            grupoNome: "TIPO_RELACIONAMENTO",
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request)];
                    case 2:
                        response = _a.sent();
                        this.tiposRelacionamento = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        err_12 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_12.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.carregarTipoApostolado = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, err_13;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = {
                            grupoNome: "TIPO_APOSTOLADO",
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request)];
                    case 2:
                        response = _a.sent();
                        this.tiposApostolado = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        err_13 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_13.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.carregarApostolados = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, err_14;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = {
                            ativo: null,
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apostoladoService.pesquisar(request)];
                    case 2:
                        response = _a.sent();
                        this.apostolados = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        err_14 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_14.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.carregarApostoladosPorTipo = function (tipoApostoladoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.apostoladosSelecionados = this.apostolados.filter(function (a) { return a.tipoId == tipoApostoladoId; });
                return [2 /*return*/];
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.getUsuarioAtual = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_15;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuarioAtual = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        err_15 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_15.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.carregarPessoa = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_16;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.pessoaService.buscaPorUsuarioId(this.usuarioAtual.id)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        this.formaVidaSelecionadaId = this.pessoa.formaVidaId;
                        this.estadoVidaSelecionadoId = this.pessoa.estadoVidaId;
                        return [3 /*break*/, 3];
                    case 2:
                        err_16 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_16.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.carregarFilhos = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_17;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 7, , 8]);
                        response = null;
                        if (!(this.pessoa.genero == "masculino")) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.filhoService.porPaiId(this.pessoa.id)];
                    case 1: return [4 /*yield*/, _a.sent()];
                    case 2:
                        response = _a.sent();
                        return [3 /*break*/, 6];
                    case 3:
                        if (!(this.pessoa.genero == "feminino")) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.filhoService.porMaeId(this.pessoa.id)];
                    case 4: return [4 /*yield*/, _a.sent()];
                    case 5:
                        response = _a.sent();
                        _a.label = 6;
                    case 6:
                        this.filhos = response.entity;
                        if (this.filhos.length > 0) {
                            this.hasFilho = true;
                        }
                        return [3 /*break*/, 8];
                    case 7:
                        err_17 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_17.error.errors);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.atualizarFormaVida = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, err_18;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        request = {
                            pessoaId: this.pessoa.id,
                            elementoGrupoDominioFormaVidaId: this.formaVidaSelecionadaId,
                        };
                        return [4 /*yield*/, this.pessoaService.atualizarFormaVida(request)];
                    case 1:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        return [4 /*yield*/, this.carregarPessoa()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_18 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_18.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.atualizarEstadoVida = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, err_19;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        request = {
                            pessoaId: this.pessoa.id,
                            elementoGrupoDominioEstadoVidaId: this.estadoVidaSelecionadoId,
                        };
                        return [4 /*yield*/, this.pessoaService.atualizarEstadoVida(request)];
                    case 1:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        return [4 /*yield*/, this.carregarPessoa()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_19 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_19.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.atualizarDadoVocacional = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, err_20;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!(this.dadoVocacional.id == null)) return [3 /*break*/, 2];
                        this.dadoVocacional.pessoaId = this.pessoa.id;
                        return [4 /*yield*/, this.dadoVocacionalService.create(this.dadoVocacional)];
                    case 1:
                        response = _a.sent();
                        this.dadoVocacional = response.entity;
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.dadoVocacionalService.update(this.dadoVocacional.id, this.dadoVocacional)];
                    case 3:
                        response = _a.sent();
                        this.dadoVocacional = response.entity;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_20 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_20.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.atualizarCelibatario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, err_21;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!(this.celibatario.id == null)) return [3 /*break*/, 2];
                        this.celibatario.pessoaId = this.pessoa.id;
                        return [4 /*yield*/, this.celibatarioService.create(this.celibatario)];
                    case 1:
                        response = _a.sent();
                        this.celibatario = response.entity;
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.celibatarioService.update(this.celibatario.id, this.celibatario)];
                    case 3:
                        response = _a.sent();
                        this.celibatario = response.entity;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_21 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_21.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.atualizarRelacionamento = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, err_22;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        if (!(this.relacionamento.pessoa2IsMembro == null ||
                            this.relacionamento.tipoRelacionamentoId == null)) return [3 /*break*/, 1];
                        this.swtAlert2Service.warningAlert("Preencha todos os campos do formulário, incluindo pessoa, tipo de relacionamento e data(s) do relacionamento.");
                        return [3 /*break*/, 5];
                    case 1:
                        if (!(this.relacionamento.id == null)) return [3 /*break*/, 3];
                        this.relacionamento.pessoa1Id = this.pessoa.id;
                        this.relacionamento.pessoa1IsMembro = true;
                        return [4 /*yield*/, this.relacionamentoService.create(this.relacionamento)];
                    case 2:
                        response = _a.sent();
                        this.relacionamento = response.entity;
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.relacionamentoService.update(this.relacionamento.id, this.relacionamento)];
                    case 4:
                        response = _a.sent();
                        this.relacionamento = response.entity;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_22 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_22.error.errors);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.atualizarSacerdote = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, err_23;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!(this.sacerdote.id == null)) return [3 /*break*/, 2];
                        this.sacerdote.pessoaId = this.pessoa.id;
                        return [4 /*yield*/, this.sacerdoteService.create(this.sacerdote)];
                    case 1:
                        response = _a.sent();
                        this.sacerdote = response.entity;
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.sacerdoteService.update(this.sacerdote.id, this.sacerdote)];
                    case 3:
                        response = _a.sent();
                        this.sacerdote = response.entity;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_23 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_23.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.carregarPaises = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.paisService.findAll()];
                    case 1:
                        response = _a.sent();
                        this.paises = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_1.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.carregarEstados = function (paisId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        request = {
                            paisId: paisId,
                            nome: undefined,
                            pageNumber: 0,
                            quantityOfElements: 1000,
                        };
                        return [4 /*yield*/, this.estadoService.buscarEstados(request)];
                    case 1:
                        response = _a.sent();
                        this.estados = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.carregarCidades = function (estadoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        request = {
                            estadoId: estadoId,
                            nome: undefined,
                            pageNumber: 0,
                            quantityOfElements: 1000,
                        };
                        return [4 /*yield*/, this.cidadeService.buscarCidades(request)];
                    case 1:
                        response = _a.sent();
                        this.cidades = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.salvarFilho = function (filho) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (this.pessoa.genero == "masculino") {
                            filho.paiId = this.pessoa.id;
                        }
                        else if (this.pessoa.genero == "feminino") {
                            filho.maeId = this.pessoa.id;
                        }
                        if (filho.isMembro == true) {
                            filho.filhoId = this.pessoaFilhoSelecionado.id;
                        }
                        return [4 /*yield*/, this.filhoService.create(filho)];
                    case 1:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        this.filho = this.filhoBuilder.getInstance();
                        this.pessoaPaiSelecionado = this.pessoaInfoBuilder.getInstance();
                        this.pessoaMaeSelecionado = this.pessoaInfoBuilder.getInstance();
                        this.pessoaFilhoSelecionado = this.pessoaInfoBuilder.getInstance();
                        this.maeIsMembro = false;
                        this.paiIsMembro = false;
                        return [4 /*yield*/, this.carregarFilhos()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_4.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.salvarHistoricoMissionarioMissao = function (historicoMissionarioMissao) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        if (historicoMissionarioMissao.id == null) {
                            historicoMissionarioMissao.missaoId = this.missaoSelecionadaId;
                        }
                        response = null;
                        if (!(historicoMissionarioMissao.id == null)) return [3 /*break*/, 2];
                        historicoMissionarioMissao.pessoaId = this.pessoa.id;
                        return [4 /*yield*/, this.historicoMissionarioMissaoService.create(historicoMissionarioMissao)];
                    case 1:
                        response = _a.sent();
                        this.historicoMissionarioMissao =
                            this.historicoMissionarioMissaoBuilder.getInstance();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.historicoMissionarioMissaoService.update(historicoMissionarioMissao.id, historicoMissionarioMissao)];
                    case 3:
                        response = _a.sent();
                        _a.label = 4;
                    case 4:
                        this.swtAlert2Service.successAlert(response.message);
                        return [4 /*yield*/, this.carregarHistoricoMissionarioMissao()];
                    case 5:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        e_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_5.error.errors);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.salvarHistoricoMissionarioMissaoApostolado = function (historicoMissionarioMissaoApostolado) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var historicoMissionarioMissaoId, response, e_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(historicoMissionarioMissaoApostolado.apostoladoId != null)) return [3 /*break*/, 9];
                        if (!(historicoMissionarioMissaoApostolado.duracaoAnos == null ||
                            historicoMissionarioMissaoApostolado.duracaoMeses == null)) return [3 /*break*/, 1];
                        this.swtAlert2Service.warningAlert("Informe a duração de anos e meses. Caso não saiba o período completo, coloque o tempo aproximado.");
                        return [3 /*break*/, 8];
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        historicoMissionarioMissaoId = historicoMissionarioMissaoApostolado.historicoMissionarioMissaoId;
                        response = null;
                        if (!(historicoMissionarioMissaoApostolado.id != null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.historicoMissionarioMissaoApostoladoService.update(historicoMissionarioMissaoApostolado.id, historicoMissionarioMissaoApostolado)];
                    case 2:
                        response =
                            _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.historicoMissionarioMissaoApostoladoService.create(historicoMissionarioMissaoApostolado)];
                    case 4:
                        response =
                            _a.sent();
                        _a.label = 5;
                    case 5:
                        this.swtAlert2Service.successAlert(response.message);
                        return [4 /*yield*/, this.carregarHistoricoMissionarioMissaoApostolado(historicoMissionarioMissaoId)];
                    case 6:
                        _a.sent();
                        this.historicoMissionarioMissaoApostolado =
                            this.historicoMissionarioMissaoApostoladoBuilder.getInstance();
                        this.historicoMissionarioMissaoApostolado.historicoMissionarioMissaoId =
                            historicoMissionarioMissaoId;
                        return [3 /*break*/, 8];
                    case 7:
                        e_6 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_6.error.errors);
                        return [3 /*break*/, 8];
                    case 8: return [3 /*break*/, 10];
                    case 9:
                        this.swtAlert2Service.warningAlert("Preencha os dados do formulário antes de salvar o apostolado.");
                        _a.label = 10;
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.deletarFilho = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!confirm("Deseja deletar esse filho?")) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.filhoService.delete(id)];
                    case 2:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        this.carregarFilhos();
                        return [3 /*break*/, 4];
                    case 3:
                        e_7 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_7.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.deletarCelibatario = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!confirm("Deseja deletar os dados do celibato?")) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.celibatarioService.delete(id)];
                    case 2:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        this.celibatario = this.celibatarioBuilder.getInstance();
                        this.carregarCelibatario();
                        return [3 /*break*/, 4];
                    case 3:
                        e_8 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_8.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.deletarRelacionamento = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!confirm("Deseja deletar os dados do relacionamento?")) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.relacionamentoService.delete(id)];
                    case 2:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        this.relacionamento = this.relacionamentoBuilder.getInstance();
                        this.pessoaRelacionamentoSelecionada =
                            this.pessoaInfoBuilder.getInstance();
                        this.carregarRelacionamento();
                        return [3 /*break*/, 4];
                    case 3:
                        e_9 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_9.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.deletarSacerdote = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_10;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!confirm("Deseja deletar os dados do sacerdócio?")) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.sacerdoteService.delete(id)];
                    case 2:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        this.sacerdote = this.sacerdoteBuilder.getInstance();
                        this.carregarSacerdote();
                        return [3 /*break*/, 4];
                    case 3:
                        e_10 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_10.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.deletarHistoricoMissionarioMissao = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_11;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!confirm("Deseja deletar os dados de histórico na missão?")) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.historicoMissionarioMissaoService.delete(id)];
                    case 2:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        this.carregarHistoricoMissionarioMissao();
                        return [3 /*break*/, 4];
                    case 3:
                        e_11 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_11.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.deletarHistoricoMissionarioMissaoApostolado = function (id, historicoMissionarioMissaoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_12;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!confirm("Deseja deletar os dados de histórico deste apostolado na missão?")) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.historicoMissionarioMissaoApostoladoService.delete(id)];
                    case 2:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        this.carregarHistoricoMissionarioMissaoApostolado(historicoMissionarioMissaoId);
                        return [3 /*break*/, 4];
                    case 3:
                        e_12 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_12.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.selecionarPai = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.filho.paiId = pessoa.id;
                this.pessoaPaiSelecionado = pessoa;
                $("#listagem-pessoa-pai").modal("hide");
                return [2 /*return*/];
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.selecionarMae = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.filho.maeId = pessoa.id;
                this.pessoaMaeSelecionado = pessoa;
                $("#listagem-pessoa-mae").modal("hide");
                return [2 /*return*/];
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.selecionarFilho = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.filho.filhoId = pessoa.id;
                this.pessoaFilhoSelecionado = pessoa;
                $("#listagem-pessoa-filho").modal("hide");
                return [2 /*return*/];
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.selecionarFormadorPessoal = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
                this.pessoaFormadorPessoalSelecionado = pessoa;
                $("#listagem-pessoa-formador-pessoal").modal("hide");
                return [2 /*return*/];
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.selecionarPessoaRelacionamento = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.relacionamento.pessoa2Id = pessoa.id;
                this.relacionamento.pessoa2IsMembro = true;
                this.pessoaRelacionamentoSelecionada = pessoa;
                $("#listagem-pessoa-relacionamento").modal("hide");
                return [2 /*return*/];
            });
        });
    };
    DadosVocacionaisEditComponent.prototype.configModalHistoricoApostolado = function (historicoMissionarioMissaoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.historicoMissionarioMissaoApostolado.historicoMissionarioMissaoId =
                    historicoMissionarioMissaoId;
                this.carregarHistoricoMissionarioMissaoApostolado(historicoMissionarioMissaoId);
                return [2 /*return*/];
            });
        });
    };
    return DadosVocacionaisEditComponent;
}());
export { DadosVocacionaisEditComponent };
