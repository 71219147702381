import * as tslib_1 from "tslib";
import { DadoVocacional } from '../model/dadovocacional.model';
import { AbstractBuilder } from './abstract.builder';
var DadoVocacionalBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(DadoVocacionalBuilder, _super);
    function DadoVocacionalBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DadoVocacionalBuilder.prototype.reset = function () {
        this.entity = new DadoVocacional(null, null, null, null, null, null, null, null, null, null, null);
    };
    return DadoVocacionalBuilder;
}(AbstractBuilder));
export { DadoVocacionalBuilder };
