import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
var ParecerCALFCAnswerComponent = /** @class */ (function () {
    function ParecerCALFCAnswerComponent(wopGetAllPublic, pessoaService, imagemService, paisService, estadoService, cidadeService, elementoGrupoDominioService, elementoGrupoDominioLocalService, missaoService, swtAlert2Service, usuarioService, pessoaBuilder, pessoaInfoBuilder, dadoVocacionalService, dadoFormandoCAService, caminhoFormativoService, respostaParecerService, questionarioFormandoService, parecerResponsavelService, dadoFormandoCABuilder, caminhoFormativoFormandoBuilder, respostaParecerBuilder, questionarioFormandoBuilder, route, router) {
        this.wopGetAllPublic = wopGetAllPublic;
        this.pessoaService = pessoaService;
        this.imagemService = imagemService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.missaoService = missaoService;
        this.swtAlert2Service = swtAlert2Service;
        this.usuarioService = usuarioService;
        this.pessoaBuilder = pessoaBuilder;
        this.pessoaInfoBuilder = pessoaInfoBuilder;
        this.dadoVocacionalService = dadoVocacionalService;
        this.dadoFormandoCAService = dadoFormandoCAService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.respostaParecerService = respostaParecerService;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelService = parecerResponsavelService;
        this.dadoFormandoCABuilder = dadoFormandoCABuilder;
        this.caminhoFormativoFormandoBuilder = caminhoFormativoFormandoBuilder;
        this.respostaParecerBuilder = respostaParecerBuilder;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.route = route;
        this.router = router;
        this.intlTelInputCalled = false;
        this.missoesVinculo = new Array();
        this.missoes = new Array();
        this.formasVida = new Array();
        this.niveisFormacao = new Array();
        this.dadoFormandoCA = this.dadoFormandoCABuilder.getInstance();
        this.caminhoFormativoFormando =
            this.caminhoFormativoFormandoBuilder.getInstance();
        this.imageChangedEvent = "";
        this.croppedImage = "";
        this.nextClicked = false;
        this.pessoaResponsavel = this.pessoaBuilder.getInstance();
        this.formando = this.pessoaBuilder.getInstance();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.url = environment.usuario.selectPorUsername;
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.tabNames = ["dados-pessoais-tab-content", "questionario-tab-content"];
        this.activeTabNumber = 0;
        this.resposta21 = respostaParecerBuilder.getInstance();
        this.resposta22 = respostaParecerBuilder.getInstance();
        this.resposta23 = respostaParecerBuilder.getInstance();
        this.resposta24 = respostaParecerBuilder.getInstance();
        this.resposta25 = respostaParecerBuilder.getInstance();
        this.resposta26 = respostaParecerBuilder.getInstance();
        this.resposta27 = respostaParecerBuilder.getInstance();
        this.resposta28 = respostaParecerBuilder.getInstance();
        this.resposta29 = respostaParecerBuilder.getInstance();
        this.resposta30 = respostaParecerBuilder.getInstance();
        this.resposta31 = respostaParecerBuilder.getInstance();
        this.resposta32 = respostaParecerBuilder.getInstance();
        this.resposta33 = respostaParecerBuilder.getInstance();
        this.resposta34 = respostaParecerBuilder.getInstance();
        this.resposta35 = respostaParecerBuilder.getInstance();
    }
    ParecerCALFCAnswerComponent.prototype.ngOnInit = function () {
        this.initialization();
    };
    ParecerCALFCAnswerComponent.prototype.imprimirValorDoForm = function () {
        console.log("Valor do Form", JSON.stringify(this.dadosPessoaisForm.value));
    };
    ParecerCALFCAnswerComponent.prototype.initialization = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.getUsuarioAtual()];
                    case 1:
                        _b.sent();
                        this.carregarFormasVida();
                        this.carregarNiveisFormacao();
                        return [4 /*yield*/, this.carregarMissoesVinculo()];
                    case 2:
                        _b.sent();
                        this.parecerResponsavelId = Number(this.route.snapshot.paramMap.get("id"));
                        return [4 /*yield*/, this.carregarParecerResponsavel()];
                    case 3:
                        _b.sent();
                        return [4 /*yield*/, this.carregarQuestionarioFormando()];
                    case 4:
                        _b.sent();
                        return [4 /*yield*/, this.carregarPessoaFormando(this.questionarioFormando.pessoaId)];
                    case 5:
                        _b.sent();
                        _a = this;
                        return [4 /*yield*/, this.carregarPessoaResponsavel(this.usuarioAtual.id)];
                    case 6:
                        _a.pessoaResponsavel = _b.sent();
                        return [4 /*yield*/, this.carregarDadoVocacional()];
                    case 7:
                        _b.sent();
                        return [4 /*yield*/, this.carregarDadoFormandoCA()];
                    case 8:
                        _b.sent();
                        return [4 /*yield*/, this.carregarCaminhoFormativo()];
                    case 9:
                        _b.sent();
                        return [4 /*yield*/, this.carregarRespostas()];
                    case 10:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerComponent.prototype.getUsuarioAtual = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuarioAtual = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerComponent.prototype.carregarMissoesVinculo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.wopGetAllPublic.getAllMissao()];
                    case 1:
                        response = _a.sent();
                        this.missoesVinculo = response.entity;
                        this.missoesVinculo = this.missoesVinculo.sort(function (a, b) {
                            return a.nome.localeCompare(b.nome);
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerComponent.prototype.carregarPessoaFormando = function (pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, blogImage, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.pessoaService.buscaPorId(pessoaId)];
                    case 1:
                        response = _a.sent();
                        this.formando = response.entity;
                        this.pessoaFormandoId = this.formando.id;
                        if (!(this.formando.imagemId != null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getPessoaImagem(this.formando.imagemId)];
                    case 2:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.formando);
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerComponent.prototype.carregarPessoaResponsavel = function (usuarioId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.pessoaService.buscaPorUsuarioId(usuarioId)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.entity];
                    case 2:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerComponent.prototype.carregarFormasVida = function () {
        var _this = this;
        var buscaFormaVida = {
            grupoNome: "FORMA_VIDA",
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(buscaFormaVida)
            .then(function (lista) {
            _this.formasVida = lista.entity;
        })
            .catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    ParecerCALFCAnswerComponent.prototype.carregarNiveisFormacao = function () {
        var _this = this;
        var busca = {
            grupoNome: "NIVEL_FORMACAO",
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(busca)
            .then(function (lista) {
            _this.niveisFormacao = lista.entity;
        })
            .catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    ParecerCALFCAnswerComponent.prototype.carregarPaises = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.paisService.findAll()];
                    case 1:
                        response = _a.sent();
                        this.paises = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerComponent.prototype.carregarEstados = function (paisId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        request = {
                            paisId: paisId,
                            nome: undefined,
                            pageNumber: 0,
                            quantityOfElements: 1000,
                        };
                        return [4 /*yield*/, this.estadoService.buscarEstados(request)];
                    case 1:
                        response = _a.sent();
                        this.estados = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerComponent.prototype.carregarCidades = function (estadoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, e_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        request = {
                            estadoId: estadoId,
                            nome: undefined,
                            pageNumber: 0,
                            quantityOfElements: 1000,
                        };
                        return [4 /*yield*/, this.cidadeService.buscarCidades(request)];
                    case 1:
                        response = _a.sent();
                        this.cidades = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_5.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerComponent.prototype.logOnConsole = function (dadosPessoaisForm) {
        console.log(dadosPessoaisForm);
    };
    ParecerCALFCAnswerComponent.prototype.activateTab = function (tab) {
        $('.nav-tabs a[href="#' + tab + '"]').removeClass("disabled");
        $('.nav-tabs a[href="#' + tab + '"]').tab("show");
    };
    ParecerCALFCAnswerComponent.prototype.activateTab2 = function (action) {
        if (action.localeCompare("previous") == 0) {
            if (this.activeTabNumber > 0) {
                this.activeTabNumber -= 1;
            }
        }
        else if (action.localeCompare("next") == 0) {
            if (this.activeTabNumber < this.tabNames.length - 1) {
                this.activeTabNumber += 1;
            }
        }
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').removeClass("disabled");
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').tab("show");
    };
    ParecerCALFCAnswerComponent.prototype.salvar = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    //this.atualizarCaminhoFormativo();
                    return [4 /*yield*/, this.atualizarParecerResponsavel("INICIADO")];
                    case 1:
                        //this.atualizarCaminhoFormativo();
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerComponent.prototype.fileChangeEvent = function (event) {
        this.imageChangedEvent = event;
        console.log(event);
    };
    ParecerCALFCAnswerComponent.prototype.imageCropped = function (event) {
        this.croppedImage = event.base64;
    };
    ParecerCALFCAnswerComponent.prototype.imageLoaded = function () {
        console.log("Imagem carregada");
    };
    ParecerCALFCAnswerComponent.prototype.cropperReady = function () {
        console.log("Imagem cortada");
    };
    ParecerCALFCAnswerComponent.prototype.loadImageFailed = function () {
        console.log("Carregamento da imagem falhou!");
    };
    ParecerCALFCAnswerComponent.prototype.carregarDadoVocacional = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.dadoVocacionalService.porPessoaId(this.formando.id)];
                    case 1:
                        response = _a.sent();
                        if (response.entity != undefined && response.entity != null) {
                            this.dadoVocacional = response.entity;
                            this.pessoaFormadorPessoalSelecionado =
                                this.pessoaInfoBuilder.getInstance();
                            this.pessoaFormadorPessoalSelecionado.nome =
                                this.dadoVocacional.formadorPessoalPessoaNome;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerComponent.prototype.selecionarFormadorComunitario = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    ParecerCALFCAnswerComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerComponent.prototype.carregarDadoFormandoCA = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.dadoFormandoCAService.porPessoaId(this.formando.id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.dadoFormandoCA = response;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_5.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerComponent.prototype.carregarCaminhoFormativo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.caminhoFormativoService.porPessoaId(this.formando.id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.caminhoFormativoFormando = response;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_6 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_6.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerComponent.prototype.atualizarCaminhoFormativo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!(this.caminhoFormativoFormando.id != null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.caminhoFormativoService.atualizar(this.caminhoFormativoFormando)];
                    case 1:
                        response = _a.sent();
                        this.caminhoFormativoFormando = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        err_7 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_7.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerComponent.prototype.carregarRespostas = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_8;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.respostaParecerService.porPessoaId(this.pessoaFormandoId)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.respostas = response.content;
                            this.respostas.forEach(function (resposta) {
                                switch (resposta.parecer_pergunta_id) {
                                    case 21:
                                        _this.resposta21 = resposta;
                                        break;
                                    case 22:
                                        _this.resposta22 = resposta;
                                        break;
                                    case 23:
                                        _this.resposta23 = resposta;
                                        break;
                                    case 24:
                                        _this.resposta24 = resposta;
                                        break;
                                    case 25:
                                        _this.resposta25 = resposta;
                                        break;
                                    case 26:
                                        _this.resposta26 = resposta;
                                        break;
                                    case 27:
                                        _this.resposta27 = resposta;
                                        break;
                                    case 28:
                                        _this.resposta28 = resposta;
                                        break;
                                    case 29:
                                        _this.resposta29 = resposta;
                                        break;
                                    case 30:
                                        _this.resposta30 = resposta;
                                        break;
                                    case 31:
                                        _this.resposta31 = resposta;
                                        break;
                                    case 32:
                                        _this.resposta32 = resposta;
                                        break;
                                    case 33:
                                        _this.resposta33 = resposta;
                                        break;
                                    case 34:
                                        _this.resposta34 = resposta;
                                        break;
                                    case 35:
                                        _this.resposta35 = resposta;
                                        break;
                                }
                            });
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_8 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_8.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerComponent.prototype.carregarParecerResponsavel = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.parecerResponsavelService.buscarPorId(this.parecerResponsavelId)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.parecerResponsavel = response;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_9 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_9.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerComponent.prototype.carregarQuestionarioFormando = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_10;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.questionarioFormandoService.buscarPorId(this.parecerResponsavel.questionarioFormandoId)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.questionarioFormando = response;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_10 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_10.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerComponent.prototype.atualizarResposta = function (resposta) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, err_11;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        resposta.pessoaId = this.pessoaFormandoId;
                        if (!(resposta.id == null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.respostaParecerService.cadastrar(resposta)];
                    case 1:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        return [2 /*return*/, response];
                    case 2: return [4 /*yield*/, this.respostaParecerService.atualizar(resposta.id, resposta)];
                    case 3:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        return [2 /*return*/, response];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_11 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_11.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerComponent.prototype.salvarQuestionario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, caminhoFormativoSugeridoFC;
            return tslib_1.__generator(this, function (_r) {
                switch (_r.label) {
                    case 0:
                        if (!!this.respostaVazia(this.resposta21)) return [3 /*break*/, 2];
                        this.resposta21.parecer_pergunta_id = 21;
                        _a = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta21)];
                    case 1:
                        _a.resposta21 = _r.sent();
                        _r.label = 2;
                    case 2:
                        if (!!this.respostaVazia(this.resposta22)) return [3 /*break*/, 4];
                        this.resposta22.parecer_pergunta_id = 22;
                        _b = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta22)];
                    case 3:
                        _b.resposta22 = _r.sent();
                        _r.label = 4;
                    case 4:
                        if (!!this.respostaVazia(this.resposta23)) return [3 /*break*/, 6];
                        this.resposta23.parecer_pergunta_id = 23;
                        _c = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta23)];
                    case 5:
                        _c.resposta23 = _r.sent();
                        _r.label = 6;
                    case 6:
                        if (!!this.respostaVazia(this.resposta24)) return [3 /*break*/, 8];
                        this.resposta24.parecer_pergunta_id = 24;
                        _d = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta24)];
                    case 7:
                        _d.resposta24 = _r.sent();
                        _r.label = 8;
                    case 8:
                        if (!!this.respostaVazia(this.resposta25)) return [3 /*break*/, 10];
                        this.resposta25.parecer_pergunta_id = 25;
                        _e = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta25)];
                    case 9:
                        _e.resposta25 = _r.sent();
                        _r.label = 10;
                    case 10:
                        if (!!this.respostaVazia(this.resposta26)) return [3 /*break*/, 12];
                        this.resposta26.parecer_pergunta_id = 26;
                        _f = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta26)];
                    case 11:
                        _f.resposta26 = _r.sent();
                        _r.label = 12;
                    case 12:
                        if (!!this.respostaVazia(this.resposta27)) return [3 /*break*/, 14];
                        this.resposta27.parecer_pergunta_id = 27;
                        _g = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta27)];
                    case 13:
                        _g.resposta27 = _r.sent();
                        _r.label = 14;
                    case 14:
                        if (!!this.respostaVazia(this.resposta28)) return [3 /*break*/, 16];
                        this.resposta28.parecer_pergunta_id = 28;
                        _h = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta28)];
                    case 15:
                        _h.resposta28 = _r.sent();
                        _r.label = 16;
                    case 16:
                        if (!!this.respostaVazia(this.resposta29)) return [3 /*break*/, 18];
                        this.resposta29.parecer_pergunta_id = 29;
                        _j = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta29)];
                    case 17:
                        _j.resposta29 = _r.sent();
                        _r.label = 18;
                    case 18:
                        if (!!this.respostaVazia(this.resposta30)) return [3 /*break*/, 20];
                        this.resposta30.parecer_pergunta_id = 30;
                        _k = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta30)];
                    case 19:
                        _k.resposta30 = _r.sent();
                        _r.label = 20;
                    case 20:
                        if (!!this.respostaVazia(this.resposta31)) return [3 /*break*/, 22];
                        this.resposta31.parecer_pergunta_id = 31;
                        _l = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta31)];
                    case 21:
                        _l.resposta31 = _r.sent();
                        _r.label = 22;
                    case 22:
                        if (!!this.respostaVazia(this.resposta32)) return [3 /*break*/, 24];
                        this.resposta32.parecer_pergunta_id = 32;
                        _m = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta32)];
                    case 23:
                        _m.resposta32 = _r.sent();
                        _r.label = 24;
                    case 24:
                        if (!!this.respostaVazia(this.resposta33)) return [3 /*break*/, 26];
                        this.resposta33.parecer_pergunta_id = 33;
                        _o = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta33)];
                    case 25:
                        _o.resposta33 = _r.sent();
                        _r.label = 26;
                    case 26:
                        if (!!this.respostaVazia(this.resposta34)) return [3 /*break*/, 28];
                        this.resposta34.parecer_pergunta_id = 34;
                        _p = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta34)];
                    case 27:
                        _p.resposta34 = _r.sent();
                        _r.label = 28;
                    case 28:
                        if (!!this.respostaVazia(this.resposta35)) return [3 /*break*/, 30];
                        this.resposta35.parecer_pergunta_id = 35;
                        _q = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta35)];
                    case 29:
                        _q.resposta35 = _r.sent();
                        _r.label = 30;
                    case 30:
                        caminhoFormativoSugeridoFC = this.caminhoFormativoFormando.caminhoFormativoSugeridoFC;
                        return [4 /*yield*/, this.atualizarParecerResponsavel("INICIADO")];
                    case 31:
                        _r.sent();
                        if (!!this.caminhoFormativoFormando.id) return [3 /*break*/, 33];
                        return [4 /*yield*/, this.carregarCaminhoFormativo()];
                    case 32:
                        _r.sent();
                        this.caminhoFormativoFormando.caminhoFormativoSugeridoFC =
                            caminhoFormativoSugeridoFC;
                        _r.label = 33;
                    case 33:
                        this.atualizarCaminhoFormativo();
                        return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerComponent.prototype.definirStatusQuestionario = function () {
        var status = "INICIADO";
        var todoRespondido = false;
        if (!this.respostaVazia(this.resposta21) &&
            !this.respostaVazia(this.resposta22) &&
            !this.respostaVazia(this.resposta23) &&
            !this.respostaVazia(this.resposta24) &&
            !this.respostaVazia(this.resposta25) &&
            !this.respostaVazia(this.resposta26) &&
            !this.respostaVazia(this.resposta27) &&
            !this.respostaVazia(this.resposta28) &&
            !this.respostaVazia(this.resposta29) &&
            !this.respostaVazia(this.resposta30) &&
            !this.respostaVazia(this.resposta31) &&
            !this.respostaVazia(this.resposta32) &&
            // !this.respostaVazia(this.resposta33) && - Aqui é o caminho formativo proposto pelo FC
            !this.respostaVazia(this.resposta34) &&
            !this.respostaVazia(this.resposta35)) {
            todoRespondido = true;
        }
        if (todoRespondido) {
            status = "ENVIADO";
        }
        return status;
    };
    ParecerCALFCAnswerComponent.prototype.respostaVazia = function (resposta) {
        return (resposta.resposta == null ||
            resposta.resposta == undefined ||
            resposta.resposta == "");
    };
    ParecerCALFCAnswerComponent.prototype.enviarQuestionario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.salvarQuestionario()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.atualizarParecerResponsavel("ENVIADO")];
                    case 2:
                        _a.sent();
                        this.router.navigate(["secured/parecer-agradecimento"]);
                        return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerComponent.prototype.atualizarParecerResponsavel = function (status) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_12;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!(this.parecerResponsavel.id != null)) return [3 /*break*/, 2];
                        this.parecerResponsavel.status = status;
                        if (status == "ENVIADO") {
                            this.parecerResponsavel.data_envio = new Date();
                        }
                        return [4 /*yield*/, this.parecerResponsavelService.atualizar(this.parecerResponsavel.id, this.parecerResponsavel)];
                    case 1:
                        response = _a.sent();
                        this.parecerResponsavel = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        err_12 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_12.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return ParecerCALFCAnswerComponent;
}());
export { ParecerCALFCAnswerComponent };
