import * as tslib_1 from "tslib";
import { Celula } from "../model/celula.model";
import { AbstractBuilder } from "./abstract.builder";
var CelulaBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(CelulaBuilder, _super);
    function CelulaBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CelulaBuilder.prototype.reset = function () {
        this.entity = new Celula(null, null, null, null, null, null, null, null, null);
    };
    return CelulaBuilder;
}(AbstractBuilder));
export { CelulaBuilder };
