import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
var AdicaoEquipeFormacaoComponent = /** @class */ (function () {
    function AdicaoEquipeFormacaoComponent(imagemService, missionarioMissaoAtualService, historicoMissionarioMissaoService, elementoGrupoDominioService, apostoladoService, route, router, swtAlert2Service) {
        this.imagemService = imagemService;
        this.missionarioMissaoAtualService = missionarioMissaoAtualService;
        this.historicoMissionarioMissaoService = historicoMissionarioMissaoService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.apostoladoService = apostoladoService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.eventEmitter = new EventEmitter();
        this.detalhesMissionariosDaCasaComunitaria = new Array();
        this.cadastroEquipeFormacaoFormulario = {
            pessoaId: null,
            casaComunitariaId: null,
            apostoladoId: null,
            dataEntrada: null
        };
    }
    AdicaoEquipeFormacaoComponent.prototype.ngOnInit = function () {
        this.initializer();
    };
    AdicaoEquipeFormacaoComponent.prototype.initializer = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.casaComunitaria.id != undefined)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.carregarMissionariosCasaComunitaria()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.carregarEquipeFormacaoCasaComunitaria()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.carregarTipoApostolado()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.carregarApostolados()];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    /*** MEMBROS ***/
    AdicaoEquipeFormacaoComponent.prototype.adicionarMembroEquipeFormacao = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        this.cadastroEquipeFormacaoFormulario.casaComunitariaId = this.casaComunitaria.id;
                        if (!(this.cadastroEquipeFormacaoFormulario.dataEntrada != null && this.cadastroEquipeFormacaoFormulario.pessoaId != null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.missionarioMissaoAtualService.inserirApostoladoEquipeFormacao(this.cadastroEquipeFormacaoFormulario)];
                    case 1:
                        response = _a.sent();
                        this.cadastroEquipeFormacaoFormulario = {
                            pessoaId: null,
                            casaComunitariaId: null,
                            apostoladoId: null,
                            dataEntrada: null
                        };
                        this.carregarEquipeFormacaoCasaComunitaria();
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 3];
                    case 2:
                        if (this.cadastroEquipeFormacaoFormulario.pessoaId == null) {
                            this.swtAlert2Service.errorAlert("O campo missionário é obrigatório.");
                        }
                        else if (this.cadastroEquipeFormacaoFormulario.dataEntrada == null) {
                            this.swtAlert2Service.errorAlert("O campo data de entrada é obrigatório.");
                        }
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    // async atualizarMembro(membro) : Promise<any> {
    //     try
    //     {
    //         let response = await this.historicoMissionarioMissaoApostoladoService.update(membro.id,membro);
    //         this.swtAlert2Service.successAlert(response.message);
    //         //this.carregarEquipeFormacao();
    //     }
    //     catch(err)
    //     {
    //         this.swtAlert2Service.errorAlert(err.error.errors);
    //     }
    // }
    // async removerMembro(id:number) : Promise<any> {
    //     if(confirm("Deseja remover este membro da casa comunitária?"))
    //     {
    //         try
    //         {
    //             let response = await this.historicoMissionarioMissaoApostoladoService.delete(id);
    //             this.swtAlert2Service.successAlert(response.message);
    //             //this.carregarEquipeFormacao();                  
    //         }
    //         catch(err)
    //         {
    //             this.swtAlert2Service.errorAlert(err.error.errors);
    //         }
    //     }        
    // }
    AdicaoEquipeFormacaoComponent.prototype.carregarMissionariosCasaComunitaria = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, i, blogImage, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        return [4 /*yield*/, this.missionarioMissaoAtualService.listarDetalhesDeMissionariosNaMissaoPorCasaComunitaria(this.casaComunitaria.id)];
                    case 1:
                        response = _a.sent();
                        this.detalhesMissionariosDaCasaComunitaria = response.sort(function (m1, m2) { return m1.pessoaNome.localeCompare(m2.pessoaNome); });
                        this.totalDePaginas = response.quantity;
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.detalhesMissionariosDaCasaComunitaria.length)) return [3 /*break*/, 5];
                        if (!(this.detalhesMissionariosDaCasaComunitaria[i].imagemId != null)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getPessoaImagem(this.detalhesMissionariosDaCasaComunitaria[i].imagemId)];
                    case 3:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.detalhesMissionariosDaCasaComunitaria[i]);
                        _a.label = 4;
                    case 4:
                        i++;
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    AdicaoEquipeFormacaoComponent.prototype.carregarEquipeFormacaoCasaComunitaria = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, i, blogImage, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        return [4 /*yield*/, this.missionarioMissaoAtualService.findEquipeFormacaoPorCasaComunitaria(this.casaComunitaria.id)];
                    case 1:
                        response = _a.sent();
                        this.listaEquipeFormacao = response.sort(function (a, b) { return a.pessoaNome.localeCompare(b.pessoaNome); });
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.listaEquipeFormacao.length)) return [3 /*break*/, 5];
                        if (!(this.listaEquipeFormacao[i].imagemId != null)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getPessoaImagem(this.listaEquipeFormacao[i].imagemId)];
                    case 3:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.listaEquipeFormacao[i]);
                        _a.label = 4;
                    case 4:
                        i++;
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    AdicaoEquipeFormacaoComponent.prototype.carregarTipoApostolado = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        request = {
                            grupoNome: "TIPO_APOSTOLADO"
                        };
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request)];
                    case 1:
                        response = _a.sent();
                        this.listaTipoApostolado = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        err_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdicaoEquipeFormacaoComponent.prototype.carregarApostolados = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var servicoAutoridadeCasaComunitaria, response, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        servicoAutoridadeCasaComunitaria = this.listaTipoApostolado.filter(function (t) { return t.valor.localeCompare("SA") == 0; });
                        if (!(servicoAutoridadeCasaComunitaria.length > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.apostoladoService.porTipoId(servicoAutoridadeCasaComunitaria[0].id)];
                    case 1:
                        response = _a.sent();
                        this.listaApostolados = response.entity.filter(function (ap) { return ap.id != 21 && ap.id != 23; });
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        err_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_5.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AdicaoEquipeFormacaoComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_6 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_6.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return AdicaoEquipeFormacaoComponent;
}());
export { AdicaoEquipeFormacaoComponent };
