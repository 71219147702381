import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
var PaisService = /** @class */ (function (_super) {
    tslib_1.__extends(PaisService, _super);
    function PaisService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType('r/pais');
        return _this;
    }
    PaisService.prototype.buscarPaises = function (busca) {
        return this.http.post(this.apiURL + '/buscar', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise();
    };
    return PaisService;
}(GenericService));
export { PaisService };
