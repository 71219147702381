import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
import { environment } from 'src/environments/environment';
var MembroComunidadeService = /** @class */ (function (_super) {
    tslib_1.__extends(MembroComunidadeService, _super);
    function MembroComunidadeService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType('membrocomunidade');
        return _this;
    }
    MembroComunidadeService.prototype.buscarPorCelula = function (busca) {
        return this.http.post(environment.moduloFormacao.urlModuloFormacao + 'membrocomunidade/porcelula', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise();
    };
    MembroComunidadeService.prototype.buscarPorCasaComunitaria = function (busca) {
        return this.http.post(environment.moduloFormacao.urlModuloFormacao + 'membrocomunidade/porcasacomunitaria', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise();
    };
    MembroComunidadeService.prototype.listarMembrosCelula = function (id) {
        return this.http.get(environment.moduloFormacao.urlModuloFormacao + "membrocomunidade/celula/porid/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    MembroComunidadeService.prototype.listarMembrosCasaComunitaria = function (id) {
        return this.http.get(this.apiURL + "membrocomunidade/porcasacomunitaria/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    MembroComunidadeService.prototype.vincularMembroCelula = function (request) {
        return this.http.post(this.apiURL + '/vincularmembrocelula', JSON.stringify(request), { 'headers': this.getHeaders() }).toPromise();
    };
    MembroComunidadeService.prototype.vincularMembroCasaComunitaria = function (request) {
        return this.http.post(this.apiURL + '/vincularmembrocasacomunitaria', JSON.stringify(request), { 'headers': this.getHeaders() }).toPromise();
    };
    MembroComunidadeService.prototype.desvincularMembroCelula = function (request) {
        return this.http.post(this.apiURL + '/desvincularmembrocelula', JSON.stringify(request), { 'headers': this.getHeaders() }).toPromise();
    };
    MembroComunidadeService.prototype.desvincularMembroCasaComunitaria = function (request) {
        return this.http.post(this.apiURL + '/desvincularmembrocasacomunitaria', JSON.stringify(request), { 'headers': this.getHeaders() }).toPromise();
    };
    MembroComunidadeService.prototype.detalhesMembroCelula = function (request) {
        return this.http.post(this.apiURL + '/detalhesmembro', JSON.stringify(request), { 'headers': this.getHeaders() }).toPromise();
    };
    MembroComunidadeService.prototype.detalhesMembroCasaComunitaria = function (request) {
        return this.http.post(this.apiURL + '/detalhesmembro', JSON.stringify(request), { 'headers': this.getHeaders() }).toPromise();
    };
    MembroComunidadeService.prototype.buscarPorPessoaId = function (id) {
        return this.http.get(this.apiURL + "/porpessoa/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    MembroComunidadeService.prototype.aniversariantesCelula = function (celulaId) {
        return this.http.get(this.apiURL + "/celula/aniversariantes/" + celulaId, { 'headers': this.getHeaders() }).toPromise();
    };
    MembroComunidadeService.prototype.aniversariantesCasaComunitaria = function (casaComunitariaId) {
        return this.http.get(this.apiURL + "/casacomunitaria/aniversariantes/" + casaComunitariaId, { 'headers': this.getHeaders() }).toPromise();
    };
    return MembroComunidadeService;
}(GenericService));
export { MembroComunidadeService };
