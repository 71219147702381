import * as tslib_1 from "tslib";
import { RemocaoMembroCasaComunitaria } from '../model/remocaomembrocasacomunitaria.model.';
import { AbstractBuilder } from './abstract.builder';
var RemocaoMembroCasaComunitariaBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(RemocaoMembroCasaComunitariaBuilder, _super);
    function RemocaoMembroCasaComunitariaBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RemocaoMembroCasaComunitariaBuilder.prototype.reset = function () {
        this.entity = new RemocaoMembroCasaComunitaria(null, null, null, null);
    };
    return RemocaoMembroCasaComunitariaBuilder;
}(AbstractBuilder));
export { RemocaoMembroCasaComunitariaBuilder };
