import * as tslib_1 from "tslib";
import { Apostolado } from '../model/apostolado.model';
import { AbstractBuilder } from './abstract.builder';
var ApostoladoBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(ApostoladoBuilder, _super);
    function ApostoladoBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ApostoladoBuilder.prototype.reset = function () {
        this.entity = new Apostolado(null, null, null, true, null, null);
    };
    return ApostoladoBuilder;
}(AbstractBuilder));
export { ApostoladoBuilder };
