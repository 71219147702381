import * as tslib_1 from "tslib";
import { Usuario } from '../model/usuario.model';
import { AbstractBuilder } from './abstract.builder';
var UsuarioBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(UsuarioBuilder, _super);
    function UsuarioBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UsuarioBuilder.prototype.reset = function () {
        this.entity = new Usuario(null, null, null, null, null, null, null, null, new Array(), new Array());
    };
    return UsuarioBuilder;
}(AbstractBuilder));
export { UsuarioBuilder };
