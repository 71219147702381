import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { UsuarioBuilder } from 'src/app/builder/usuario.builder';
var UsuarioComponent = /** @class */ (function () {
    function UsuarioComponent(usuarioService, swtAlert2Service, route, router) {
        this.usuarioService = usuarioService;
        this.swtAlert2Service = swtAlert2Service;
        this.route = route;
        this.router = router;
        this.usuarioBuilder = new UsuarioBuilder();
        this.usuario = this.usuarioBuilder.getInstance();
    }
    UsuarioComponent.prototype.ngOnInit = function () {
        this.getUsuarioAtual();
    };
    UsuarioComponent.prototype.getUsuarioAtual = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuarioAtual = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UsuarioComponent.prototype.salvar = function (usuario) {
        var _this = this;
        this.usuarioService.create(usuario).then(function (response) {
            _this.usuario = _this.usuarioBuilder.getInstance();
            _this.swtAlert2Service.successAlert(response.message);
            setTimeout(function () {
                _this.router.navigate(['../secured/usuario/' + response.entity.id]);
            }, 2000);
        }).catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    return UsuarioComponent;
}());
export { UsuarioComponent };
