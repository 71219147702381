import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
var CasaComunitariaService = /** @class */ (function (_super) {
    tslib_1.__extends(CasaComunitariaService, _super);
    function CasaComunitariaService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType('r/casacomunitaria');
        return _this;
    }
    CasaComunitariaService.prototype.buscarCasasComunitaria = function (busca) {
        return this.http.post(this.apiURL + '/buscar', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise();
    };
    CasaComunitariaService.prototype.porMissao = function (missaoId) {
        return this.http.get(this.apiURL + '/pormissao/' + missaoId, { 'headers': this.getHeaders() }).toPromise();
    };
    CasaComunitariaService.prototype.getAllNotInAcessoUsuarioEstrutura = function (id) {
        return this.http.get(this.apiURL + "/getallnotinacessousuarioestrutura/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    CasaComunitariaService.prototype.getAllFromAcessoUsuarioEstrutura = function (id) {
        return this.http.get(this.apiURL + "/getallfromacessousuarioestrutura/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    return CasaComunitariaService;
}(GenericService));
export { CasaComunitariaService };
