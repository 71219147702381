import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
var DifusaoService = /** @class */ (function (_super) {
    tslib_1.__extends(DifusaoService, _super);
    function DifusaoService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType('r/difusao');
        return _this;
    }
    DifusaoService.prototype.buscar = function (busca) {
        return this.http.post(this.apiURL + '/buscar', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise();
    };
    DifusaoService.prototype.porRegional = function (regionalId) {
        return this.http.get(this.apiURL + '/porregional/' + regionalId, { 'headers': this.getHeaders() }).toPromise();
    };
    DifusaoService.prototype.getAllNotInAcessoUsuarioEstrutura = function (id) {
        return this.http.get(this.apiURL + "/getallnotinacessousuarioestrutura/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    DifusaoService.prototype.getAllFromAcessoUsuarioEstrutura = function (id) {
        return this.http.get(this.apiURL + "/getallfromacessousuarioestrutura/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    return DifusaoService;
}(GenericService));
export { DifusaoService };
