import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
var ParecerService = /** @class */ (function (_super) {
    tslib_1.__extends(ParecerService, _super);
    function ParecerService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType('parecer');
        return _this;
    }
    ParecerService.prototype.porPessoaId = function (id) {
        return this.http.get(this.apiURL + "/porpessoa/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    ParecerService.prototype.porQuestionarioId = function (id) {
        return this.http.get(this.apiURL + "/porquestionario/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    ParecerService.prototype.salvarParecer = function (parecer) {
        return this.http.post(this.apiURL, parecer);
    };
    ParecerService.prototype.getParecer = function (parecerId) {
        var url = this.apiURL + "/" + parecerId;
        return this.http.get(url);
    };
    return ParecerService;
}(GenericService));
export { ParecerService };
