import * as tslib_1 from "tslib";
import { GenericService } from "src/app/core/services/generic.service";
import { environment } from "src/environments/environment";
var RespostaParecerService = /** @class */ (function (_super) {
    tslib_1.__extends(RespostaParecerService, _super);
    function RespostaParecerService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType("respostaparecer");
        return _this;
    }
    RespostaParecerService.prototype.porPessoaId = function (id) {
        return this.http
            .get(environment.moduloFormacao.urlModuloFormacao + "parecer_resposta_responsavel/porpessoaid/" + id, { headers: this.getHeaders() })
            .toPromise();
    };
    RespostaParecerService.prototype.cadastrar = function (request) {
        return this.http
            .post(environment.moduloFormacao.urlModuloFormacao + "parecer_resposta_responsavel", JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    RespostaParecerService.prototype.atualizar = function (id, request) {
        return this.http
            .put(environment.moduloFormacao.urlModuloFormacao + "parecer_resposta_responsavel/" + id, JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    return RespostaParecerService;
}(GenericService));
export { RespostaParecerService };
