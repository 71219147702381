import { Component, OnInit } from '@angular/core';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { Pessoa } from 'src/app/model/pessoa.model';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from 'src/app/model/usuario.model';
import { PessoaService } from '../../pessoa/service/pessoa.service';
import { UsuarioService } from '../../usuario/service/usuario.service';

@Component({
  selector: 'app-questionario-agradecimento',
  templateUrl: './questionario-agradecimento.component.html',
  styleUrls: []
})
export class QuestionarioAgradecimentoComponent implements OnInit {

    public pessoa: Pessoa;
    public pessoaId: number;
    public intlTelInputCalled: boolean = false;
    public intlTellInputInstance : any;
    public usuarioAtual: Usuario;    

  constructor(        
    public pessoaService: PessoaService,
    public swtAlert2Service: SwtAlert2Service,
    public usuarioService: UsuarioService,
    public pessoaBuilder: PessoaBuilder,
    public route: ActivatedRoute,
    public router: Router        
) {        
    this.pessoa = this.pessoaBuilder.getInstance();
  }

  ngOnInit(): void {

  }

}

