import * as tslib_1 from "tslib";
import { DadosDetalhamentoMissionarioMissaoApostoladoAtual } from '../model/dadosdetalhamentomissionariomissaoapostoladoatual.model';
import { AbstractBuilder } from './abstract.builder';
var DadosDetalhamentoMissionarioMissaoApostoladoAtualBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(DadosDetalhamentoMissionarioMissaoApostoladoAtualBuilder, _super);
    function DadosDetalhamentoMissionarioMissaoApostoladoAtualBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DadosDetalhamentoMissionarioMissaoApostoladoAtualBuilder.prototype.reset = function () {
        this.entity = new DadosDetalhamentoMissionarioMissaoApostoladoAtual(null, null, null, null, null, null, null, null);
    };
    return DadosDetalhamentoMissionarioMissaoApostoladoAtualBuilder;
}(AbstractBuilder));
export { DadosDetalhamentoMissionarioMissaoApostoladoAtualBuilder };
