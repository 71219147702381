import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
var AdicaoMembroCasaComunitariaComponent = /** @class */ (function () {
    function AdicaoMembroCasaComunitariaComponent(imagemService, salvarMissionarioMissaoAtualBuilder, missionarioMissaoAtualService, pessoaService, route, router, swtAlert2Service) {
        this.imagemService = imagemService;
        this.salvarMissionarioMissaoAtualBuilder = salvarMissionarioMissaoAtualBuilder;
        this.missionarioMissaoAtualService = missionarioMissaoAtualService;
        this.pessoaService = pessoaService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.eventEmitter = new EventEmitter();
        this.pessoaBuilder = new PessoaBuilder();
        this.pessoaMembroSelecionada = this.pessoaBuilder.getInstance();
        this.membrosCasaComunitaria = new Array();
        this.salvarMissionarioMissaoAtual = this.salvarMissionarioMissaoAtualBuilder.getInstance();
        this.busca = {
            casaComunitariaId: null,
            formaVidaId: null,
            pageNumber: 0,
            quantityOfElements: 10
        };
    }
    AdicaoMembroCasaComunitariaComponent.prototype.ngOnInit = function () {
        this.initializer();
    };
    AdicaoMembroCasaComunitariaComponent.prototype.initializer = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.casaComunitaria.id != undefined)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.carregarMembrosCasaComunitaria()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.temAcessoARemocao();
                        return [2 /*return*/];
                }
            });
        });
    };
    /*** MEMBROS ***/
    AdicaoMembroCasaComunitariaComponent.prototype.selecionarMembro = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.salvarMissionarioMissaoAtual.pessoaId = pessoa.id;
                this.pessoaMembroSelecionada = pessoa;
                $('#listagem-pessoa-membro').modal('hide');
                return [2 /*return*/];
            });
        });
    };
    AdicaoMembroCasaComunitariaComponent.prototype.recarregarMembros = function (comando) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (comando == 'recarregar_membros_casa_comunitaria') {
                    this.membroSelecionado = null;
                    this.carregarMembrosCasaComunitaria();
                    $('#listagem-pessoa-membro').modal('hide');
                }
                return [2 /*return*/];
            });
        });
    };
    AdicaoMembroCasaComunitariaComponent.prototype.adicionarMembroCasaComunitaria = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        this.salvarMissionarioMissaoAtual.casaComunitariaId = this.casaComunitaria.id;
                        if (!(this.salvarMissionarioMissaoAtual.dataChegada != null && this.salvarMissionarioMissaoAtual.pessoaId != null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.missionarioMissaoAtualService.adicionarMembroNaCasaComunitaria(this.salvarMissionarioMissaoAtual)];
                    case 1:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert("Missionário adicionado a casa comunitária com sucesso!");
                        this.salvarMissionarioMissaoAtual = this.salvarMissionarioMissaoAtualBuilder.getInstance();
                        this.pessoaMembroSelecionada = this.pessoaBuilder.getInstance();
                        this.carregarMembrosCasaComunitaria();
                        return [3 /*break*/, 3];
                    case 2:
                        if (this.salvarMissionarioMissaoAtual.pessoaId == null) {
                            this.swtAlert2Service.errorAlert("O campo pessoa é obrigatório.");
                        }
                        else if (this.salvarMissionarioMissaoAtual.dataChegada == null) {
                            this.swtAlert2Service.errorAlert("O campo data de entrada é obrigatório.");
                        }
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    AdicaoMembroCasaComunitariaComponent.prototype.carregarMembrosCasaComunitaria = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, i, blogImage, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        this.busca.casaComunitariaId = this.casaComunitaria.id;
                        return [4 /*yield*/, this.pessoaService.buscarMembrosCasaComunitaria(this.busca)];
                    case 1:
                        response = _a.sent();
                        this.membrosCasaComunitaria = response.entity;
                        this.totalDePaginas = response.quantity;
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.membrosCasaComunitaria.length)) return [3 /*break*/, 5];
                        if (!(this.membrosCasaComunitaria[i].imagemId != null)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getPessoaImagem(this.membrosCasaComunitaria[i].imagemId)];
                    case 3:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.membrosCasaComunitaria[i]);
                        _a.label = 4;
                    case 4:
                        i++;
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    AdicaoMembroCasaComunitariaComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdicaoMembroCasaComunitariaComponent.prototype.cliqueNoBotaoListener = function (botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.carregarMembrosCasaComunitaria();
    };
    AdicaoMembroCasaComunitariaComponent.prototype.temAcessoARemocao = function () {
        var e_1, _a;
        this.podeRemoverMissionario = false;
        try {
            for (var _b = tslib_1.__values(this.usuarioLogado.autorizacoesNome), _c = _b.next(); !_c.done; _c = _b.next()) {
                var authNome = _c.value;
                if (authNome.localeCompare("ROLE_ASSISTENTE_MISSIONARIO") == 0
                    ||
                        authNome.localeCompare("ROLE_ACOMPANHADOR_MISSAO") == 0) {
                    this.podeRemoverMissionario = true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    return AdicaoMembroCasaComunitariaComponent;
}());
export { AdicaoMembroCasaComunitariaComponent };
