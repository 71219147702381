import * as tslib_1 from "tslib";
import { GenericService } from "src/app/core/services/generic.service";
import { environment } from "src/environments/environment";
var CoordenadorColegiadoService = /** @class */ (function (_super) {
    tslib_1.__extends(CoordenadorColegiadoService, _super);
    function CoordenadorColegiadoService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType("coordenadorcolegiado");
        return _this;
    }
    CoordenadorColegiadoService.prototype.obterFormadoresPorMissaoDoCoordenadorColegiado = function (page, size) {
        var url = environment.moduloFormacao.urlModuloFormacao + "coordenadorcolegiado/formadorespormissao?page=" + page + "&size=" + size;
        return this.http.get(url, { headers: this.getHeaders() }).toPromise();
    };
    return CoordenadorColegiadoService;
}(GenericService));
export { CoordenadorColegiadoService };
