import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
var MissionarioMissaoAtualService = /** @class */ (function (_super) {
    tslib_1.__extends(MissionarioMissaoAtualService, _super);
    function MissionarioMissaoAtualService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType('missionariomissaoatual');
        return _this;
    }
    MissionarioMissaoAtualService.prototype.adicionarMembroNaCasaComunitaria = function (salvarMissionarioMissaoAtual) {
        return this.http.post(this.apiURL + "/adicionarmembrocasacomunitaria", JSON.stringify(salvarMissionarioMissaoAtual), { 'headers': this.getHeaders() }).toPromise();
    };
    MissionarioMissaoAtualService.prototype.removerMembroDaCasaComunitaria = function (removerMissionarioMissaoAtual) {
        return this.http.post(this.apiURL + "/removermembrocasacomunitaria", JSON.stringify(removerMissionarioMissaoAtual), { 'headers': this.getHeaders() }).toPromise();
    };
    MissionarioMissaoAtualService.prototype.listarDetalhesDeMissionariosNaMissaoPorCasaComunitaria = function (casaComunitariaId) {
        return this.http.get(this.apiURL + "/listarporcasacomunitaria/" + casaComunitariaId, { 'headers': this.getHeaders() }).toPromise();
    };
    MissionarioMissaoAtualService.prototype.detalhamento = function (id) {
        return this.http.get(this.apiURL + "/detalhamento/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    MissionarioMissaoAtualService.prototype.detalhamentoPorUsuarioLogado = function () {
        return this.http.get(this.apiURL + "/detalhamento/porusuariologado", { 'headers': this.getHeaders() }).toPromise();
    };
    MissionarioMissaoAtualService.prototype.salvarApostolado = function (missionatioMissaoApostoladoAtual) {
        return this.http.post(this.apiURL + "/apostolado", JSON.stringify(missionatioMissaoApostoladoAtual), { 'headers': this.getHeaders() }).toPromise();
    };
    MissionarioMissaoAtualService.prototype.atualizarApostolado = function (missionatioMissaoApostoladoAtual) {
        return this.http.put(this.apiURL + "/apostolado", JSON.stringify(missionatioMissaoApostoladoAtual), { 'headers': this.getHeaders() }).toPromise();
    };
    MissionarioMissaoAtualService.prototype.detalhamentoApostolado = function (id) {
        return this.http.get(this.apiURL + "/apostolado/detalhamento/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    MissionarioMissaoAtualService.prototype.listarApostoladosPorMissionarioMissaoAtualId = function (id) {
        return this.http.get(this.apiURL + "/apostolado/listar/pormissionariomissaoatual/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    MissionarioMissaoAtualService.prototype.deletarApostolado = function (id) {
        return this.http.delete(this.apiURL + "/apostolado/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    MissionarioMissaoAtualService.prototype.inserirApostoladoEquipeFormacao = function (formulario) {
        return this.http.post(this.apiURL + "/apostolado/inserirapostoladoequipeformacao", JSON.stringify(formulario), { 'headers': this.getHeaders() }).toPromise();
    };
    MissionarioMissaoAtualService.prototype.findEquipeFormacaoPorCasaComunitaria = function (id) {
        return this.http.get(this.apiURL + "/apostolado/equipeformacaoporcasacomunitaria/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    return MissionarioMissaoAtualService;
}(GenericService));
export { MissionarioMissaoAtualService };
