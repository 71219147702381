import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
import { environment } from 'src/environments/environment';
var FormadorAssistenteService = /** @class */ (function (_super) {
    tslib_1.__extends(FormadorAssistenteService, _super);
    function FormadorAssistenteService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType('formadorassistente');
        return _this;
    }
    FormadorAssistenteService.prototype.buscarPorCelula = function (busca) {
        return this.http.post(this.apiURL + '/porcelula', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise();
    };
    FormadorAssistenteService.prototype.buscarPorPessoaId = function (id) {
        return this.http.get(this.apiURL + "/porpessoa/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    FormadorAssistenteService.prototype.buscarCelulaPorPessoaId = function (id) {
        return this.http.get(environment.moduloFormacao.urlModuloFormacao + "api/secured/formadorassistente/celula/porpessoaid/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    FormadorAssistenteService.prototype.buscarCelulaPorId = function (id) {
        return this.http.get(environment.moduloFormacao.urlModuloFormacao + "api/secured/formadorassistente/celula/porid/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    return FormadorAssistenteService;
}(GenericService));
export { FormadorAssistenteService };
