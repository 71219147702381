import * as tslib_1 from "tslib";
import { GenericService } from "src/app/core/services/generic.service";
import { environment } from "src/environments/environment";
var DadoFormandoCAService = /** @class */ (function (_super) {
    tslib_1.__extends(DadoFormandoCAService, _super);
    function DadoFormandoCAService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType("dadofomandoca");
        return _this;
    }
    DadoFormandoCAService.prototype.porPessoaId = function (id) {
        return this.http
            .get(environment.moduloFormacao.urlModuloFormacao + "dadosFormandoCA/porpessoaid/" + id, { headers: this.getHeaders() })
            .toPromise();
    };
    DadoFormandoCAService.prototype.cadastrar = function (request) {
        return this.http
            .post(environment.moduloFormacao.urlModuloFormacao + "dadosFormandoCA", JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    DadoFormandoCAService.prototype.atualizar = function (request) {
        return this.http
            .put(environment.moduloFormacao.urlModuloFormacao + "dadosFormandoCA", JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    return DadoFormandoCAService;
}(GenericService));
export { DadoFormandoCAService };
