import * as tslib_1 from "tslib";
import { AbstractBuilder } from './abstract.builder';
import { DadoFormandoCV } from '../model/dadoformandocv.model';
var DadoFormandoCVBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(DadoFormandoCVBuilder, _super);
    function DadoFormandoCVBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DadoFormandoCVBuilder.prototype.reset = function () {
        this.entity = new DadoFormandoCV(null, null, null, null, null, null, null);
    };
    return DadoFormandoCVBuilder;
}(AbstractBuilder));
export { DadoFormandoCVBuilder };
