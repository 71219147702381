import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
var CasaComunitariaLocalManageComponent = /** @class */ (function () {
    function CasaComunitariaLocalManageComponent(usuarioService, pessoaService, casaComunitariaService, imagemService, missionarioMissaoAtualService, historicoMissionarioMissaoBuilder, historicoMissionarioMissaoService, route, router, swtAlert2Service) {
        this.usuarioService = usuarioService;
        this.pessoaService = pessoaService;
        this.casaComunitariaService = casaComunitariaService;
        this.imagemService = imagemService;
        this.missionarioMissaoAtualService = missionarioMissaoAtualService;
        this.historicoMissionarioMissaoBuilder = historicoMissionarioMissaoBuilder;
        this.historicoMissionarioMissaoService = historicoMissionarioMissaoService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.pessoaBuilder = new PessoaBuilder();
        this.pessoaMembroSelecionada = this.pessoaBuilder.getInstance();
    }
    CasaComunitariaLocalManageComponent.prototype.ngOnInit = function () {
        this.casaComunitariaId = Number(this.route.snapshot.paramMap.get('id'));
        this.isLoaded = false;
        this.initializer();
    };
    CasaComunitariaLocalManageComponent.prototype.initializer = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.carregarUsuario()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.carregarPessoa()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.carregarDadosDeDetalhamentoDaMissaoAtualDoUsuarioLogado()];
                    case 3:
                        _a.sent();
                        if (!(this.dadosDetalhamentoMissaoAtualUsuarioLogado != undefined && this.dadosDetalhamentoMissaoAtualUsuarioLogado.id != undefined)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.carregarCasaComunitaria()];
                    case 4:
                        _a.sent();
                        return [3 /*break*/, 8];
                    case 5:
                        if (!(this.casaComunitariaId != undefined && this.casaComunitariaId != 0)) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.carregarCasaComunitaria()];
                    case 6:
                        _a.sent();
                        return [3 /*break*/, 8];
                    case 7:
                        this.swtAlert2Service.errorAlert("Não foi possível obter as informações da casa comunitária");
                        _a.label = 8;
                    case 8:
                        this.isLoaded = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    CasaComunitariaLocalManageComponent.prototype.carregarUsuario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuario = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_1.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CasaComunitariaLocalManageComponent.prototype.carregarPessoa = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.pessoaService.buscaPorUsuarioId(this.usuario.id)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CasaComunitariaLocalManageComponent.prototype.carregarDadosDeDetalhamentoDaMissaoAtualDoUsuarioLogado = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.missionarioMissaoAtualService.detalhamentoPorUsuarioLogado()];
                    case 1:
                        response = _a.sent();
                        this.dadosDetalhamentoMissaoAtualUsuarioLogado = response;
                        if (this.casaComunitariaId == undefined || this.casaComunitariaId == 0) {
                            this.casaComunitariaId = this.dadosDetalhamentoMissaoAtualUsuarioLogado.casaComunitariaId;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CasaComunitariaLocalManageComponent.prototype.carregarCasaComunitaria = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!(this.casaComunitariaId != undefined)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.casaComunitariaService.find(this.casaComunitariaId)];
                    case 1:
                        response = _a.sent();
                        this.casaComunitaria = response.entity;
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        e_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_4.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CasaComunitariaLocalManageComponent.prototype.carregamentoConcluido = function ($event) {
        this.isLoaded = $event;
    };
    return CasaComunitariaLocalManageComponent;
}());
export { CasaComunitariaLocalManageComponent };
