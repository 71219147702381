import * as tslib_1 from "tslib";
import { AbstractBuilder } from './abstract.builder';
import { RespostaQuestionario } from '../model/respostaquestionario.model';
var RespostaQuestionarioBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(RespostaQuestionarioBuilder, _super);
    function RespostaQuestionarioBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RespostaQuestionarioBuilder.prototype.reset = function () {
        this.entity = new RespostaQuestionario(null, null, null, null);
    };
    return RespostaQuestionarioBuilder;
}(AbstractBuilder));
export { RespostaQuestionarioBuilder };
