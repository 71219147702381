import * as tslib_1 from "tslib";
import { PessoaInfo } from '../model/pessoainfo.model';
import { AbstractBuilder } from './abstract.builder';
var PessoaInfoBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(PessoaInfoBuilder, _super);
    function PessoaInfoBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PessoaInfoBuilder.prototype.reset = function () {
        this.entity = new PessoaInfo(null, null, null, null, null);
    };
    return PessoaInfoBuilder;
}(AbstractBuilder));
export { PessoaInfoBuilder };
