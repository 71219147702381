import * as tslib_1 from "tslib";
import { ElementoGrupoDominio } from '../model/elementogrupodominio.model';
import { AbstractBuilder } from './abstract.builder';
var ElementoGrupoDominioBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(ElementoGrupoDominioBuilder, _super);
    function ElementoGrupoDominioBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ElementoGrupoDominioBuilder.prototype.reset = function () {
        this.entity = new ElementoGrupoDominio(null, null, null, null, null, false, null, false);
    };
    return ElementoGrupoDominioBuilder;
}(AbstractBuilder));
export { ElementoGrupoDominioBuilder };
