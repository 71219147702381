import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
var QuestionarioCALViewAnswerComponent = /** @class */ (function () {
    function QuestionarioCALViewAnswerComponent(wopGetAllPublic, pessoaService, imagemService, paisService, estadoService, cidadeService, elementoGrupoDominioService, elementoGrupoDominioLocalService, missaoService, swtAlert2Service, usuarioService, pessoaBuilder, pessoaInfoBuilder, dadoVocacionalService, dadoFomandoCAService, caminhoFormativoService, mensagemService, respostaQuestionarioService, questionarioFormandoService, dadoFomandoCABuilder, caminhoFormativoBuilder, 
    // public mensagemBuilder: MensagemBuilder,
    respostaQuestionarioBuilder, questionarioFormandoBuilder, route, router) {
        this.wopGetAllPublic = wopGetAllPublic;
        this.pessoaService = pessoaService;
        this.imagemService = imagemService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.missaoService = missaoService;
        this.swtAlert2Service = swtAlert2Service;
        this.usuarioService = usuarioService;
        this.pessoaBuilder = pessoaBuilder;
        this.pessoaInfoBuilder = pessoaInfoBuilder;
        this.dadoVocacionalService = dadoVocacionalService;
        this.dadoFomandoCAService = dadoFomandoCAService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.mensagemService = mensagemService;
        this.respostaQuestionarioService = respostaQuestionarioService;
        this.questionarioFormandoService = questionarioFormandoService;
        this.dadoFomandoCABuilder = dadoFomandoCABuilder;
        this.caminhoFormativoBuilder = caminhoFormativoBuilder;
        this.respostaQuestionarioBuilder = respostaQuestionarioBuilder;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.route = route;
        this.router = router;
        this.intlTelInputCalled = false;
        this.missoesVinculo = new Array();
        this.missoes = new Array();
        // this.formasVida = new Array<ElementoGrupoDominio>();
        this.niveisFormacao = new Array();
        // this.estadosVida = new Array<ElementoGrupoDominio>();
        this.pessoaFormadorPessoalSelecionado =
            this.pessoaInfoBuilder.getInstance();
        this.pessoaFormadorComunitarioSelecionado =
            this.pessoaInfoBuilder.getInstance();
        this.dadoFormandoCA = this.dadoFomandoCABuilder.getInstance();
        this.caminhoFormativo = this.caminhoFormativoBuilder.getInstance();
        // this.mensagemRL = this.mensagemBuilder.getInstance();
        this.imageChangedEvent = "";
        this.croppedImage = "";
        this.nextClicked = false;
        this.pessoa = this.pessoaBuilder.getInstance();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.url = environment.usuario.selectPorUsername;
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.tabNames = ["dados-pessoais-tab-content", "questionario-tab-content"];
        this.activeTabNumber = 0;
        this.resposta39 = respostaQuestionarioBuilder.getInstance();
        this.resposta40 = respostaQuestionarioBuilder.getInstance();
        this.resposta41 = respostaQuestionarioBuilder.getInstance();
        this.resposta42 = respostaQuestionarioBuilder.getInstance();
        this.resposta43 = respostaQuestionarioBuilder.getInstance();
        this.resposta44 = respostaQuestionarioBuilder.getInstance();
        this.resposta45 = respostaQuestionarioBuilder.getInstance();
        this.resposta46 = respostaQuestionarioBuilder.getInstance();
        this.resposta47 = respostaQuestionarioBuilder.getInstance();
        this.resposta48 = respostaQuestionarioBuilder.getInstance();
        this.resposta49 = respostaQuestionarioBuilder.getInstance();
    }
    QuestionarioCALViewAnswerComponent.prototype.ngOnInit = function () {
        this.initialization();
    };
    QuestionarioCALViewAnswerComponent.prototype.initialization = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.questionarioFormandoId = Number(this.route.snapshot.paramMap.get("id"));
                        return [4 /*yield*/, this.carregarQuestionarioFormando()];
                    case 1:
                        _a.sent();
                        // await this.getUsuarioAtual();
                        // this.carregarFormasVida();
                        this.carregarNiveisFormacao();
                        // this.carregarEstadoVida();
                        return [4 /*yield*/, this.carregarMissoesVinculo()];
                    case 2:
                        // this.carregarEstadoVida();
                        _a.sent();
                        return [4 /*yield*/, this.carregarPessoa()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.carregarDadoVocacional()];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, this.carregarDadoFormandoCA()];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, this.carregarCaminhoFormativo()];
                    case 6:
                        _a.sent();
                        return [4 /*yield*/, this.carregarRespostas()];
                    case 7:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    // async getUsuarioAtual(): Promise<any> {
    //   try {
    //     let response = await this.usuarioService.getCurrentUser();
    //     this.usuarioAtual = response.entity;
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }
    QuestionarioCALViewAnswerComponent.prototype.carregarMissoesVinculo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.wopGetAllPublic.getAllMissao()];
                    case 1:
                        response = _a.sent();
                        this.missoesVinculo = response.entity;
                        this.missoesVinculo = this.missoesVinculo.sort(function (a, b) {
                            return a.nome.localeCompare(b.nome);
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_1.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALViewAnswerComponent.prototype.carregarPessoa = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, blogImage, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.pessoaService.buscaPorId(this.questionarioFormando.pessoaId)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        this.pessoaId = this.pessoa.id;
                        if (!(this.pessoa.imagemId != null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getPessoaImagem(this.pessoa.imagemId)];
                    case 2:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.pessoa);
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    // carregarFormasVida(): void {
    //   let buscaFormaVida = {
    //     grupoNome: "FORMA_VIDA",
    //   };
    //   this.elementoGrupoDominioService
    //     .buscarPorGrupoDominioNome(buscaFormaVida)
    //     .then((lista: any) => {
    //       this.formasVida = lista.entity;
    //     })
    //     .catch((err: any) => {
    //       this.swtAlert2Service.errorAlert(err.error.errors);
    //     });
    // }
    QuestionarioCALViewAnswerComponent.prototype.carregarNiveisFormacao = function () {
        var _this = this;
        var busca = {
            grupoNome: "NIVEL_FORMACAO",
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(busca)
            .then(function (lista) {
            _this.niveisFormacao = lista.entity;
        })
            .catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    // carregarEstadoVida(): void {
    //   let busca = {
    //     grupoNome: "ESTADO_VIDA",
    //   };
    //   this.elementoGrupoDominioService
    //     .buscarPorGrupoDominioNome(busca)
    //     .then((lista: any) => {
    //       this.estadosVida = lista.entity;
    //     })
    //     .catch((err: any) => {
    //       this.swtAlert2Service.errorAlert(err.error.errors);
    //     });
    // }
    QuestionarioCALViewAnswerComponent.prototype.logOnConsole = function (dadosPessoaisForm) {
        console.log(dadosPessoaisForm);
    };
    QuestionarioCALViewAnswerComponent.prototype.activateTab = function (tab) {
        $('.nav-tabs a[href="#' + tab + '"]').removeClass("disabled");
        $('.nav-tabs a[href="#' + tab + '"]').tab("show");
    };
    QuestionarioCALViewAnswerComponent.prototype.activateTab2 = function (action) {
        if (action.localeCompare("previous") == 0) {
            if (this.activeTabNumber > 0) {
                this.activeTabNumber -= 1;
            }
        }
        else if (action.localeCompare("next") == 0) {
            if (this.activeTabNumber < this.tabNames.length - 1) {
                this.activeTabNumber += 1;
            }
        }
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').removeClass("disabled");
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').tab("show");
    };
    QuestionarioCALViewAnswerComponent.prototype.setarElementoSelecionado = function (valor) {
        this.pessoa.usuarioId = valor;
    };
    QuestionarioCALViewAnswerComponent.prototype.salvar = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, blogImage, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.pessoaService.update(pessoa.id, pessoa)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        if (!(this.pessoa.imagemId != null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getPessoaImagem(this.pessoa.imagemId)];
                    case 2:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.pessoa);
                        _a.label = 3;
                    case 3:
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 5];
                    case 4:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 5];
                    case 5:
                        this.atualizarDadoFormandoCA();
                        this.atualizarCaminhoFormativo();
                        this.atualizarQuestionarioFormando("INICIADO");
                        return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALViewAnswerComponent.prototype.salvarImagem = function () {
        var _this = this;
        this.imagemService
            .salvarCropped({ imageData: this.croppedImage })
            .then(function (response) {
            _this.swtAlert2Service.successAlert(response.message);
            _this.pessoa.imagemId = response.entity.id;
            _this.salvar(_this.pessoa);
            $("#modal-pessoa-imagem").modal("hide");
        })
            .catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    QuestionarioCALViewAnswerComponent.prototype.fileChangeEvent = function (event) {
        this.imageChangedEvent = event;
        console.log(event);
    };
    QuestionarioCALViewAnswerComponent.prototype.imageCropped = function (event) {
        this.croppedImage = event.base64;
    };
    QuestionarioCALViewAnswerComponent.prototype.imageLoaded = function () {
        console.log("Imagem carregada");
    };
    QuestionarioCALViewAnswerComponent.prototype.cropperReady = function () {
        console.log("Imagem cortada");
    };
    QuestionarioCALViewAnswerComponent.prototype.loadImageFailed = function () {
        console.log("Carregamento da imagem falhou!");
    };
    // setarFormaVidaSelecionada(): void {
    //   for (let i = 0; i < this.formasVida.length; i++) {
    //     if (this.formasVida[i].id == this.pessoa.formaVidaId) {
    //       this.formaVidaSelecionada = this.formasVida[i];
    //     }
    //   }
    // }
    QuestionarioCALViewAnswerComponent.prototype.carregarDadoVocacional = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.dadoVocacionalService.porPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response.entity != undefined && response.entity != null) {
                            this.dadoVocacional = response.entity;
                            this.pessoaFormadorPessoalSelecionado =
                                this.pessoaInfoBuilder.getInstance();
                            this.pessoaFormadorPessoalSelecionado.nome =
                                this.dadoVocacional.formadorPessoalPessoaNome;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALViewAnswerComponent.prototype.selecionarFormadorPessoal = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
                this.pessoaFormadorPessoalSelecionado = pessoa;
                $("#listagem-pessoa-formador-pessoal").modal("hide");
                return [2 /*return*/];
            });
        });
    };
    QuestionarioCALViewAnswerComponent.prototype.selecionarFormadorComunitario = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                //this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
                this.pessoaFormadorComunitarioSelecionado = pessoa;
                $("#listagem-pessoa-formador-comunitario").modal("hide");
                return [2 /*return*/];
            });
        });
    };
    QuestionarioCALViewAnswerComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALViewAnswerComponent.prototype.carregarDadoFormandoCA = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.dadoFomandoCAService.porPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.dadoFormandoCA = response;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_5.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALViewAnswerComponent.prototype.carregarCaminhoFormativo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.caminhoFormativoService.porPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.caminhoFormativo = response;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_6 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_6.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALViewAnswerComponent.prototype.atualizarCaminhoFormativo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, err_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        this.caminhoFormativo.missao_atual_id =
                            this.pessoa.vinculoMissao.missaoAtualId;
                        this.caminhoFormativo.nome_formador_pessoal =
                            this.pessoaFormadorPessoalSelecionado.nome;
                        if (!(this.caminhoFormativo.id == null)) return [3 /*break*/, 2];
                        this.caminhoFormativo.pessoaId = this.pessoa.id;
                        return [4 /*yield*/, this.caminhoFormativoService.cadastrar(this.caminhoFormativo)];
                    case 1:
                        response = _a.sent();
                        this.caminhoFormativo = response;
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.caminhoFormativoService.atualizar(this.caminhoFormativo)];
                    case 3:
                        response = _a.sent();
                        this.caminhoFormativo = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_7 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_7.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALViewAnswerComponent.prototype.carregarRespostas = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_8;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.respostaQuestionarioService.porPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.respostas = response.content;
                            this.respostas.forEach(function (resposta) {
                                switch (resposta.questionario_pergunta_id) {
                                    case 39:
                                        _this.resposta39 = resposta;
                                        break;
                                    case 40:
                                        _this.resposta40 = resposta;
                                        break;
                                    case 41:
                                        _this.resposta41 = resposta;
                                        break;
                                    case 42:
                                        _this.resposta42 = resposta;
                                        break;
                                    case 43:
                                        _this.resposta43 = resposta;
                                        break;
                                    case 44:
                                        _this.resposta44 = resposta;
                                        break;
                                    case 45:
                                        _this.resposta45 = resposta;
                                        break;
                                    case 46:
                                        _this.resposta46 = resposta;
                                        break;
                                    case 47:
                                        _this.resposta47 = resposta;
                                        break;
                                    case 48:
                                        _this.resposta48 = resposta;
                                        break;
                                    case 49:
                                        _this.resposta49 = resposta;
                                        break;
                                }
                            });
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_8 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_8.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALViewAnswerComponent.prototype.carregarQuestionarioFormando = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.questionarioFormandoService.buscarPorId(this.questionarioFormandoId)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.questionarioFormando = response;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_9 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_9.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALViewAnswerComponent.prototype.carregarMensagemAF = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_10;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.dadoFomandoCAService.porPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.dadoFormandoCA = response;
                            /*this.carregarEstados(this.dadoFormandoCA.pais_origem_id);
                                    this.carregarCidades(this.dadoFormandoCA.estado_origem_id);*/
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_10 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_10.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    // async carregarMensagemRLPorOrigem(): Promise<any> {
    //   try {
    //     let response = await this.mensagemService.mensagemRLPorOrigemId(
    //       this.questionarioFormando.id
    //     );
    //     if (response != null && response != undefined) {
    //       this.mensagemRL = response;
    //     }
    //   } catch (err) {
    //     this.swtAlert2Service.errorAlert(err.error.errors);
    //   }
    // }
    QuestionarioCALViewAnswerComponent.prototype.atualizarDadoFormandoCA = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, err_11;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!(this.dadoFormandoCA.id == null)) return [3 /*break*/, 2];
                        this.dadoFormandoCA.pessoaId = this.pessoa.id;
                        return [4 /*yield*/, this.dadoFomandoCAService.cadastrar(this.dadoFormandoCA)];
                    case 1:
                        response = _a.sent();
                        this.dadoFormandoCA = response;
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.dadoFomandoCAService.atualizar(this.dadoFormandoCA)];
                    case 3:
                        response = _a.sent();
                        this.dadoFormandoCA = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_11 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_11.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALViewAnswerComponent.prototype.atualizarResposta = function (resposta) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, err_12;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        resposta.pessoaId = this.pessoa.id;
                        if (!(resposta.id == null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.respostaQuestionarioService.cadastrar(resposta)];
                    case 1:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        return [2 /*return*/, response];
                    case 2: return [4 /*yield*/, this.respostaQuestionarioService.atualizar(resposta.id, resposta)];
                    case 3:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        return [2 /*return*/, response];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_12 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_12.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    // async atualizarMensagem(): Promise<any> {
    //   try {
    //     if (this.mensagemRL.id == null) {
    //       this.mensagemRL.origem = 1; //1 - Questionario Formando
    //       this.mensagemRL.origem_id = this.questionarioFormandoId;
    //       this.mensagemRL.lida = 0;
    //       let response = await this.mensagemService.cadastrarMensagemRL(
    //         this.mensagemRL
    //       );
    //       this.mensagemRL = response;
    //       this.swtAlert2Service.successAlert(response.message);
    //     } else {
    //       let response = await this.mensagemService.atualizarMensagemRL(
    //         this.mensagemRL
    //       );
    //       this.mensagemRL = response;
    //       this.swtAlert2Service.successAlert(response.message);
    //     }
    //   } catch (err) {
    //     this.swtAlert2Service.errorAlert(err.error.errors);
    //   }
    // }
    // async salvarQuestionario(): Promise<any> {
    //   if (!this.respostaVazia(this.resposta39)) {
    //     this.resposta39.questionario_pergunta_id = 39;
    //     this.resposta39 = await this.atualizarResposta(this.resposta39);
    //   }
    //   if (!this.respostaVazia(this.resposta40)) {
    //     this.resposta40.questionario_pergunta_id = 40;
    //     this.resposta40 = await this.atualizarResposta(this.resposta40);
    //   }
    //   if (!this.respostaVazia(this.resposta41)) {
    //     this.resposta41.questionario_pergunta_id = 41;
    //     this.resposta41 = await this.atualizarResposta(this.resposta41);
    //   }
    //   if (!this.respostaVazia(this.resposta42)) {
    //     this.resposta42.questionario_pergunta_id = 42;
    //     this.resposta42 = await this.atualizarResposta(this.resposta42);
    //   }
    //   if (!this.respostaVazia(this.resposta43)) {
    //     this.resposta43.questionario_pergunta_id = 43;
    //     this.resposta43 = await this.atualizarResposta(this.resposta43);
    //   }
    //   if (!this.respostaVazia(this.resposta44)) {
    //     this.resposta44.questionario_pergunta_id = 44;
    //     this.resposta44 = await this.atualizarResposta(this.resposta44);
    //   }
    //   if (!this.respostaVazia(this.resposta45)) {
    //     this.resposta45.questionario_pergunta_id = 45;
    //     this.resposta45 = await this.atualizarResposta(this.resposta45);
    //   }
    //   if (!this.respostaVazia(this.resposta46)) {
    //     this.resposta46.questionario_pergunta_id = 46;
    //     this.resposta46 = await this.atualizarResposta(this.resposta46);
    //   }
    //   if (!this.respostaVazia(this.resposta47)) {
    //     this.resposta47.questionario_pergunta_id = 47;
    //     this.resposta47 = await this.atualizarResposta(this.resposta47);
    //   }
    //   if (!this.respostaVazia(this.resposta48)) {
    //     this.resposta48.questionario_pergunta_id = 48;
    //     this.resposta48 = await this.atualizarResposta(this.resposta48);
    //   }
    //   if (!this.respostaVazia(this.resposta49)) {
    //     this.resposta49.questionario_pergunta_id = 49;
    //     this.resposta49 = await this.atualizarResposta(this.resposta49);
    //   }
    //   // if (this.mensagemRL.mensagem != null) this.atualizarMensagem();
    //   // let status = this.definirStatusQuestionario();
    //   this.atualizarQuestionarioFormando("INICIADO");
    // }
    QuestionarioCALViewAnswerComponent.prototype.definirStatusQuestionario = function () {
        var status = "INICIADO";
        var todoRespondido = false;
        if (!this.respostaVazia(this.resposta39) &&
            !this.respostaVazia(this.resposta41) &&
            !this.respostaVazia(this.resposta40) &&
            !this.respostaVazia(this.resposta42) &&
            !this.respostaVazia(this.resposta43) &&
            !this.respostaVazia(this.resposta44) &&
            !this.respostaVazia(this.resposta45) &&
            !this.respostaVazia(this.resposta46) &&
            !this.respostaVazia(this.resposta47) &&
            !this.respostaVazia(this.resposta48) &&
            !this.respostaVazia(this.resposta49)) {
            todoRespondido = true;
        }
        if (todoRespondido) {
            status = "ENVIADO";
        }
        return status;
    };
    QuestionarioCALViewAnswerComponent.prototype.respostaVazia = function (resposta) {
        return (resposta.resposta == null ||
            resposta.resposta == undefined ||
            resposta.resposta == "");
    };
    // async enviarQuestionario(): Promise<any> {
    //   await this.salvarQuestionario();
    //   await this.atualizarQuestionarioFormando("ENVIADO");
    //   this.router.navigate(["secured/questionario-agradecimento"]);
    // }
    QuestionarioCALViewAnswerComponent.prototype.atualizarQuestionarioFormando = function (status) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_13;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!(this.questionarioFormando.id != null)) return [3 /*break*/, 2];
                        this.questionarioFormando.status = status;
                        if (status == "ENVIADO") {
                            this.questionarioFormando.data_envio = new Date();
                        }
                        return [4 /*yield*/, this.questionarioFormandoService.atualizar(this.questionarioFormando.id, this.questionarioFormando)];
                    case 1:
                        response = _a.sent();
                        this.questionarioFormando = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        err_13 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_13.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return QuestionarioCALViewAnswerComponent;
}());
export { QuestionarioCALViewAnswerComponent };
