import * as tslib_1 from "tslib";
import { HistoricoMissionarioMissao } from '../model/historicomissionariomissao.model';
import { AbstractBuilder } from './abstract.builder';
var HistoricoMissionarioMissaoBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(HistoricoMissionarioMissaoBuilder, _super);
    function HistoricoMissionarioMissaoBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HistoricoMissionarioMissaoBuilder.prototype.reset = function () {
        this.entity = new HistoricoMissionarioMissao(null, null, null, null, null, null, null, null, null, null, false);
    };
    return HistoricoMissionarioMissaoBuilder;
}(AbstractBuilder));
export { HistoricoMissionarioMissaoBuilder };
