import * as tslib_1 from "tslib";
import { DadosDetalhamentoMissionarioMissaoAtual } from '../model/dadosdetalhamentomissionariomissaoatual.model';
import { AbstractBuilder } from './abstract.builder';
var DadosDetalhamentoMissionarioMissaoAtualBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(DadosDetalhamentoMissionarioMissaoAtualBuilder, _super);
    function DadosDetalhamentoMissionarioMissaoAtualBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DadosDetalhamentoMissionarioMissaoAtualBuilder.prototype.reset = function () {
        this.entity = new DadosDetalhamentoMissionarioMissaoAtual(null, null, null, null, null, null, null, null, null, null, null, null);
    };
    return DadosDetalhamentoMissionarioMissaoAtualBuilder;
}(AbstractBuilder));
export { DadosDetalhamentoMissionarioMissaoAtualBuilder };
