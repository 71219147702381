import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
import { environment } from 'src/environments/environment';
var FormadorComunitarioService = /** @class */ (function (_super) {
    tslib_1.__extends(FormadorComunitarioService, _super);
    function FormadorComunitarioService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType('formadorcomunitario');
        return _this;
    }
    FormadorComunitarioService.prototype.buscarPorCelula = function (busca) {
        return this.http.post(this.apiURL + '/porcelula', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise();
    };
    FormadorComunitarioService.prototype.buscarPorPessoaId = function (id) {
        return this.http.get(this.apiURL + "/porpessoa/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    FormadorComunitarioService.prototype.buscarCelulaPorPessoaId = function (id) {
        return this.http.get(environment.moduloFormacao.urlModuloFormacao + "api/secured/formadorcomunitario/celula/porpessoaid/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    FormadorComunitarioService.prototype.buscarCelulaPorId = function (id) {
        return this.http.get(environment.moduloFormacao.urlModuloFormacao + "api/secured/formadorcomunitario/celula/porid/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    return FormadorComunitarioService;
}(GenericService));
export { FormadorComunitarioService };
