import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
var EscolaridadeService = /** @class */ (function (_super) {
    tslib_1.__extends(EscolaridadeService, _super);
    function EscolaridadeService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType('r/escolaridade');
        return _this;
    }
    EscolaridadeService.prototype.findByAptidaoId = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(this.apiURL + '/poraptidao/' + id, { 'headers': this.getHeaders() }).toPromise()];
            });
        });
    };
    return EscolaridadeService;
}(GenericService));
export { EscolaridadeService };
