import * as tslib_1 from "tslib";
import { PessoaNacionalidade } from '../model/pessoanacionalidade.model';
import { AbstractBuilder } from './abstract.builder';
var PessoaNacionalidadeBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(PessoaNacionalidadeBuilder, _super);
    function PessoaNacionalidadeBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PessoaNacionalidadeBuilder.prototype.reset = function () {
        this.entity = new PessoaNacionalidade(null, null, null, null);
    };
    return PessoaNacionalidadeBuilder;
}(AbstractBuilder));
export { PessoaNacionalidadeBuilder };
