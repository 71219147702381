import * as tslib_1 from "tslib";
import { AbstractBuilder } from './abstract.builder';
import { TermoTratamentoDados } from '../model/termotratamentodados.model';
var TermoTratamentoDadosBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(TermoTratamentoDadosBuilder, _super);
    function TermoTratamentoDadosBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TermoTratamentoDadosBuilder.prototype.reset = function () {
        this.entity = new TermoTratamentoDados(null, null, null, null);
    };
    return TermoTratamentoDadosBuilder;
}(AbstractBuilder));
export { TermoTratamentoDadosBuilder };
