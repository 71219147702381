import * as tslib_1 from "tslib";
import { AbstractBuilder } from './abstract.builder';
import { RespostaParecer } from '../model/respostaparecer.model';
var RespostaParecerBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(RespostaParecerBuilder, _super);
    function RespostaParecerBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RespostaParecerBuilder.prototype.reset = function () {
        this.entity = new RespostaParecer(null, null, null, null);
    };
    return RespostaParecerBuilder;
}(AbstractBuilder));
export { RespostaParecerBuilder };
