import * as tslib_1 from "tslib";
import { TermoUso } from '../model/termouso.model';
import { AbstractBuilder } from './abstract.builder';
var TermoUsoBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(TermoUsoBuilder, _super);
    function TermoUsoBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TermoUsoBuilder.prototype.reset = function () {
        this.entity = new TermoUso(null, null, null, null);
    };
    return TermoUsoBuilder;
}(AbstractBuilder));
export { TermoUsoBuilder };
