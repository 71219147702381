import * as tslib_1 from "tslib";
import { Sacerdote } from '../model/sacerdote.model';
import { AbstractBuilder } from './abstract.builder';
var SacerdoteBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(SacerdoteBuilder, _super);
    function SacerdoteBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SacerdoteBuilder.prototype.reset = function () {
        this.entity = new Sacerdote(null, null, null, null);
    };
    return SacerdoteBuilder;
}(AbstractBuilder));
export { SacerdoteBuilder };
