import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { Login } from "../model/login.model";
var LoginComponent = /** @class */ (function () {
    function LoginComponent(esqueceuSenhaService, loginService, termoUsoBuilder, termoTratamentoDadosBuilder, cadastroPublicoService, swtAlert2Service) {
        this.esqueceuSenhaService = esqueceuSenhaService;
        this.loginService = loginService;
        this.termoUsoBuilder = termoUsoBuilder;
        this.termoTratamentoDadosBuilder = termoTratamentoDadosBuilder;
        this.cadastroPublicoService = cadastroPublicoService;
        this.swtAlert2Service = swtAlert2Service;
        this.passwordFieldType = "password";
        this.termoVigente = this.termoUsoBuilder.getInstance();
        this.termoTratamentoDadosVigente =
            this.termoTratamentoDadosBuilder.getInstance();
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.login = new Login("", "");
        this.carregarTermoVigente();
        this.carregarTermoTratamentoDadosVigente();
    };
    LoginComponent.prototype.fazerLogin = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.login.username = this.login.username.trim();
                this.loginService.fazerLogin(this.login);
                return [2 /*return*/];
            });
        });
    };
    LoginComponent.prototype.togglePasswordVisibility = function () {
        this.passwordFieldType =
            this.passwordFieldType === "password" ? "text" : "password";
    };
    LoginComponent.prototype.recuperarSenha = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.emailRecuperacao != "" &&
                            this.emailRecuperacao != undefined &&
                            this.emailRecuperacao != null)) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.esqueceuSenhaService.esqueceuSenha(this.emailRecuperacao)];
                    case 2:
                        response = _a.sent();
                        this.swtAlert2Service.infoAlert(response.message);
                        $("#modal-esqueceu-senha").modal("hide");
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        this.swtAlert2Service.warningAlert("Informe o e-mail de recuperação!");
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype.carregarTermoVigente = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.cadastroPublicoService.getTermoVigente()];
                    case 1:
                        response = _a.sent();
                        this.termoVigente = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_1.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype.carregarTermoTratamentoDadosVigente = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.cadastroPublicoService.getTermoTratamentoDadosVigente()];
                    case 1:
                        response = _a.sent();
                        this.termoTratamentoDadosVigente = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return LoginComponent;
}());
export { LoginComponent };
