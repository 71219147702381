import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
var ApostoladoService = /** @class */ (function (_super) {
    tslib_1.__extends(ApostoladoService, _super);
    function ApostoladoService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType('r/apostolado');
        return _this;
    }
    ApostoladoService.prototype.pesquisar = function (request) {
        return this.http.post(this.apiURL + "/pesquisar", JSON.stringify(request), { 'headers': this.getHeaders() }).toPromise();
    };
    ApostoladoService.prototype.porTipoId = function (id) {
        return this.http.get(this.apiURL + "/portipo/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    return ApostoladoService;
}(GenericService));
export { ApostoladoService };
