import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { MembroComunidadeBuilder } from "src/app/builder/membro.comunidade.builder";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
var ParecerCoordenadorColegiadoCelulaComponent = /** @class */ (function () {
    function ParecerCoordenadorColegiadoCelulaComponent(missaoConfiguracaoService, usuarioService, imagemService, pessoaService, celulaService, membroService, coordenadorColegiadoService, formadorComunitarioService, formadorAssistenteService, questionarioFormandoBuilder, questionarioFormandoService, parecerResponsavelBuilder, parecerResponsavelService, discernimentoService, route, router, swtAlert2Service) {
        this.missaoConfiguracaoService = missaoConfiguracaoService;
        this.usuarioService = usuarioService;
        this.imagemService = imagemService;
        this.pessoaService = pessoaService;
        this.celulaService = celulaService;
        this.membroService = membroService;
        this.coordenadorColegiadoService = coordenadorColegiadoService;
        this.formadorComunitarioService = formadorComunitarioService;
        this.formadorAssistenteService = formadorAssistenteService;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelBuilder = parecerResponsavelBuilder;
        this.parecerResponsavelService = parecerResponsavelService;
        this.discernimentoService = discernimentoService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.totalDePaginas = 1;
        this.pessoaBuilder = new PessoaBuilder();
        this.membrosCelula = new Array();
        this.membroComunidadeBuilder = new MembroComunidadeBuilder();
        this.membroComunidade = this.membroComunidadeBuilder.getInstance();
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
    }
    ParecerCoordenadorColegiadoCelulaComponent.prototype.ngOnInit = function () {
        this.initializer();
        this.hasCelulaAssociada = false;
        this.paginacaoConfig = {
            page: 0,
            size: 10,
            totalPages: 0,
            totalElements: 0,
        };
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.initializer = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.carregarUsuario()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.carregarPessoa()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.carregarFormadoresCelula()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.carregarQuestionarioConfiguracao = function (questionarioId) {
        var _this = this;
        var missaoId = this.pessoa.vinculoMissao.missaoAtualId;
        this.missaoConfiguracaoService
            .obterConfiguracaoQuestionarioPorMissaoEQuestionario(missaoId, questionarioId)
            .subscribe(function (questionarioConfiguracao) {
            return (_this.questionarioConfiguracao = questionarioConfiguracao);
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.carregarUsuario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuario = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.carregarPessoa = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.pessoaService.buscaPorUsuarioId(this.usuario.id)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.log(e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.carregarQuestionario = function (pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.questionarioFormandoService.buscarPorPessoaId(pessoaId)];
                    case 1:
                        response = _a.sent();
                        if (response != undefined && response != null) {
                            return [2 /*return*/, response];
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.carregarFormadoresCelula = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, i, blogImage, _a, err_2;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 9, , 10]);
                        return [4 /*yield*/, this.discernimentoService.obterMembrosParaDiscernimentoDoCoordenadorDeColegiado(this.paginacaoConfig.page, this.paginacaoConfig.size)];
                    case 1:
                        response = _b.sent();
                        this.formadoresDaMissao = response.content;
                        this.paginacaoConfig.totalPages = response.totalPages;
                        this.paginacaoConfig.totalElements = response.totalElements;
                        if (this.formadoresDaMissao.length > 0) {
                            this.formadoresDaMissao = this.formadoresDaMissao.sort(function (a, b) {
                                return a.pessoaNome.localeCompare(b.pessoaNome);
                            });
                        }
                        i = 0;
                        _b.label = 2;
                    case 2:
                        if (!(i < this.formadoresDaMissao.length)) return [3 /*break*/, 8];
                        if (!(this.formadoresDaMissao[i].pessoaImagemId != null)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getPessoaImagem(this.formadoresDaMissao[i].pessoaImagemId)];
                    case 3:
                        blogImage = _b.sent();
                        this.imagemService.createImage(blogImage, this.formadoresDaMissao[i]);
                        _b.label = 4;
                    case 4:
                        if (this.formadoresDaMissao[i].questionarioFormandoStatus == "NAOINICIADO") {
                            this.formadoresDaMissao[i].questionarioFormandoStatus =
                                "Não Iniciado";
                        }
                        if (this.formadoresDaMissao[i].parecerStatus == "NAOINICIADO") {
                            this.formadoresDaMissao[i].parecerStatus = "Não Iniciado";
                        }
                        if (!(!this.questionarioFormando || !this.questionarioFormando.id)) return [3 /*break*/, 6];
                        _a = this;
                        return [4 /*yield*/, this.carregarQuestionario(this.formadoresDaMissao[i].pessoaId)];
                    case 5:
                        _a.questionarioFormando = _b.sent();
                        if (this.questionarioFormando) {
                            if (!this.questionarioConfiguracao) {
                                this.carregarQuestionarioConfiguracao(this.questionarioFormando.questionarioId);
                            }
                        }
                        return [3 /*break*/, 7];
                    case 6:
                        if (!this.questionarioConfiguracao) {
                            this.carregarQuestionarioConfiguracao(this.questionarioFormando.questionarioId);
                        }
                        _b.label = 7;
                    case 7:
                        i++;
                        return [3 /*break*/, 2];
                    case 8: return [3 /*break*/, 10];
                    case 9:
                        err_2 = _b.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 10];
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.cadastrarVinculoParecerCAL = function (questionarioFormandoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.parecerResponsavel.questionarioFormandoId = questionarioFormandoId;
                        this.parecerResponsavel.parecer_id = 2;
                        this.parecerResponsavel.pessoaResponsavelId = this.pessoa.id;
                        return [4 /*yield*/, this.parecerResponsavelService.cadastrar(this.parecerResponsavel)];
                    case 1:
                        response = _a.sent();
                        if (response != undefined && response != null) {
                            this.parecerResponsavel = response;
                            this.router.navigate([
                                "/secured/parecer-cal-answer/" + this.parecerResponsavel.id,
                            ]);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.consultarVinculoParecerCAL = function (formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, _a, err_4;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 5, , 6]);
                        if (!(formando.questionarioFormandoId != null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.parecerResponsavelService.buscarPorQuestionarioFormandoId(formando.questionarioFormandoId)];
                    case 1:
                        response = _b.sent();
                        if (response != undefined && response != null) {
                            this.parecerResponsavel = response;
                            this.router.navigate([
                                "/secured/parecer-cal-answer/" + this.parecerResponsavel.id,
                            ]);
                        }
                        else {
                            this.cadastrarVinculoParecerCAL(formando.questionarioFormandoId);
                        }
                        _b.label = 2;
                    case 2:
                        if (!(formando.questionarioFormandoId == null)) return [3 /*break*/, 4];
                        _a = this;
                        return [4 /*yield*/, this.cadastrarVinculoQuestionarioCAL(formando.pessoaId)];
                    case 3:
                        _a.questionarioFormando = _b.sent();
                        this.cadastrarVinculoParecerCAL(this.questionarioFormando.id);
                        _b.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_4 = _b.sent();
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.cadastrarVinculoQuestionarioCAL = function (pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.questionarioFormando.anoFormativoId = 2;
                        this.questionarioFormando.questionarioId = 3;
                        this.questionarioFormando.pessoaId = pessoaId;
                        return [4 /*yield*/, this.questionarioFormandoService.cadastrar(this.questionarioFormando)];
                    case 1:
                        response = _a.sent();
                        if (response != undefined && response != null) {
                            return [2 /*return*/, response];
                        }
                        else {
                            this.swtAlert2Service.errorAlert("Não foi possível cadastrar um vinculo de questionário para o formando");
                            throw new Error("Não foi possível cadastrar um vinculo de questionário para o formando");
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_5.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_6 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_6.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.cliqueNoBotaoListener = function (botao) {
        this.paginacaoConfig.page = botao.numero - 1;
        this.carregarFormadoresCelula();
    };
    return ParecerCoordenadorColegiadoCelulaComponent;
}());
export { ParecerCoordenadorColegiadoCelulaComponent };
