import * as tslib_1 from "tslib";
import { AbstractBuilder } from './abstract.builder';
import { AceiteTermo } from '../model/aceitetermo.model';
var AceiteTermoBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(AceiteTermoBuilder, _super);
    function AceiteTermoBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AceiteTermoBuilder.prototype.reset = function () {
        this.entity = new AceiteTermo(null, null, null, null, null);
    };
    return AceiteTermoBuilder;
}(AbstractBuilder));
export { AceiteTermoBuilder };
