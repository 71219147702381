import * as tslib_1 from "tslib";
import { AbstractBuilder } from './abstract.builder';
import { FamiliaInfo } from '../model/familiainfo.model';
var FamiliaInfoBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(FamiliaInfoBuilder, _super);
    function FamiliaInfoBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FamiliaInfoBuilder.prototype.reset = function () {
        this.entity = new FamiliaInfo(null, null, null, null, null, null);
    };
    return FamiliaInfoBuilder;
}(AbstractBuilder));
export { FamiliaInfoBuilder };
