import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
var FilhoService = /** @class */ (function (_super) {
    tslib_1.__extends(FilhoService, _super);
    function FilhoService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType('filho');
        return _this;
    }
    FilhoService.prototype.porPaiId = function (paiId) {
        return this.http.get(this.apiURL + '/porpai/' + paiId, { 'headers': this.getHeaders() }).toPromise();
    };
    FilhoService.prototype.porMaeId = function (maeId) {
        return this.http.get(this.apiURL + '/pormae/' + maeId, { 'headers': this.getHeaders() }).toPromise();
    };
    FilhoService.prototype.porFilhoId = function (filhoId) {
        return this.http.get(this.apiURL + '/porfilho/' + filhoId, { 'headers': this.getHeaders() }).toPromise();
    };
    return FilhoService;
}(GenericService));
export { FilhoService };
