import * as tslib_1 from "tslib";
import { GenericService } from "src/app/core/services/generic.service";
import { environment } from "src/environments/environment";
var MensagemService = /** @class */ (function (_super) {
    tslib_1.__extends(MensagemService, _super);
    function MensagemService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType("mensagem");
        return _this;
    }
    MensagemService.prototype.porPessoaId = function (id) {
        return this.http
            .get("" + environment.moduloFormacao.buscarCaminhoFormativo + id, {
            headers: this.getHeaders(),
        })
            .toPromise();
    };
    //Corrigir rota
    /*buscarMensagemRL(request: any) {
          return this.http.get<any>(`${environment.moduloFormacao.urlModuloFormacao}mensagemrl`, JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
      }
      //Corrigir rota
      buscarMensagemAF(request: any) {
          return this.http.get<any>(`${environment.moduloFormacao.urlModuloFormacao}mensagemaf`, JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
      }*/
    MensagemService.prototype.cadastrarMensagemRL = function (request) {
        return this.http
            .post(environment.moduloFormacao.urlModuloFormacao + "mensagemrl", JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    MensagemService.prototype.cadastrarMensagemAF = function (request) {
        return this.http
            .post(environment.moduloFormacao.urlModuloFormacao + "mensagemaf", JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    MensagemService.prototype.cadastrarMensagem = function (request) {
        return this.http
            .post(environment.moduloFormacao.urlModuloFormacao + "mensagem", JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    MensagemService.prototype.atualizarMensagemRL = function (request) {
        return this.http
            .put(environment.moduloFormacao.urlModuloFormacao + "mensagemrl/" + request.id, JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    MensagemService.prototype.atualizarMensagemAF = function (request) {
        return this.http
            .put(environment.moduloFormacao.urlModuloFormacao + "mensagemaf", JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    MensagemService.prototype.atualizarMensagem = function (request) {
        return this.http
            .put(environment.moduloFormacao.urlModuloFormacao + "mensagem", JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    MensagemService.prototype.porQuestionarioFormandoId = function (id) {
        return this.http
            .get(environment.moduloFormacao.urlModuloFormacao + "mensagem/porquestionarioformando/" + id, { headers: this.getHeaders() })
            .toPromise();
    };
    MensagemService.prototype.mensagemRLPorOrigemId = function (id) {
        return this.http
            .get(environment.moduloFormacao.urlModuloFormacao + "mensagemrl/origem/" + id, { headers: this.getHeaders() })
            .toPromise();
    };
    return MensagemService;
}(GenericService));
export { MensagemService };
