import * as tslib_1 from "tslib";
import { GenericService } from "src/app/core/services/generic.service";
var MissaoConfiguracaoService = /** @class */ (function (_super) {
    tslib_1.__extends(MissaoConfiguracaoService, _super);
    function MissaoConfiguracaoService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType("missao/configuracao");
        return _this;
    }
    MissaoConfiguracaoService.prototype.obterConfiguracaoQuestionarioPorMissaoEQuestionario = function (missaoId, questionarioId) {
        return this.http.get(this.apiURL + "/m/" + missaoId + "/q/" + questionarioId, {
            headers: this.getHeaders(),
        });
    };
    return MissaoConfiguracaoService;
}(GenericService));
export { MissaoConfiguracaoService };
