import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { GenericService } from "src/app/core/services/generic.service";
import { environment } from "src/environments/environment";

export type MissaoConfiguracao = {
  id: number;
  missaoId: number;
  hasColegiado: boolean;
  hasComissaoDiscernimento: boolean;
};

export type QuestionarioConfiguracao = {
  id: number;
  missaoConfiguracaoId: number;
  questionarioId: number;
  periodoInicio: Date;
  periodoFim: Date;
  status: string;
};

@Injectable()
export class MissaoConfiguracaoService extends GenericService<MissaoConfiguracao> {
  constructor(protected http: HttpClient, protected router: Router) {
    super(http, router);
    this.setEntityType("missao/configuracao");
  }

  public obterConfiguracaoQuestionarioPorMissaoEQuestionario(
    missaoId: number,
    questionarioId: number
  ) {
    return this.http.get<QuestionarioConfiguracao>(
      `${this.apiURL}/m/${missaoId}/q/${questionarioId}`,
      {
        headers: this.getHeaders(),
      }
    );
  }
}
