import { GenericService } from 'src/app/core/services/generic.service';
import { Usuario } from 'src/app/model/usuario.model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";


@Injectable()
export class UsuarioService extends GenericService<Usuario> {
    
    constructor(protected http: HttpClient, protected router: Router) {
        super(http,router);
        this.setEntityType('r/usuario');
    }

    getCurrentUser(): Promise<any> {
        console.log("Chamou getCurrenteUser service");
        return this.http.get<Usuario>(this.apiURL+'/currentuser', { 'headers' : this.getHeaders() }).toPromise();
    }
}