import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import 'select2';
import intlTelInput from 'intl-tel-input';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { EnderecoBuilder } from 'src/app/builder/endereco.builder';
import { DocumentoBuilder } from 'src/app/builder/documento.builder';
import { AptidaoBuilder } from 'src/app/builder/aptidao.builder';
import { EscolaridadeBuilder } from 'src/app/builder/escolaridade.builder';
import { TrabalhoBuilder } from 'src/app/builder/trabalho.builder';
import { environment } from 'src/environments/environment';
var PerfilEditComponent = /** @class */ (function () {
    function PerfilEditComponent(wopGetAllPublic, pessoaService, enderecoService, paisService, estadoService, cidadeService, imagemService, elementoGrupoDominioService, elementoGrupoDominioLocalService, documentoService, regionalService, missaoService, difusaoService, escolaridadeService, trabalhoService, aptidaoService, swtAlert2Service, usuarioService, familiaInfoService, familiaInfoBuilder, vistoService, vistoBuilder, pessoaNacionalidadeService, pessoaNacionalidadeBuilder, nacionalidadeService, route, router) {
        this.wopGetAllPublic = wopGetAllPublic;
        this.pessoaService = pessoaService;
        this.enderecoService = enderecoService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.imagemService = imagemService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.documentoService = documentoService;
        this.regionalService = regionalService;
        this.missaoService = missaoService;
        this.difusaoService = difusaoService;
        this.escolaridadeService = escolaridadeService;
        this.trabalhoService = trabalhoService;
        this.aptidaoService = aptidaoService;
        this.swtAlert2Service = swtAlert2Service;
        this.usuarioService = usuarioService;
        this.familiaInfoService = familiaInfoService;
        this.familiaInfoBuilder = familiaInfoBuilder;
        this.vistoService = vistoService;
        this.vistoBuilder = vistoBuilder;
        this.pessoaNacionalidadeService = pessoaNacionalidadeService;
        this.pessoaNacionalidadeBuilder = pessoaNacionalidadeBuilder;
        this.nacionalidadeService = nacionalidadeService;
        this.route = route;
        this.router = router;
        this.intlTelInputCalled = false;
        this.regionais = new Array();
        this.missoesVinculo = new Array();
        this.difusoesVinculo = new Array();
        this.missoes = new Array();
        this.difusoes = new Array();
        this.paises = new Array();
        this.estados = new Array();
        this.cidades = new Array();
        this.escolaridades = new Array();
        this.trabalhos = new Array();
        this.estadosCivil = new Array();
        this.tiposDocumento = new Array();
        this.formasVida = new Array();
        this.niveisFormacao = new Array();
        this.estadosVida = new Array();
        this.habilidades = new Array();
        this.linguas = new Array();
        this.niveisEscolaridade = new Array();
        this.nacionalidadeSituacoes = new Array();
        this.tiposVisto = new Array();
        this.pessoaNacionalidades = new Array();
        this.nacionalidades = new Array();
        this.vistos = new Array();
        this.imageChangedEvent = '';
        this.croppedImage = '';
        this.nextClicked = false;
        this.pessoaBuilder = new PessoaBuilder();
        this.enderecoBuilder = new EnderecoBuilder();
        this.documentoBuilder = new DocumentoBuilder();
        this.aptidaoBuilder = new AptidaoBuilder();
        this.escolaridadeBuilder = new EscolaridadeBuilder();
        this.trabalhoBuilder = new TrabalhoBuilder();
        this.pessoa = this.pessoaBuilder.getInstance();
        this.endereco = this.enderecoBuilder.getInstance();
        this.documento = this.documentoBuilder.getInstance();
        this.aptidao = this.aptidaoBuilder.getInstance();
        this.escolaridade = this.escolaridadeBuilder.getInstance();
        this.trabalho = this.trabalhoBuilder.getInstance();
        this.familiaInfo = this.familiaInfoBuilder.getInstance();
        this.pessoaNacionalidade = this.pessoaNacionalidadeBuilder.getInstance();
        this.visto = this.vistoBuilder.getInstance();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.url = environment.usuario.selectPorUsername;
        this.tabNames = [
            'dados-pessoais-tab-content',
            'endereco-tab-content',
            'dados-vocacionais-tab-content',
            'aptidoes-tab-content',
            'trabalho-tab-content'
        ];
        this.activeTabNumber = 0;
    }
    PerfilEditComponent.prototype.ngOnInit = function () {
        this.initialization();
    };
    PerfilEditComponent.prototype.initialization = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUsuarioAtual()];
                    case 1:
                        _a.sent();
                        this.carregarPaises();
                        this.carregarFormasVida();
                        this.carregarTipoDocumento();
                        this.carregarEstadosCivil();
                        this.carregarNiveisFormacao();
                        this.carregarEstadoVida();
                        this.carregarNacionalidades();
                        /* this.carregarRegionais(); */
                        this.carregarLinguas();
                        this.carregarHabilidades();
                        this.carregarNiveisEscolaridade();
                        this.carregarNacionalidadeSituacao();
                        this.carregarTiposVisto();
                        return [4 /*yield*/, this.carregarMissoesVinculo()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.carregarDifusoesVinculo()];
                    case 3:
                        _a.sent();
                        $("select[name='linguas-select']").select2({
                            theme: "bootstrap4"
                        });
                        $("select[name='habilidades-select']").select2({
                            theme: "bootstrap4"
                        });
                        return [4 /*yield*/, this.carregarPessoa()];
                    case 4:
                        _a.sent();
                        this.encontrarDocumentosPorPessoaId(this.pessoaId);
                        return [4 /*yield*/, this.encontrarTrabalhoPorPessoaId(this.pessoaId)];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, this.carregarFamilia(this.pessoaId)];
                    case 6:
                        _a.sent();
                        return [4 /*yield*/, this.carregarPessoaNacionalidades(this.pessoaId)];
                    case 7:
                        _a.sent();
                        return [4 /*yield*/, this.carregarVistos(this.pessoaId)];
                    case 8:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PerfilEditComponent.prototype.getUsuarioAtual = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuarioAtual = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /* carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => {
            this.regionais = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    } */
    PerfilEditComponent.prototype.carregarOrigem = function () {
        if (this.regionalSelecionadoId != undefined) {
            if (this.tipoOrigem == "M") {
                this.carregarMissoes();
            }
            else if (this.tipoOrigem == "D") {
                this.carregarDifusoes();
            }
        }
    };
    PerfilEditComponent.prototype.carregarMissoesVinculo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.wopGetAllPublic.getAllMissao()];
                    case 1:
                        response = _a.sent();
                        this.missoesVinculo = response.entity;
                        this.missoesVinculo = this.missoesVinculo.sort(function (a, b) { return a.nome.localeCompare(b.nome); });
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PerfilEditComponent.prototype.carregarDifusoesVinculo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.wopGetAllPublic.getAllDifusao()];
                    case 1:
                        response = _a.sent();
                        this.difusoesVinculo = response.entity;
                        this.difusoesVinculo = this.difusoesVinculo.sort(function (a, b) { return a.nome.localeCompare(b.nome); });
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PerfilEditComponent.prototype.carregarMissoes = function () {
        var _this = this;
        this.missaoService.porRegional(this.regionalSelecionadoId).then(function (response) {
            _this.missoes = response.entity;
            _this.missoes = _this.missoes.sort(function (a, b) { return a.nome.localeCompare(b.nome, 'fi'); });
            _this.difusoes = null;
        }).catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    PerfilEditComponent.prototype.carregarDifusoes = function () {
        var _this = this;
        this.difusaoService.porRegional(this.regionalSelecionadoId).then(function (response) {
            _this.difusoes = response.entity;
            _this.difusoes = _this.difusoes.sort(function (a, b) { return a.nome.localeCompare(b.nome, 'fi'); });
            _this.missoes = null;
        }).catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    PerfilEditComponent.prototype.ngAfterViewChecked = function () {
        if (!this.intlTelInputCalled && $('#pessoa-telefone').length == 1) {
            var input = document.querySelector("#pessoa-telefone");
            this.intlTellInputInstance = intlTelInput(input, {
                initialCountry: "auto",
                geoIpLookup: function (callback) {
                    fetch("https://ipapi.co/json")
                        .then(function (res) { return res.json(); })
                        .then(function (data) { return callback(data.country_code); })
                        .catch(function () { return callback("us"); });
                },
                utilsScript: 'assets/scripts/utils.js'
            });
            this.intlTelInputCalled = true;
        }
    };
    PerfilEditComponent.prototype.carregarPaises = function () {
        var _this = this;
        this.paisService.findAll().then(function (lista) {
            _this.paises = lista.entity;
            _this.paises = _this.paises.sort(function (a, b) { return a.nome.localeCompare(b.nome, 'fi'); });
        }).catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    PerfilEditComponent.prototype.carregarEstados = function (paisId) {
        var _this = this;
        var busca = {
            paisId: paisId,
            nome: undefined,
            pageNumber: 0,
            quantityOfElements: 1000
        };
        this.estadoService.buscarEstados(busca).then(function (lista) {
            _this.estados = lista.entity;
        }).catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    PerfilEditComponent.prototype.carregarCidades = function (estadoId) {
        var _this = this;
        var busca = {
            estadoId: estadoId,
            nome: undefined,
            pageNumber: 0,
            quantityOfElements: 1000
        };
        this.cidadeService.buscarCidades(busca).then(function (lista) {
            _this.cidades = lista.entity;
        }).catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    PerfilEditComponent.prototype.carregarPessoa = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, blogImage, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.pessoaService.buscaPorUsuarioId(this.usuarioAtual.id)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        this.pessoaId = this.pessoa.id;
                        if (!(this.pessoa.imagemId != null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getPessoaImagem(this.pessoa.imagemId)];
                    case 2:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.pessoa);
                        _a.label = 3;
                    case 3:
                        if (this.pessoa.enderecoId != null) {
                            this.carregarEndereco(this.pessoa.enderecoId);
                        }
                        if (this.pessoa.usuarioId != null) {
                            $("select[name='usuario']").append(new Option(this.pessoa.usuarioUsername, this.pessoa.usuarioId.toString(), false, true));
                        }
                        if (this.pessoa.formaVidaId != null) {
                            this.setarFormaVidaSelecionada();
                        }
                        /* if(this.pessoa.obraId != null)
                        {
                            this.carregarObra(this.pessoa.obraId);
                        } */
                        /* if(this.pessoa.membroId != null)
                        {
                            if(this.pessoa.formaVidaValor == 'CA')
                            {
                                this.carregarMembroComunidadeAlianca(this.pessoa.membroId);
                            }
                            else
                            if(this.pessoa.formaVidaValor == 'CV')
                            {
                                this.carregarMembroComunidadeVida(this.pessoa.membroId);
                            }
                        } */
                        if (this.pessoa.aptidaoId != null) {
                            this.carregarAptidao(this.pessoa.aptidaoId);
                        }
                        if (this.pessoa.trabalhoIds.length > 0) {
                            this.encontrarTrabalhoPorPessoaId(this.pessoa.id);
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    PerfilEditComponent.prototype.carregarFamilia = function (pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.familiaInfoService.porPessoaId(pessoaId)];
                    case 1:
                        response = _a.sent();
                        if (response.entity != null) {
                            this.familiaInfo = response.entity;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PerfilEditComponent.prototype.carregarEndereco = function (enderecoId) {
        var _this = this;
        this.enderecoService.find(enderecoId).then(function (response) {
            _this.endereco = response.entity;
            _this.carregarEstados(_this.endereco.paisId);
            _this.carregarCidades(_this.endereco.estadoId);
        }).catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    /* carregarObra(obraId: number): void {
        this.obraService.find(obraId).then((response: any) => {
            this.obra = response.entity;
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    } */
    /* carregarMembroComunidadeAlianca(membroComunidadeAliancaId: number): void {
        this.membroComunidadeAliancaService.find(membroComunidadeAliancaId).then((response: any) => {
            this.membroComunidadeAlianca = response.entity;
            this.regionalSelecionadoId = this.membroComunidadeAlianca.regionalId;
            if(this.membroComunidadeAlianca.missaoOrigemId != null)
            {
                this.tipoOrigem = 'M';
            }
            else if(this.membroComunidadeAlianca.difusaoOrigemId != null)
            {
                this.tipoOrigem = 'D';
            }
            this.carregarOrigem();
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    } */
    /* carregarMembroComunidadeVida(membroComunidadeVidaId: number): void {
        this.membroComunidadeVidaService.find(membroComunidadeVidaId).then((response: any) => {
            this.membroComunidadeVida = response.entity;
            this.regionalSelecionadoId = this.membroComunidadeVida.regionalId;
            if(this.membroComunidadeVida.missaoOrigemId != null)
            {
                this.tipoOrigem = 'M';
            }
            else if(this.membroComunidadeVida.difusaoOrigemId != null)
            {
                this.tipoOrigem = 'D';
            }
            this.carregarOrigem();
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    } */
    PerfilEditComponent.prototype.carregarAptidao = function (aptidaoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.aptidaoService.find(aptidaoId)];
                    case 1:
                        response = _a.sent();
                        this.aptidao = response.entity;
                        this.encontrarEscolaridadesPorAptidaoId(this.aptidao.id);
                        $("select[name='linguas-select']").val(this.aptidao.linguaIds).trigger('change');
                        $("select[name='habilidades-select']").val(this.aptidao.habilidadeIds).trigger('change');
                        return [3 /*break*/, 3];
                    case 2:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/, null];
                }
            });
        });
    };
    PerfilEditComponent.prototype.carregarPessoaNacionalidades = function (pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.pessoaNacionalidadeService.porPessoaId(pessoaId)];
                    case 1:
                        response = _a.sent();
                        this.pessoaNacionalidades = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        err_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/, null];
                }
            });
        });
    };
    PerfilEditComponent.prototype.carregarNacionalidades = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.nacionalidadeService.findAll()];
                    case 1:
                        response = _a.sent();
                        this.nacionalidades = response.entity;
                        this.nacionalidades = this.nacionalidades.sort(function (a, b) { return a.descricao.localeCompare(b.descricao, 'fi'); });
                        return [3 /*break*/, 3];
                    case 2:
                        err_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_5.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/, null];
                }
            });
        });
    };
    PerfilEditComponent.prototype.carregarVistos = function (pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.vistoService.porPessoaId(pessoaId)];
                    case 1:
                        response = _a.sent();
                        this.vistos = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        err_6 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_6.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/, null];
                }
            });
        });
    };
    PerfilEditComponent.prototype.encontrarDocumentosPorPessoaId = function (pessoaId) {
        var _this = this;
        this.documentoService.buscarPorPessoaId(pessoaId).then(function (response) {
            _this.documentos = response.entity;
        }).catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    PerfilEditComponent.prototype.encontrarEscolaridadesPorAptidaoId = function (aptidaoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.escolaridadeService.findByAptidaoId(aptidaoId)];
                    case 1:
                        response = _a.sent();
                        this.escolaridades = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        err_7 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_7.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/, null];
                }
            });
        });
    };
    PerfilEditComponent.prototype.encontrarTrabalhoPorPessoaId = function (pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.trabalhoService.findByPessoaId(pessoaId)];
                    case 1:
                        response = _a.sent();
                        this.trabalhos = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        err_8 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_8.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/, null];
                }
            });
        });
    };
    PerfilEditComponent.prototype.carregarTipoDocumento = function () {
        var _this = this;
        var buscaDocumentos = {
            grupoNome: 'TIPOS_DOCUMENTOS_PESSOA'
        };
        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaDocumentos).then(function (lista) {
            _this.tiposDocumento = lista.entity;
        }).catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    PerfilEditComponent.prototype.carregarEstadosCivil = function () {
        var _this = this;
        var buscaEstadosCivil = {
            grupoNome: 'ESTADO_CIVIL'
        };
        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaEstadosCivil).then(function (lista) {
            _this.estadosCivil = lista.entity;
        }).catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    PerfilEditComponent.prototype.carregarFormasVida = function () {
        var _this = this;
        var buscaFormaVida = {
            grupoNome: 'FORMA_VIDA'
        };
        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFormaVida).then(function (lista) {
            _this.formasVida = lista.entity;
        }).catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    PerfilEditComponent.prototype.carregarNiveisFormacao = function () {
        var _this = this;
        var busca = {
            grupoNome: 'NIVEL_FORMACAO'
        };
        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca).then(function (lista) {
            _this.niveisFormacao = lista.entity;
        }).catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    PerfilEditComponent.prototype.carregarEstadoVida = function () {
        var _this = this;
        var busca = {
            grupoNome: 'ESTADO_VIDA'
        };
        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca).then(function (lista) {
            _this.estadosVida = lista.entity;
        }).catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    PerfilEditComponent.prototype.carregarHabilidades = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var busca, response, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        busca = {
                            grupoNome: 'HABILIDADE'
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca)];
                    case 2:
                        response = _a.sent();
                        this.habilidades = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        e_4 = _a.sent();
                        console.log(e_4);
                        return [3 /*break*/, 4];
                    case 4:
                        ;
                        return [2 /*return*/, null];
                }
            });
        });
    };
    PerfilEditComponent.prototype.carregarLinguas = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var busca, response, e_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        busca = {
                            grupoNome: 'LINGUA'
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca)];
                    case 2:
                        response = _a.sent();
                        this.linguas = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        e_5 = _a.sent();
                        console.log(e_5);
                        return [3 /*break*/, 4];
                    case 4:
                        ;
                        return [2 /*return*/, null];
                }
            });
        });
    };
    PerfilEditComponent.prototype.carregarNiveisEscolaridade = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var busca, response, e_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        busca = {
                            grupoNome: 'NIVEL_ESCOLARIDADE'
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca)];
                    case 2:
                        response = _a.sent();
                        this.niveisEscolaridade = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        e_6 = _a.sent();
                        console.log(e_6);
                        return [3 /*break*/, 4];
                    case 4:
                        ;
                        return [2 /*return*/, null];
                }
            });
        });
    };
    PerfilEditComponent.prototype.carregarNacionalidadeSituacao = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var busca, response, e_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        busca = {
                            grupoNome: 'NACIONALIDADE_SITUACAO'
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca)];
                    case 2:
                        response = _a.sent();
                        this.nacionalidadeSituacoes = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        e_7 = _a.sent();
                        console.log(e_7);
                        return [3 /*break*/, 4];
                    case 4:
                        ;
                        return [2 /*return*/, null];
                }
            });
        });
    };
    PerfilEditComponent.prototype.carregarTiposVisto = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var busca, response, e_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        busca = {
                            grupoNome: 'TIPOS_VISTO'
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.elementoGrupoDominioLocalService.buscarPorGrupoDominioNome(busca)];
                    case 2:
                        response = _a.sent();
                        this.tiposVisto = response.entity;
                        this.tiposVisto = this.tiposVisto.sort(function (a, b) { return a.nome.localeCompare(b.nome, 'fi'); });
                        return [3 /*break*/, 4];
                    case 3:
                        e_8 = _a.sent();
                        console.log(e_8);
                        return [3 /*break*/, 4];
                    case 4:
                        ;
                        return [2 /*return*/, null];
                }
            });
        });
    };
    Object.defineProperty(PerfilEditComponent.prototype, "valorTipoDocumento", {
        get: function () {
            var _this = this;
            var tipoSelecionado = this.tiposDocumento.filter(function (tipo) {
                return (tipo.id == _this.documento.elementoGrupoDominioTipoId);
            });
            return tipoSelecionado.length > 0 ? tipoSelecionado[0].valor : '';
        },
        enumerable: true,
        configurable: true
    });
    PerfilEditComponent.prototype.logOnConsole = function (dadosPessoaisForm) {
        console.log(dadosPessoaisForm);
    };
    PerfilEditComponent.prototype.activateTab = function (tab) {
        $('.nav-tabs a[href="#' + tab + '"]').removeClass('disabled');
        $('.nav-tabs a[href="#' + tab + '"]').tab('show');
    };
    PerfilEditComponent.prototype.activateTab2 = function (action) {
        if (action.localeCompare('previous') == 0) {
            if (this.activeTabNumber > 0) {
                this.activeTabNumber -= 1;
            }
        }
        else if (action.localeCompare('next') == 0) {
            if (this.activeTabNumber < this.tabNames.length - 1) {
                this.activeTabNumber += 1;
            }
        }
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').removeClass('disabled');
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').tab('show');
    };
    PerfilEditComponent.prototype.setarElementoSelecionado = function (valor) {
        this.pessoa.usuarioId = valor;
    };
    PerfilEditComponent.prototype.salvar = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, blogImage, err_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.pessoaService.update(pessoa.id, pessoa)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        if (!(this.pessoa.imagemId != null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getPessoaImagem(this.pessoa.imagemId)];
                    case 2:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.pessoa);
                        _a.label = 3;
                    case 3:
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 5];
                    case 4:
                        err_9 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_9.error.errors);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    PerfilEditComponent.prototype.salvarEndereco = function (endereco) {
        var _this = this;
        if (this.endereco.id == null) {
            this.enderecoService.create(endereco).then(function (response) {
                _this.endereco = response.entity;
                _this.pessoa.enderecoId = response.entity.id;
                _this.salvar(_this.pessoa);
            }).catch(function (err) {
                _this.swtAlert2Service.errorAlert(err.error.errors);
                _this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
        else {
            this.enderecoService.update(endereco.id, endereco).then(function (response) {
                _this.endereco = response.entity;
                _this.swtAlert2Service.successAlert(response.message);
            }).catch(function (err) {
                _this.swtAlert2Service.errorAlert(err.error.errors);
                _this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    };
    PerfilEditComponent.prototype.salvarFamiliaInfo = function (familiaInfo) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, err_10;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        familiaInfo.pessoaId = this.pessoaId;
                        if (!(familiaInfo.id == null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.familiaInfoService.create(familiaInfo)];
                    case 1:
                        response = _a.sent();
                        this.familiaInfo = response.entity;
                        this.swtAlert2Service.successAlert("Família salva com sucesso!");
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.familiaInfoService.update(familiaInfo.id, familiaInfo)];
                    case 3:
                        response = _a.sent();
                        this.familiaInfo = response.entity;
                        this.swtAlert2Service.successAlert("Família salva com sucesso!");
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_10 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_10.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    PerfilEditComponent.prototype.salvarImagem = function () {
        var _this = this;
        this.imagemService.salvarCropped({ 'imageData': this.croppedImage }).then(function (response) {
            _this.swtAlert2Service.successAlert(response.message);
            _this.pessoa.imagemId = response.entity.id;
            _this.salvar(_this.pessoa);
            $('#modal-pessoa-imagem').modal('hide');
        }).catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    PerfilEditComponent.prototype.salvarDocumento = function (documento) {
        var _this = this;
        documento.pessoaId = this.pessoa.id;
        this.documentoService.create(documento).then(function (response) {
            _this.documento = _this.documentoBuilder.getInstance();
            _this.pessoa.documentosId.push(response.entity.id);
            _this.encontrarDocumentosPorPessoaId(_this.pessoaId);
            _this.swtAlert2Service.successAlert(response.message);
        }).catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    PerfilEditComponent.prototype.salvarAptidao = function (aptidao) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, err_11;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.aptidao.pessoaId = this.pessoa.id;
                        this.aptidao.linguaIds = $("select[name='linguas-select']").val();
                        this.aptidao.habilidadeIds = $("select[name='habilidades-select']").val();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        if (!(this.aptidao.id == null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.aptidaoService.createAsync(aptidao)];
                    case 2:
                        response = _a.sent();
                        this.aptidao = response.entity;
                        this.pessoa.aptidaoId = this.aptidao.id;
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.aptidaoService.updateAsync(aptidao.id, aptidao)];
                    case 4:
                        response = _a.sent();
                        this.aptidao = response.entity;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_11 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_11.error.errors);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/, null];
                }
            });
        });
    };
    PerfilEditComponent.prototype.salvarEscolaridade = function (escolaridade) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_12;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(escolaridade.nivelEscolaridadeId != null)) return [3 /*break*/, 7];
                        if (!(this.aptidao.id == null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.salvarAptidao(this.aptidao)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.escolaridade.aptidaoId = this.aptidao.id;
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 5, , 6]);
                        return [4 /*yield*/, this.escolaridadeService.createAsync(escolaridade)];
                    case 4:
                        response = _a.sent();
                        this.escolaridade = response.entity;
                        this.aptidao.escolaridadeIds.push(this.escolaridade.id);
                        this.encontrarEscolaridadesPorAptidaoId(this.aptidao.id);
                        this.escolaridade = this.escolaridadeBuilder.getInstance();
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 6];
                    case 5:
                        err_12 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_12.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        this.swtAlert2Service.warningAlert("É preciso preencher o formulário antes de salvar sua escolaridade.");
                        _a.label = 8;
                    case 8: return [2 /*return*/, null];
                }
            });
        });
    };
    PerfilEditComponent.prototype.salvarTrabalho = function (trabalho) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_13;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.trabalho.pessoaId = this.pessoa.id;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.trabalhoService.createAsync(trabalho)];
                    case 2:
                        response = _a.sent();
                        this.pessoa.trabalhoIds.push(response.entity.id);
                        this.encontrarTrabalhoPorPessoaId(this.pessoa.id);
                        this.trabalho = this.trabalhoBuilder.getInstance();
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 4];
                    case 3:
                        err_13 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_13.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, null];
                }
            });
        });
    };
    PerfilEditComponent.prototype.salvarPessoaNacionalidade = function (pessoaNacionalidade) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_14;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(pessoaNacionalidade.nacionalidadeId != null && pessoaNacionalidade.status != null)) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        pessoaNacionalidade.pessoaId = this.pessoaId;
                        return [4 /*yield*/, this.pessoaNacionalidadeService.create(pessoaNacionalidade)];
                    case 2:
                        response = _a.sent();
                        this.pessoaNacionalidade = response.entity;
                        this.carregarPessoaNacionalidades(this.pessoaId);
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 4];
                    case 3:
                        err_14 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_14.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        this.swtAlert2Service.warningAlert("Preencha todos os campos do formulário antes de salvar a nacionalidade!");
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    PerfilEditComponent.prototype.salvarVisto = function (visto) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_15;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(visto.paisId != null && visto.dataValidade != null)) return [3 /*break*/, 5];
                        if (!(visto.tipoVistoId == null && visto.tipoVistoNaoListado == null)) return [3 /*break*/, 1];
                        this.swtAlert2Service.warningAlert("Selecione o tipo de visto ou informe o tipo de visto no campo de visto não listado.");
                        return [3 /*break*/, 4];
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        visto.pessoaId = this.pessoaId;
                        return [4 /*yield*/, this.vistoService.create(visto)];
                    case 2:
                        response = _a.sent();
                        this.visto = this.vistoBuilder.getInstance();
                        this.carregarVistos(this.pessoaId);
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 4];
                    case 3:
                        err_15 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_15.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        this.swtAlert2Service.warningAlert("Preencha todos os campos do formulário antes de salvar o visto!");
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    PerfilEditComponent.prototype.adicionarTelefone = function () {
        if (this.intlTellInputInstance.getNumber().localeCompare("") != 0) {
            this.pessoa.telefones.push(this.intlTellInputInstance.getNumber());
            this.salvar(this.pessoa);
            $("#pessoa-telefone").val("");
        }
    };
    PerfilEditComponent.prototype.removerTelefone = function (tel) {
        if (confirm('Deseja deletar este telefone?')) {
            var index = this.pessoa.telefones.indexOf(tel);
            if (index > -1) {
                this.pessoa.telefones.splice(index, 1);
                this.salvar(this.pessoa);
            }
        }
    };
    PerfilEditComponent.prototype.removerDocumento = function (id) {
        var _this = this;
        if (confirm('Deseja deletar este documento?')) {
            this.documentoService.delete(id).then(function (response) {
                _this.swtAlert2Service.successAlert(response.message);
                _this.encontrarDocumentosPorPessoaId(_this.pessoaId);
            }).catch(function (err) {
                _this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    };
    PerfilEditComponent.prototype.removerEscolaridade = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_16;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!confirm('Deseja deletar esta escolaridade?')) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.escolaridadeService.deleteAsync(id)];
                    case 2:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        this.carregarAptidao(this.pessoa.aptidaoId);
                        return [3 /*break*/, 4];
                    case 3:
                        err_16 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_16.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, null];
                }
            });
        });
    };
    PerfilEditComponent.prototype.removerTrabalho = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_17;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!confirm('Deseja deletar este trabalho?')) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.trabalhoService.deleteAsync(id)];
                    case 2:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        this.carregarPessoa();
                        this.encontrarTrabalhoPorPessoaId(this.pessoa.id);
                        return [3 /*break*/, 4];
                    case 3:
                        err_17 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_17.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, null];
                }
            });
        });
    };
    PerfilEditComponent.prototype.removerPessoaNacionalidade = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_18;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!confirm('Deseja deletar esta nacionalidade?')) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.pessoaNacionalidadeService.delete(id)];
                    case 2:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        this.carregarPessoaNacionalidades(this.pessoaId);
                        return [3 /*break*/, 4];
                    case 3:
                        err_18 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_18.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, null];
                }
            });
        });
    };
    PerfilEditComponent.prototype.removerVisto = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_19;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!confirm('Deseja deletar este visto?')) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.vistoService.delete(id)];
                    case 2:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        this.carregarVistos(this.pessoaId);
                        return [3 /*break*/, 4];
                    case 3:
                        err_19 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_19.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, null];
                }
            });
        });
    };
    PerfilEditComponent.prototype.fileChangeEvent = function (event) {
        this.imageChangedEvent = event;
        console.log(event);
    };
    PerfilEditComponent.prototype.imageCropped = function (event) {
        this.croppedImage = event.base64;
    };
    PerfilEditComponent.prototype.imageLoaded = function () {
        console.log('Imagem carregada');
    };
    PerfilEditComponent.prototype.cropperReady = function () {
        console.log('Imagem cortada');
    };
    PerfilEditComponent.prototype.loadImageFailed = function () {
        console.log('Carregamento da imagem falhou!');
    };
    PerfilEditComponent.prototype.setarFormaVidaSelecionada = function () {
        for (var i = 0; i < this.formasVida.length; i++) {
            if (this.formasVida[i].id == this.pessoa.formaVidaId) {
                this.formaVidaSelecionada = this.formasVida[i];
            }
        }
    };
    PerfilEditComponent.prototype.setarNivelEscolaridadeSelecionado = function () {
        for (var i = 0; i < this.niveisEscolaridade.length; i++) {
            if (this.niveisEscolaridade[i].id == this.escolaridade.nivelEscolaridadeId) {
                this.nivelEscolaridadeSelecionado = this.niveisEscolaridade[i];
            }
        }
    };
    PerfilEditComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_20;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_20 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_20.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PerfilEditComponent.prototype.textoStatus = function (pessoaNacionalidade) {
        var returnText = '';
        $('select[name=pessoa_nac_status]').find('option').each(function () {
            if ($(this).val() == pessoaNacionalidade.status) {
                returnText = $(this).text();
            }
        });
        return returnText;
    };
    PerfilEditComponent.prototype.textoNacionalidade = function (pessoaNacionalidade) {
        var returnText = '';
        $('select[name=pessoa_nac]').find('option').each(function () {
            if ($(this).val() == pessoaNacionalidade.nacionalidadeId) {
                returnText = $(this).text();
            }
        });
        return returnText;
    };
    return PerfilEditComponent;
}());
export { PerfilEditComponent };
