import * as tslib_1 from "tslib";
import { GenericService } from "src/app/core/services/generic.service";
import { environment } from "src/environments/environment";
var RespostaQuestionarioService = /** @class */ (function (_super) {
    tslib_1.__extends(RespostaQuestionarioService, _super);
    function RespostaQuestionarioService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType("respostaquestionario");
        return _this;
    }
    RespostaQuestionarioService.prototype.porPessoaId = function (id) {
        return this.http
            .get("" + environment.moduloFormacao.buscarRespostaQuestionario + id, { headers: this.getHeaders() })
            .toPromise();
    };
    RespostaQuestionarioService.prototype.cadastrar = function (request) {
        return this.http
            .post(environment.moduloFormacao.cadastrarRespostaQuestionario, JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    RespostaQuestionarioService.prototype.atualizar = function (id, request) {
        return this.http
            .put(environment.moduloFormacao.urlModuloFormacao + "questionarioRespostaFormando/" + id, JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    return RespostaQuestionarioService;
}(GenericService));
export { RespostaQuestionarioService };
