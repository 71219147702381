import * as tslib_1 from "tslib";
import { Cidade } from '../model/cidade.model';
import { AbstractBuilder } from './abstract.builder';
var CidadeBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(CidadeBuilder, _super);
    function CidadeBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CidadeBuilder.prototype.reset = function () {
        this.entity = new Cidade(null, null, null, null, null, null);
    };
    return CidadeBuilder;
}(AbstractBuilder));
export { CidadeBuilder };
