import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
var ImagemService = /** @class */ (function (_super) {
    tslib_1.__extends(ImagemService, _super);
    function ImagemService(http, router, sanitizer) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.sanitizer = sanitizer;
        _this.setEntityType('imagem');
        return _this;
    }
    ImagemService.prototype.salvarCropped = function (imageData) {
        return this.http.post(environment.configServidor.wopApiUrl + 'imagem' + '/cropped/salvar', JSON.stringify(imageData), { 'headers': this.getHeaders() }).toPromise();
    };
    ImagemService.prototype.getPessoaPhoto = function (id) {
        var options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + (this.token ? this.token.access_token : '')
            }),
            // Ignore this part or  if you want full response you have 
            // to explicitly give as 'boby'as http client by default give res.json()
            observe: 'response',
            // have to explicitly give as 'blob' or 'json'
            responseType: 'blob'
        };
        return this.http.get(environment.imagem.urlBaseImagemRemote + id, options).toPromise();
    };
    ImagemService.prototype.createImage = function (image, pessoa) {
        var _this = this;
        if (image && image.size > 0) {
            var reader_1 = new FileReader();
            reader_1.addEventListener("load", function () {
                var urlStringImage = reader_1.result;
                pessoa.thumbnail = _this.sanitizer.bypassSecurityTrustUrl(urlStringImage.replace("text/xml", "image/png"));
            }, false);
            reader_1.readAsDataURL(image);
        }
    };
    return ImagemService;
}(GenericService));
export { ImagemService };
