import * as tslib_1 from "tslib";
import { AbstractBuilder } from './abstract.builder';
import { Aptidao } from '../model/aptidao.model';
var AptidaoBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(AptidaoBuilder, _super);
    function AptidaoBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AptidaoBuilder.prototype.reset = function () {
        this.entity = new Aptidao(null, null, null, null, null);
    };
    return AptidaoBuilder;
}(AbstractBuilder));
export { AptidaoBuilder };
