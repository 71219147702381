import * as tslib_1 from "tslib";
import { Nacionalidade } from '../model/nacionalidade.model';
import { AbstractBuilder } from './abstract.builder';
var NacionalidadeBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(NacionalidadeBuilder, _super);
    function NacionalidadeBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NacionalidadeBuilder.prototype.reset = function () {
        this.entity = new Nacionalidade(null, null, null, null);
    };
    return NacionalidadeBuilder;
}(AbstractBuilder));
export { NacionalidadeBuilder };
