import * as tslib_1 from "tslib";
import { Pessoa } from '../model/pessoa.model';
import { AbstractBuilder } from './abstract.builder';
import { VinculoDifusaoBuilder } from './vinculodifusao.builder';
import { VinculoMissaoBuilder } from './vinculomissao.builder';
var PessoaBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(PessoaBuilder, _super);
    function PessoaBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PessoaBuilder.prototype.reset = function () {
        this.entity = new Pessoa(null, '', '', null, null, null, null, null, null, null, null, null, new Array(), null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, new VinculoMissaoBuilder().getInstance(), new VinculoDifusaoBuilder().getInstance(), false);
    };
    return PessoaBuilder;
}(AbstractBuilder));
export { PessoaBuilder };
