import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
import { environment } from 'src/environments/environment';
var CelulaService = /** @class */ (function (_super) {
    tslib_1.__extends(CelulaService, _super);
    function CelulaService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType('r/celula');
        return _this;
    }
    CelulaService.prototype.buscarCelulas = function (busca) {
        //return this.http.post<any>(this.apiURL+'/buscar', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
        return this.http.post(environment.moduloFormacao.buscarCelula, JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise();
    };
    CelulaService.prototype.getAllNotInAcessoUsuarioEstrutura = function (id) {
        return this.http.get(this.apiURL + "/getallnotinacessousuarioestrutura/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    CelulaService.prototype.getAllFromAcessoUsuarioEstrutura = function (id) {
        return this.http.get(this.apiURL + "/getallfromacessousuarioestrutura/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    CelulaService.prototype.criarLinkCadastroCelula = function (request) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.post(environment.moduloFormacao.urlModuloFormacao + "celula/criarlinkcadastro", JSON.stringify(request), { 'headers': this.getHeaders() }).toPromise()];
            });
        });
    };
    CelulaService.prototype.obterLinkCadastroCelula = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.moduloFormacao.urlModuloFormacao + "celula/obterlinkcadastro/" + id, { 'headers': this.getHeaders() }).toPromise()];
            });
        });
    };
    CelulaService.prototype.unirCelulas = function (request) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.post(this.apiURL + '/unircelulas', JSON.stringify(request), { 'headers': this.getHeaders() }).toPromise()];
            });
        });
    };
    /**
     * Formadores Comunitários
     */
    CelulaService.prototype.cadastrarFormadorComunitario = function (data) {
        return this.http.post(this.apiURL + "/cadastrar/formadorcomunitario", JSON.stringify(data), { 'headers': this.getHeaders() }).toPromise();
    };
    CelulaService.prototype.listarFormadorComunitarioPorCelulaId = function (id) {
        return this.http.get(this.apiURL + "/" + id + "/formadorescomunitarios", { 'headers': this.getHeaders() }).toPromise();
    };
    CelulaService.prototype.deletarFormadorComunitario = function (id) {
        return this.http.delete(this.apiURL + "/deletar/formadorcomunitario/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    CelulaService.prototype.atualizarFormadorComunitario = function (data) {
        return this.http.put(this.apiURL + "/atualizar/formadorcomunitario", JSON.stringify(data), { 'headers': this.getHeaders() }).toPromise();
    };
    /**
    * Formadores Assistentes
    */
    CelulaService.prototype.cadastrarFormadorAssistente = function (data) {
        return this.http.post(this.apiURL + "/cadastrar/formadorassistente", JSON.stringify(data), { 'headers': this.getHeaders() }).toPromise();
    };
    CelulaService.prototype.listarFormadorAssistentePorCelulaId = function (id) {
        return this.http.get(this.apiURL + "/" + id + "/formadoresassistentes", { 'headers': this.getHeaders() }).toPromise();
    };
    CelulaService.prototype.deletarFormadorAssistente = function (id) {
        return this.http.delete(this.apiURL + "/deletar/formadorassistente/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    CelulaService.prototype.atualizarFormadorAssistente = function (data) {
        return this.http.put(this.apiURL + "/atualizar/formadorassistente", JSON.stringify(data), { 'headers': this.getHeaders() }).toPromise();
    };
    return CelulaService;
}(GenericService));
export { CelulaService };
