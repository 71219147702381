import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
var ElementoGrupoDominioService = /** @class */ (function (_super) {
    tslib_1.__extends(ElementoGrupoDominioService, _super);
    function ElementoGrupoDominioService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType('r/elementogrupodominio');
        return _this;
    }
    ElementoGrupoDominioService.prototype.buscarElementosGrupoDominio = function (busca) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.post(this.apiURL + '/buscar', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise()];
            });
        });
    };
    ElementoGrupoDominioService.prototype.buscarPorGrupoDominioNome = function (busca) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.post(this.apiURL + '/pornomegrupo', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise()];
            });
        });
    };
    ElementoGrupoDominioService.prototype.getAllNotInAcessoUsuarioEstrutura = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(this.apiURL + "/getallnotinacessousuarioestrutura/" + id, { 'headers': this.getHeaders() }).toPromise()];
            });
        });
    };
    ElementoGrupoDominioService.prototype.getAllFromAcessoUsuarioEstrutura = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(this.apiURL + "/getallfromacessousuarioestrutura/" + id, { 'headers': this.getHeaders() }).toPromise()];
            });
        });
    };
    return ElementoGrupoDominioService;
}(GenericService));
export { ElementoGrupoDominioService };
