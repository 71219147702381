/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../layout/secured.component.ngfactory";
import * as i2 from "../../../layout/secured.component";
import * as i3 from "../../../../login/service/login.service";
import * as i4 from "../../usuario/service/usuario.service";
import * as i5 from "../../../layout/routermanager.service";
import * as i6 from "../../../../core/services/app-route-change.service";
import * as i7 from "../../aceitetermouso/service/aceitetermouso.service";
import * as i8 from "../../aceitetermotratamentodados/service/aceitetermotratamentodados.service";
import * as i9 from "../../cadastropublico/service/cadastro.publico.local.service";
import * as i10 from "../../../../core/swtalert2/swtalert2.service";
import * as i11 from "@angular/router";
import * as i12 from "./parecer-agradecimento.component";
import * as i13 from "../../pessoa/service/pessoa.service";
import * as i14 from "../../../../builder/pessoa.builder";
var styles_ParecerAgradecimentoComponent = [];
var RenderType_ParecerAgradecimentoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ParecerAgradecimentoComponent, data: {} });
export { RenderType_ParecerAgradecimentoComponent as RenderType_ParecerAgradecimentoComponent };
export function View_ParecerAgradecimentoComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "secured", [], null, null, null, i1.View_SecuredComponent_0, i1.RenderType_SecuredComponent)), i0.ɵdid(1, 4308992, null, 0, i2.SecuredComponent, [i3.LoginService, i4.UsuarioService, i5.RouterManagerService, i6.AppRouteChangeService, i7.AceiteTermoService, i8.AceiteTermoTratamentoDadosService, i9.CadastroPublicoLocalService, i10.SwtAlert2Service, i11.Router, i0.Renderer2], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 12, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 6, "section", [["class", "content-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 5, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "row mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Obrigada pelo seu Parecer!"])), (_l()(), i0.ɵeld(10, 0, null, null, 4, "section", [["class", "content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 3, "div", [["class", "card card-primary card-outline card-outline-tabs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "div", [["class", "card-header p-0 border-bottom-0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u201CQue o Senhor te aben\u00E7oe no cumprimento desta sua preciosa miss\u00E3o para a Comunidade!"]))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ParecerAgradecimentoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-parecer-agradecimento", [], null, null, null, View_ParecerAgradecimentoComponent_0, RenderType_ParecerAgradecimentoComponent)), i0.ɵdid(1, 114688, null, 0, i12.ParecerAgradecimentoComponent, [i13.PessoaService, i10.SwtAlert2Service, i4.UsuarioService, i14.PessoaBuilder, i11.ActivatedRoute, i11.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ParecerAgradecimentoComponentNgFactory = i0.ɵccf("app-parecer-agradecimento", i12.ParecerAgradecimentoComponent, View_ParecerAgradecimentoComponent_Host_0, {}, {}, []);
export { ParecerAgradecimentoComponentNgFactory as ParecerAgradecimentoComponentNgFactory };
