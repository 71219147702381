import * as tslib_1 from "tslib";
import { Saude } from '../model/saude.model';
import { AbstractBuilder } from './abstract.builder';
var SaudeBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(SaudeBuilder, _super);
    function SaudeBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SaudeBuilder.prototype.reset = function () {
        this.entity = new Saude(null, null, null, null, null, null, null, null, 0, 0);
    };
    return SaudeBuilder;
}(AbstractBuilder));
export { SaudeBuilder };
