import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { SecuredModule } from "../../layout/secured.module";
import { IgxTabsModule } from "igniteui-angular";
import { ImageCropperModule } from "ngx-image-cropper";
import { QuestionarioRoutingModule } from "./questionario-routing.module";
import { QuestionariosComponent } from "./questionarios/questionarios.component";
import { DadoFormandoCVService } from "./service/dadoformandocv.service";
import { CaminhoFormativoService } from "./service/caminhoformativo.service";
import { DadoFormandoCVBuilder } from "src/app/builder/dadoformandocv.builder";
import { CaminhoFormativoBuilder } from "src/app/builder/caminhoformativo.builder";
import { MensagemService } from "./service/mensagem.service";
import { RespostaQuestionarioService } from "./service/respostaquestionario.service";
import { QuestionarioFormandoService } from "./service/questionarioformando.service";
import { MensagemBuilder } from "src/app/builder/mensagem.builder";
import { RespostaQuestionarioBuilder } from "src/app/builder/respostaquestionario.builder";
import { QuestionarioFormandoBuilder } from "src/app/builder/questionarioformando.builder";
import { QuestionarioAgradecimentoComponent } from "./missionario/questionario-agradecimento.component";
import { QuestionarioD2AnswerComponent } from "./missionario/questionariod2-answer.component";
import { QuestionarioD2ViewAnswerComponent } from "./missionario/questionariod2-view-answer.component";
import { ListagemPessoaInfoModalModule } from "../pessoa/modulo/listagem-pessoainfo-modal/listagempessoainfo.modal.module";
import { PessoaService } from "../pessoa/service/pessoa.service";
import { ImagemService } from "../../service/imagem.service";
import { QuestionarioCALViewAnswerComponent } from "./missionario/questionario-cal-view-answer.component";
import { QuestionarioCALAnswerComponent } from "./missionario/questionario-cal-answer.component";
import { IsNaNPipe } from "../../pipe/isnan.pipe";
import { MissaoConfiguracaoService } from "./service/configuracao-questionario.service";

@NgModule({
  declarations: [
    QuestionarioD2AnswerComponent,
    QuestionariosComponent,
    QuestionarioAgradecimentoComponent,
    QuestionarioD2ViewAnswerComponent,
    QuestionarioCALViewAnswerComponent,
    QuestionarioCALAnswerComponent,
    IsNaNPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    QuestionarioRoutingModule,
    FormsModule,
    SecuredModule,
    IgxTabsModule,
    ImageCropperModule,
    ListagemPessoaInfoModalModule,
  ],
  providers: [
    PessoaService,
    ImagemService,
    DadoFormandoCVService,
    CaminhoFormativoService,
    MensagemService,
    RespostaQuestionarioService,
    QuestionarioFormandoService,
    DadoFormandoCVBuilder,
    CaminhoFormativoBuilder,
    MensagemBuilder,
    RespostaQuestionarioBuilder,
    QuestionarioFormandoBuilder,
    MissaoConfiguracaoService,
  ],
})
export class QuestionarioModule {}
