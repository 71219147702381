import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
var UsuarioService = /** @class */ (function (_super) {
    tslib_1.__extends(UsuarioService, _super);
    function UsuarioService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType('r/usuario');
        return _this;
    }
    UsuarioService.prototype.getCurrentUser = function () {
        console.log("Chamou getCurrenteUser service");
        return this.http.get(this.apiURL + '/currentuser', { 'headers': this.getHeaders() }).toPromise();
    };
    return UsuarioService;
}(GenericService));
export { UsuarioService };
