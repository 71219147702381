import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { UsuarioBuilder } from 'src/app/builder/usuario.builder';
var LeftSidebarMissionarioComponent = /** @class */ (function () {
    function LeftSidebarMissionarioComponent(usuarioService, pessoaService, imagemService, swtAlert2Service) {
        this.usuarioService = usuarioService;
        this.pessoaService = pessoaService;
        this.imagemService = imagemService;
        this.swtAlert2Service = swtAlert2Service;
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.usuarioBuilder = new UsuarioBuilder();
        this.usuarioAtual = this.usuarioBuilder.getInstance();
        this.pessoaBuilder = new PessoaBuilder();
        this.pessoaAtual = this.pessoaBuilder.getInstance();
    }
    LeftSidebarMissionarioComponent.prototype.getUsuarioAtual = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.usuarioAtual = JSON.parse(sessionStorage.getItem("usuarioAtual"));
                        if (!(this.usuarioAtual == undefined || this.usuarioAtual == null)) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 2:
                        response = _a.sent();
                        this.usuarioAtual = response.entity;
                        sessionStorage.setItem("usuarioAtual", JSON.stringify(this.usuarioAtual));
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 4];
                    case 4: return [4 /*yield*/, this.getPessoaAtual()];
                    case 5:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LeftSidebarMissionarioComponent.prototype.getPessoaAtual = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, blogImage, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.pessoaAtual = JSON.parse(sessionStorage.getItem("pessoaAtual"));
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        if (!(this.pessoaAtual == undefined || this.pessoaAtual == null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.pessoaService.buscaPorUsuarioId(this.usuarioAtual.id)];
                    case 2:
                        response = _a.sent();
                        this.pessoaAtual = response.entity;
                        sessionStorage.setItem("pessoaAtual", JSON.stringify(this.pessoaAtual));
                        _a.label = 3;
                    case 3:
                        if (!(this.pessoaAtual.imagemId != null)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.getPessoaImagem(this.pessoaAtual.imagemId)];
                    case 4:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.pessoaAtual);
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_2 = _a.sent();
                        console.log(e_2);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    LeftSidebarMissionarioComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LeftSidebarMissionarioComponent.prototype.exibirParaRole = function (regras) {
        var retorno = false;
        if (this.usuarioAtual && regras.includes(this.usuarioAtual.autorizacoesNome[0])) {
            retorno = true;
        }
        return retorno;
    };
    LeftSidebarMissionarioComponent.prototype.initialize = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUsuarioAtual()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LeftSidebarMissionarioComponent.prototype.ngOnInit = function () {
        this.initialize();
    };
    return LeftSidebarMissionarioComponent;
}());
export { LeftSidebarMissionarioComponent };
