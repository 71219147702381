import * as tslib_1 from "tslib";
import { Celibatario } from '../model/celibatario.model';
import { AbstractBuilder } from './abstract.builder';
var CelibatarioBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(CelibatarioBuilder, _super);
    function CelibatarioBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CelibatarioBuilder.prototype.reset = function () {
        this.entity = new Celibatario(null, null, null, null);
    };
    return CelibatarioBuilder;
}(AbstractBuilder));
export { CelibatarioBuilder };
