import * as tslib_1 from "tslib";
import { AceiteTermoTratamentoDados } from '../model/aceitetermotratamentodados.model';
import { AbstractBuilder } from './abstract.builder';
var AceiteTermoTratamentoDadosBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(AceiteTermoTratamentoDadosBuilder, _super);
    function AceiteTermoTratamentoDadosBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AceiteTermoTratamentoDadosBuilder.prototype.reset = function () {
        this.entity = new AceiteTermoTratamentoDados(null, null, null, null, null);
    };
    return AceiteTermoTratamentoDadosBuilder;
}(AbstractBuilder));
export { AceiteTermoTratamentoDadosBuilder };
