import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
var QuestionarioCALAnswerComponent = /** @class */ (function () {
    function QuestionarioCALAnswerComponent(wopGetAllPublic, pessoaService, imagemService, paisService, estadoService, cidadeService, elementoGrupoDominioService, elementoGrupoDominioLocalService, missaoService, swtAlert2Service, usuarioService, pessoaBuilder, pessoaInfoBuilder, dadoVocacionalService, dadoFomandoCAService, caminhoFormativoService, mensagemService, respostaQuestionarioService, questionarioFormandoService, dadoFomandoCABuilder, caminhoFormativoBuilder, mensagemBuilder, respostaQuestionarioBuilder, questionarioFormandoBuilder, route, router) {
        this.wopGetAllPublic = wopGetAllPublic;
        this.pessoaService = pessoaService;
        this.imagemService = imagemService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.missaoService = missaoService;
        this.swtAlert2Service = swtAlert2Service;
        this.usuarioService = usuarioService;
        this.pessoaBuilder = pessoaBuilder;
        this.pessoaInfoBuilder = pessoaInfoBuilder;
        this.dadoVocacionalService = dadoVocacionalService;
        this.dadoFomandoCAService = dadoFomandoCAService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.mensagemService = mensagemService;
        this.respostaQuestionarioService = respostaQuestionarioService;
        this.questionarioFormandoService = questionarioFormandoService;
        this.dadoFomandoCABuilder = dadoFomandoCABuilder;
        this.caminhoFormativoBuilder = caminhoFormativoBuilder;
        this.mensagemBuilder = mensagemBuilder;
        this.respostaQuestionarioBuilder = respostaQuestionarioBuilder;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.route = route;
        this.router = router;
        this.isNaN = Number.isNaN;
        this.intlTelInputCalled = false;
        this.missoesVinculo = new Array();
        this.missoes = new Array();
        this.formasVida = new Array();
        this.niveisFormacao = new Array();
        this.estadosVida = new Array();
        this.pessoaFormadorPessoalSelecionado =
            this.pessoaInfoBuilder.getInstance();
        this.pessoaFormadorComunitarioSelecionado =
            this.pessoaInfoBuilder.getInstance();
        this.dadoFormandoCA = this.dadoFomandoCABuilder.getInstance();
        this.caminhoFormativo = this.caminhoFormativoBuilder.getInstance();
        this.mensagemRL = this.mensagemBuilder.getInstance();
        this.imageChangedEvent = "";
        this.croppedImage = "";
        this.nextClicked = false;
        this.pessoa = this.pessoaBuilder.getInstance();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.url = environment.usuario.selectPorUsername;
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.tabNames = ["dados-pessoais-tab-content", "questionario-tab-content"];
        this.activeTabNumber = 0;
        this.resposta39 = respostaQuestionarioBuilder.getInstance();
        this.resposta40 = respostaQuestionarioBuilder.getInstance();
        this.resposta41 = respostaQuestionarioBuilder.getInstance();
        this.resposta42 = respostaQuestionarioBuilder.getInstance();
        this.resposta43 = respostaQuestionarioBuilder.getInstance();
        this.resposta44 = respostaQuestionarioBuilder.getInstance();
        this.resposta45 = respostaQuestionarioBuilder.getInstance();
        this.resposta46 = respostaQuestionarioBuilder.getInstance();
        this.resposta47 = respostaQuestionarioBuilder.getInstance();
        this.resposta48 = respostaQuestionarioBuilder.getInstance();
        this.resposta49 = respostaQuestionarioBuilder.getInstance();
    }
    QuestionarioCALAnswerComponent.prototype.ngOnInit = function () {
        this.initialization();
    };
    QuestionarioCALAnswerComponent.prototype.initialization = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUsuarioAtual()];
                    case 1:
                        _a.sent();
                        this.carregarFormasVida();
                        this.carregarNiveisFormacao();
                        this.carregarEstadoVida();
                        return [4 /*yield*/, this.carregarMissoesVinculo()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.carregarPessoa()];
                    case 3:
                        _a.sent();
                        this.questionarioFormandoId = Number(this.route.snapshot.paramMap.get("id"));
                        return [4 /*yield*/, this.carregarDadoVocacional()];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, this.carregarDadoFormandoCA()];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, this.carregarCaminhoFormativo()];
                    case 6:
                        _a.sent();
                        return [4 /*yield*/, this.carregarQuestionarioFormando()];
                    case 7:
                        _a.sent();
                        return [4 /*yield*/, this.carregarRespostas()];
                    case 8:
                        _a.sent();
                        return [4 /*yield*/, this.carregarMensagemRLPorOrigem()];
                    case 9:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALAnswerComponent.prototype.getUsuarioAtual = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuarioAtual = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALAnswerComponent.prototype.carregarMissoesVinculo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.wopGetAllPublic.getAllMissao()];
                    case 1:
                        response = _a.sent();
                        this.missoesVinculo = response.entity;
                        this.missoesVinculo = this.missoesVinculo.sort(function (a, b) {
                            return a.nome.localeCompare(b.nome);
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALAnswerComponent.prototype.carregarPessoa = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, blogImage, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.pessoaService.buscaPorUsuarioId(this.usuarioAtual.id)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        this.pessoaId = this.pessoa.id;
                        if (!(this.pessoa.imagemId != null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getPessoaImagem(this.pessoa.imagemId)];
                    case 2:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.pessoa);
                        _a.label = 3;
                    case 3:
                        if (this.pessoa.formaVidaId != null) {
                            this.setarFormaVidaSelecionada();
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALAnswerComponent.prototype.carregarFormasVida = function () {
        var _this = this;
        var buscaFormaVida = {
            grupoNome: "FORMA_VIDA",
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(buscaFormaVida)
            .then(function (lista) {
            _this.formasVida = lista.entity;
        })
            .catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    QuestionarioCALAnswerComponent.prototype.carregarNiveisFormacao = function () {
        var _this = this;
        var busca = {
            grupoNome: "NIVEL_FORMACAO",
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(busca)
            .then(function (lista) {
            _this.niveisFormacao = lista.entity;
        })
            .catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    QuestionarioCALAnswerComponent.prototype.carregarEstadoVida = function () {
        var _this = this;
        var busca = {
            grupoNome: "ESTADO_VIDA",
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(busca)
            .then(function (lista) {
            _this.estadosVida = lista.entity;
        })
            .catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    QuestionarioCALAnswerComponent.prototype.logOnConsole = function (dadosPessoaisForm) {
        console.log(dadosPessoaisForm);
    };
    QuestionarioCALAnswerComponent.prototype.activateTab = function (tab) {
        $('.nav-tabs a[href="#' + tab + '"]').removeClass("disabled");
        $('.nav-tabs a[href="#' + tab + '"]').tab("show");
    };
    QuestionarioCALAnswerComponent.prototype.activateTab2 = function (action) {
        if (action.localeCompare("previous") == 0) {
            if (this.activeTabNumber > 0) {
                this.activeTabNumber -= 1;
            }
        }
        else if (action.localeCompare("next") == 0) {
            if (this.activeTabNumber < this.tabNames.length - 1) {
                this.activeTabNumber += 1;
            }
        }
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').removeClass("disabled");
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').tab("show");
    };
    QuestionarioCALAnswerComponent.prototype.setarElementoSelecionado = function (valor) {
        this.pessoa.usuarioId = valor;
    };
    QuestionarioCALAnswerComponent.prototype.salvar = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, blogImage, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.pessoaService.update(pessoa.id, pessoa)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        if (!(this.pessoa.imagemId != null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getPessoaImagem(this.pessoa.imagemId)];
                    case 2:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.pessoa);
                        _a.label = 3;
                    case 3:
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 5];
                    case 4:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 5];
                    case 5: return [4 /*yield*/, this.atualizarDadoFormandoCA()];
                    case 6:
                        _a.sent();
                        return [4 /*yield*/, this.atualizarCaminhoFormativo()];
                    case 7:
                        _a.sent();
                        return [4 /*yield*/, this.atualizarQuestionarioFormando("INICIADO")];
                    case 8:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALAnswerComponent.prototype.salvarImagem = function () {
        var _this = this;
        this.imagemService
            .salvarCropped({ imageData: this.croppedImage })
            .then(function (response) {
            _this.swtAlert2Service.successAlert(response.message);
            _this.pessoa.imagemId = response.entity.id;
            _this.salvar(_this.pessoa);
            $("#modal-pessoa-imagem").modal("hide");
        })
            .catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    QuestionarioCALAnswerComponent.prototype.fileChangeEvent = function (event) {
        this.imageChangedEvent = event;
        console.log(event);
    };
    QuestionarioCALAnswerComponent.prototype.imageCropped = function (event) {
        this.croppedImage = event.base64;
    };
    QuestionarioCALAnswerComponent.prototype.imageLoaded = function () {
        console.log("Imagem carregada");
    };
    QuestionarioCALAnswerComponent.prototype.cropperReady = function () {
        console.log("Imagem cortada");
    };
    QuestionarioCALAnswerComponent.prototype.loadImageFailed = function () {
        console.log("Carregamento da imagem falhou!");
    };
    QuestionarioCALAnswerComponent.prototype.setarFormaVidaSelecionada = function () {
        for (var i = 0; i < this.formasVida.length; i++) {
            if (this.formasVida[i].id == this.pessoa.formaVidaId) {
                this.formaVidaSelecionada = this.formasVida[i];
            }
        }
    };
    QuestionarioCALAnswerComponent.prototype.carregarDadoVocacional = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.dadoVocacionalService.porPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response.entity != undefined && response.entity != null) {
                            this.dadoVocacional = response.entity;
                            this.pessoaFormadorPessoalSelecionado =
                                this.pessoaInfoBuilder.getInstance();
                            this.pessoaFormadorPessoalSelecionado.nome =
                                this.dadoVocacional.formadorPessoalPessoaNome;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALAnswerComponent.prototype.selecionarFormadorPessoal = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
                this.pessoaFormadorPessoalSelecionado = pessoa;
                $("#listagem-pessoa-formador-pessoal").modal("hide");
                return [2 /*return*/];
            });
        });
    };
    QuestionarioCALAnswerComponent.prototype.selecionarFormadorComunitario = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                //this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
                this.pessoaFormadorComunitarioSelecionado = pessoa;
                $("#listagem-pessoa-formador-comunitario").modal("hide");
                return [2 /*return*/];
            });
        });
    };
    QuestionarioCALAnswerComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALAnswerComponent.prototype.carregarDadoFormandoCA = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.dadoFomandoCAService.porPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.dadoFormandoCA = response;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_5.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALAnswerComponent.prototype.carregarCaminhoFormativo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.caminhoFormativoService.porPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.caminhoFormativo = response;
                            console.log("Caminho Formativo", this.caminhoFormativo);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_6 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_6.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALAnswerComponent.prototype.atualizarCaminhoFormativo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, err_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        this.caminhoFormativo.missao_atual_id =
                            this.pessoa.vinculoMissao.missaoAtualId;
                        this.caminhoFormativo.nome_formador_pessoal =
                            this.pessoaFormadorPessoalSelecionado.nome;
                        if (!(this.caminhoFormativo.id == null)) return [3 /*break*/, 2];
                        this.caminhoFormativo.pessoaId = this.pessoa.id;
                        return [4 /*yield*/, this.caminhoFormativoService.cadastrar(this.caminhoFormativo)];
                    case 1:
                        response = _a.sent();
                        this.caminhoFormativo = response;
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.caminhoFormativoService.atualizar(this.caminhoFormativo)];
                    case 3:
                        response = _a.sent();
                        this.caminhoFormativo = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_7 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_7.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALAnswerComponent.prototype.carregarRespostas = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_8;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.respostaQuestionarioService.porPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.respostas = response.content;
                            this.respostas.forEach(function (resposta) {
                                switch (resposta.questionario_pergunta_id) {
                                    case 39:
                                        _this.resposta39 = resposta;
                                        break;
                                    case 40:
                                        _this.resposta40 = resposta;
                                        break;
                                    case 41:
                                        _this.resposta41 = resposta;
                                        break;
                                    case 42:
                                        _this.resposta42 = resposta;
                                        break;
                                    case 43:
                                        _this.resposta43 = resposta;
                                        break;
                                    case 44:
                                        _this.resposta44 = resposta;
                                        break;
                                    case 45:
                                        _this.resposta45 = resposta;
                                        break;
                                    case 46:
                                        _this.resposta46 = resposta;
                                        break;
                                    case 47:
                                        _this.resposta47 = resposta;
                                        break;
                                    case 48:
                                        _this.resposta48 = resposta;
                                        break;
                                    case 49:
                                        _this.resposta49 = resposta;
                                        break;
                                }
                            });
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_8 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_8.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALAnswerComponent.prototype.carregarQuestionarioFormando = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.questionarioFormandoService.buscarPorId(this.questionarioFormandoId)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.questionarioFormando = response;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_9 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_9.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALAnswerComponent.prototype.carregarMensagemAF = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_10;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.dadoFomandoCAService.porPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.dadoFormandoCA = response;
                            /*this.carregarEstados(this.dadoFormandoCA.pais_origem_id);
                                    this.carregarCidades(this.dadoFormandoCA.estado_origem_id);*/
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_10 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_10.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALAnswerComponent.prototype.carregarMensagemRLPorOrigem = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_11;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.mensagemService.mensagemRLPorOrigemId(this.questionarioFormando.id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.mensagemRL = response;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_11 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_11.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALAnswerComponent.prototype.atualizarDadoFormandoCA = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, err_12;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!(this.dadoFormandoCA.id == null)) return [3 /*break*/, 2];
                        this.dadoFormandoCA.pessoaId = this.pessoa.id;
                        return [4 /*yield*/, this.dadoFomandoCAService.cadastrar(this.dadoFormandoCA)];
                    case 1:
                        response = _a.sent();
                        this.dadoFormandoCA = response;
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.dadoFomandoCAService.atualizar(this.dadoFormandoCA)];
                    case 3:
                        response = _a.sent();
                        this.dadoFormandoCA = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_12 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_12.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALAnswerComponent.prototype.atualizarResposta = function (resposta) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, err_13;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        resposta.pessoaId = this.pessoa.id;
                        if (!(resposta.id == null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.respostaQuestionarioService.cadastrar(resposta)];
                    case 1:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        return [2 /*return*/, response];
                    case 2: return [4 /*yield*/, this.respostaQuestionarioService.atualizar(resposta.id, resposta)];
                    case 3:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        return [2 /*return*/, response];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_13 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_13.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALAnswerComponent.prototype.atualizarMensagem = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, err_14;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!(this.mensagemRL.id == null)) return [3 /*break*/, 2];
                        this.mensagemRL.origem = 1; //1 - Questionario Formando
                        this.mensagemRL.origem_id = this.questionarioFormandoId;
                        this.mensagemRL.lida = 0;
                        return [4 /*yield*/, this.mensagemService.cadastrarMensagemRL(this.mensagemRL)];
                    case 1:
                        response = _a.sent();
                        this.mensagemRL = response;
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.mensagemService.atualizarMensagemRL(this.mensagemRL)];
                    case 3:
                        response = _a.sent();
                        this.mensagemRL = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_14 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_14.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALAnswerComponent.prototype.salvarQuestionario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
            return tslib_1.__generator(this, function (_m) {
                switch (_m.label) {
                    case 0:
                        if (!!this.respostaVazia(this.resposta39)) return [3 /*break*/, 2];
                        this.resposta39.questionario_pergunta_id = 39;
                        _a = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta39)];
                    case 1:
                        _a.resposta39 = _m.sent();
                        _m.label = 2;
                    case 2:
                        if (!!this.respostaVazia(this.resposta40)) return [3 /*break*/, 4];
                        this.resposta40.questionario_pergunta_id = 40;
                        _b = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta40)];
                    case 3:
                        _b.resposta40 = _m.sent();
                        _m.label = 4;
                    case 4:
                        if (!!this.respostaVazia(this.resposta41)) return [3 /*break*/, 6];
                        this.resposta41.questionario_pergunta_id = 41;
                        _c = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta41)];
                    case 5:
                        _c.resposta41 = _m.sent();
                        _m.label = 6;
                    case 6:
                        if (!!this.respostaVazia(this.resposta42)) return [3 /*break*/, 8];
                        this.resposta42.questionario_pergunta_id = 42;
                        _d = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta42)];
                    case 7:
                        _d.resposta42 = _m.sent();
                        _m.label = 8;
                    case 8:
                        if (!!this.respostaVazia(this.resposta43)) return [3 /*break*/, 10];
                        this.resposta43.questionario_pergunta_id = 43;
                        _e = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta43)];
                    case 9:
                        _e.resposta43 = _m.sent();
                        _m.label = 10;
                    case 10:
                        if (!!this.respostaVazia(this.resposta44)) return [3 /*break*/, 12];
                        this.resposta44.questionario_pergunta_id = 44;
                        _f = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta44)];
                    case 11:
                        _f.resposta44 = _m.sent();
                        _m.label = 12;
                    case 12:
                        if (!!this.respostaVazia(this.resposta45)) return [3 /*break*/, 14];
                        this.resposta45.questionario_pergunta_id = 45;
                        _g = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta45)];
                    case 13:
                        _g.resposta45 = _m.sent();
                        _m.label = 14;
                    case 14:
                        if (!!this.respostaVazia(this.resposta46)) return [3 /*break*/, 16];
                        this.resposta46.questionario_pergunta_id = 46;
                        _h = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta46)];
                    case 15:
                        _h.resposta46 = _m.sent();
                        _m.label = 16;
                    case 16:
                        if (!!this.respostaVazia(this.resposta47)) return [3 /*break*/, 18];
                        this.resposta47.questionario_pergunta_id = 47;
                        _j = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta47)];
                    case 17:
                        _j.resposta47 = _m.sent();
                        _m.label = 18;
                    case 18:
                        if (!!this.respostaVazia(this.resposta48)) return [3 /*break*/, 20];
                        this.resposta48.questionario_pergunta_id = 48;
                        _k = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta48)];
                    case 19:
                        _k.resposta48 = _m.sent();
                        _m.label = 20;
                    case 20:
                        if (!!this.respostaVazia(this.resposta49)) return [3 /*break*/, 22];
                        this.resposta49.questionario_pergunta_id = 49;
                        _l = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta49)];
                    case 21:
                        _l.resposta49 = _m.sent();
                        _m.label = 22;
                    case 22:
                        if (this.mensagemRL.mensagem != null)
                            this.atualizarMensagem();
                        // let status = this.definirStatusQuestionario();
                        return [4 /*yield*/, this.atualizarQuestionarioFormando("INICIADO")];
                    case 23:
                        // let status = this.definirStatusQuestionario();
                        _m.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALAnswerComponent.prototype.definirStatusQuestionario = function () {
        var status = "INICIADO";
        var todoRespondido = false;
        if (!this.respostaVazia(this.resposta39) &&
            !this.respostaVazia(this.resposta41) &&
            !this.respostaVazia(this.resposta40) &&
            !this.respostaVazia(this.resposta42) &&
            !this.respostaVazia(this.resposta43) &&
            !this.respostaVazia(this.resposta44) &&
            !this.respostaVazia(this.resposta45) &&
            !this.respostaVazia(this.resposta46) &&
            !this.respostaVazia(this.resposta47) &&
            !this.respostaVazia(this.resposta48) &&
            !this.respostaVazia(this.resposta49)) {
            todoRespondido = true;
        }
        if (todoRespondido) {
            status = "ENVIADO";
        }
        return status;
    };
    QuestionarioCALAnswerComponent.prototype.respostaVazia = function (resposta) {
        return (resposta.resposta == null ||
            resposta.resposta == undefined ||
            resposta.resposta == "");
    };
    QuestionarioCALAnswerComponent.prototype.enviarQuestionario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.salvarQuestionario()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.atualizarQuestionarioFormando("ENVIADO")];
                    case 2:
                        _a.sent();
                        this.router.navigate(["secured/questionario-agradecimento"]);
                        return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCALAnswerComponent.prototype.atualizarQuestionarioFormando = function (status) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_15;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!(this.questionarioFormando.id != null)) return [3 /*break*/, 2];
                        this.questionarioFormando.status = status;
                        if (status == "ENVIADO") {
                            this.questionarioFormando.data_envio = new Date();
                        }
                        return [4 /*yield*/, this.questionarioFormandoService.atualizar(this.questionarioFormando.id, this.questionarioFormando)];
                    case 1:
                        response = _a.sent();
                        this.questionarioFormando = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        err_15 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_15.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return QuestionarioCALAnswerComponent;
}());
export { QuestionarioCALAnswerComponent };
