import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
var RegionalService = /** @class */ (function (_super) {
    tslib_1.__extends(RegionalService, _super);
    function RegionalService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType('r/regional');
        return _this;
    }
    RegionalService.prototype.buscar = function (busca) {
        return this.http.post(this.apiURL + '/buscar', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise();
    };
    RegionalService.prototype.getAllNotInAcessoUsuarioEstrutura = function (id) {
        return this.http.get(this.apiURL + "/getallnotinacessousuarioestrutura/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    RegionalService.prototype.getAllFromAcessoUsuarioEstrutura = function (id) {
        return this.http.get(this.apiURL + "/getallfromacessousuarioestrutura/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    return RegionalService;
}(GenericService));
export { RegionalService };
