import * as tslib_1 from "tslib";
import { Filho } from '../model/filho.model';
import { AbstractBuilder } from './abstract.builder';
var FilhoBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(FilhoBuilder, _super);
    function FilhoBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FilhoBuilder.prototype.reset = function () {
        this.entity = new Filho(null, null, null, false, null, null, null, null, null, null, null);
    };
    return FilhoBuilder;
}(AbstractBuilder));
export { FilhoBuilder };
