import * as tslib_1 from "tslib";
import { SalvarMissionarioMissaoApostoladoAtual } from '../model/salvarmissionariomissaoapostoladoatual.model';
import { AbstractBuilder } from './abstract.builder';
var SalvarMissionarioMissaoApostoladoAtualBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(SalvarMissionarioMissaoApostoladoAtualBuilder, _super);
    function SalvarMissionarioMissaoApostoladoAtualBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SalvarMissionarioMissaoApostoladoAtualBuilder.prototype.reset = function () {
        this.entity = new SalvarMissionarioMissaoApostoladoAtual(null, null, null, null);
    };
    return SalvarMissionarioMissaoApostoladoAtualBuilder;
}(AbstractBuilder));
export { SalvarMissionarioMissaoApostoladoAtualBuilder };
