import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
var AutorizacaoService = /** @class */ (function (_super) {
    tslib_1.__extends(AutorizacaoService, _super);
    function AutorizacaoService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType('r/autorizacao');
        return _this;
    }
    AutorizacaoService.prototype.getCurrentUser = function () {
        return this.http.get(this.apiURL, { 'headers': this.getHeaders() }).toPromise();
    };
    AutorizacaoService.prototype.copiarPermissoes = function (request) {
        return this.http.post(this.apiURL + '/copiarpermissoes', JSON.stringify(request), { 'headers': this.getHeaders() }).toPromise();
    };
    AutorizacaoService.prototype.buscarAutorizacoes = function (busca) {
        return this.http.post(this.apiURL + '/buscar', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise();
    };
    AutorizacaoService.prototype.buscarAutorizacoesPorPermissaoId = function (busca) {
        return this.http.post(this.apiURL + '/porpermissao', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise();
    };
    AutorizacaoService.prototype.buscarAutorizacoesNaoVinculadasPorPermissaoId = function (id) {
        return this.http.get(this.apiURL + '/notvinculated/' + id, { 'headers': this.getHeaders() }).toPromise();
    };
    AutorizacaoService.prototype.vincular = function (obj) {
        return this.http.post(this.apiURL + '/vincular/', JSON.stringify(obj), { 'headers': this.getHeaders() }).toPromise();
    };
    AutorizacaoService.prototype.desvincular = function (obj) {
        return this.http.post(this.apiURL + '/desvincular/', JSON.stringify(obj), { 'headers': this.getHeaders() }).toPromise();
    };
    AutorizacaoService.prototype.autorizacoesPorUsuarioId = function (id) {
        return this.http.get(this.apiURL + '/porusuario/' + id, { 'headers': this.getHeaders() }).toPromise();
    };
    return AutorizacaoService;
}(GenericService));
export { AutorizacaoService };
