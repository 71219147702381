import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
var QuestionarioD2AnswerComponent = /** @class */ (function () {
    function QuestionarioD2AnswerComponent(wopGetAllPublic, pessoaService, imagemService, paisService, estadoService, cidadeService, elementoGrupoDominioService, elementoGrupoDominioLocalService, missaoService, swtAlert2Service, usuarioService, pessoaBuilder, pessoaInfoBuilder, dadoVocacionalService, dadoFormandoCVService, caminhoFormativoService, mensagemService, respostaQuestionarioService, questionarioFormandoService, dadoFormandoCVBuilder, caminhoFormativoBuilder, mensagemBuilder, respostaQuestionarioBuilder, questionarioFormandoBuilder, route, router) {
        this.wopGetAllPublic = wopGetAllPublic;
        this.pessoaService = pessoaService;
        this.imagemService = imagemService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.missaoService = missaoService;
        this.swtAlert2Service = swtAlert2Service;
        this.usuarioService = usuarioService;
        this.pessoaBuilder = pessoaBuilder;
        this.pessoaInfoBuilder = pessoaInfoBuilder;
        this.dadoVocacionalService = dadoVocacionalService;
        this.dadoFormandoCVService = dadoFormandoCVService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.mensagemService = mensagemService;
        this.respostaQuestionarioService = respostaQuestionarioService;
        this.questionarioFormandoService = questionarioFormandoService;
        this.dadoFormandoCVBuilder = dadoFormandoCVBuilder;
        this.caminhoFormativoBuilder = caminhoFormativoBuilder;
        this.mensagemBuilder = mensagemBuilder;
        this.respostaQuestionarioBuilder = respostaQuestionarioBuilder;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.route = route;
        this.router = router;
        this.intlTelInputCalled = false;
        this.missoesVinculo = new Array();
        this.missoes = new Array();
        this.formasVida = new Array();
        this.niveisFormacao = new Array();
        this.estadosVida = new Array();
        this.pessoaFormadorPessoalSelecionado =
            this.pessoaInfoBuilder.getInstance();
        this.pessoaFormadorComunitarioSelecionado =
            this.pessoaInfoBuilder.getInstance();
        this.dadoFormandoCV = this.dadoFormandoCVBuilder.getInstance();
        this.caminhoFormativo = this.caminhoFormativoBuilder.getInstance();
        this.mensagemAF = this.mensagemBuilder.getInstance();
        this.mensagemRL = this.mensagemBuilder.getInstance();
        this.imageChangedEvent = "";
        this.croppedImage = "";
        this.nextClicked = false;
        this.pessoa = this.pessoaBuilder.getInstance();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.url = environment.usuario.selectPorUsername;
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.tabNames = ["dados-pessoais-tab-content", "questionario-tab-content"];
        this.activeTabNumber = 0;
        this.resposta1 = respostaQuestionarioBuilder.getInstance();
        this.resposta2 = respostaQuestionarioBuilder.getInstance();
        this.resposta3 = respostaQuestionarioBuilder.getInstance();
        this.resposta4 = respostaQuestionarioBuilder.getInstance();
        this.resposta5 = respostaQuestionarioBuilder.getInstance();
        this.resposta6 = respostaQuestionarioBuilder.getInstance();
        this.resposta7 = respostaQuestionarioBuilder.getInstance();
        this.resposta8 = respostaQuestionarioBuilder.getInstance();
        this.resposta9 = respostaQuestionarioBuilder.getInstance();
        this.resposta10 = respostaQuestionarioBuilder.getInstance();
        this.resposta11 = respostaQuestionarioBuilder.getInstance();
        this.resposta12 = respostaQuestionarioBuilder.getInstance();
        this.resposta13 = respostaQuestionarioBuilder.getInstance();
        this.resposta14 = respostaQuestionarioBuilder.getInstance();
        this.resposta15 = respostaQuestionarioBuilder.getInstance();
        this.resposta16 = respostaQuestionarioBuilder.getInstance();
        this.resposta17 = respostaQuestionarioBuilder.getInstance();
        this.resposta18 = respostaQuestionarioBuilder.getInstance();
        this.resposta19 = respostaQuestionarioBuilder.getInstance();
        this.resposta20 = respostaQuestionarioBuilder.getInstance();
    }
    QuestionarioD2AnswerComponent.prototype.ngOnInit = function () {
        this.initialization();
    };
    QuestionarioD2AnswerComponent.prototype.initialization = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUsuarioAtual()];
                    case 1:
                        _a.sent();
                        this.carregarPaises();
                        this.carregarFormasVida();
                        this.carregarNiveisFormacao();
                        this.carregarEstadoVida();
                        return [4 /*yield*/, this.carregarMissoesVinculo()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.carregarPessoa()];
                    case 3:
                        _a.sent();
                        this.questionarioFormandoId = Number(this.route.snapshot.paramMap.get("id"));
                        return [4 /*yield*/, this.carregarDadoVocacional()];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, this.carregarDadoFormandoCV()];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, this.carregarCaminhoFormativo()];
                    case 6:
                        _a.sent();
                        return [4 /*yield*/, this.carregarQuestionarioFormando()];
                    case 7:
                        _a.sent();
                        return [4 /*yield*/, this.carregarRespostas()];
                    case 8:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioD2AnswerComponent.prototype.getUsuarioAtual = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuarioAtual = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioD2AnswerComponent.prototype.carregarMissoesVinculo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.wopGetAllPublic.getAllMissao()];
                    case 1:
                        response = _a.sent();
                        this.missoesVinculo = response.entity;
                        this.missoesVinculo = this.missoesVinculo.sort(function (a, b) {
                            return a.nome.localeCompare(b.nome);
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioD2AnswerComponent.prototype.carregarPessoa = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, blogImage, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.pessoaService.buscaPorUsuarioId(this.usuarioAtual.id)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        this.pessoaId = this.pessoa.id;
                        if (!(this.pessoa.imagemId != null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getPessoaImagem(this.pessoa.imagemId)];
                    case 2:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.pessoa);
                        _a.label = 3;
                    case 3:
                        if (this.pessoa.formaVidaId != null) {
                            this.setarFormaVidaSelecionada();
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioD2AnswerComponent.prototype.carregarFormasVida = function () {
        var _this = this;
        var buscaFormaVida = {
            grupoNome: "FORMA_VIDA",
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(buscaFormaVida)
            .then(function (lista) {
            _this.formasVida = lista.entity;
        })
            .catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    QuestionarioD2AnswerComponent.prototype.carregarNiveisFormacao = function () {
        var _this = this;
        var busca = {
            grupoNome: "NIVEL_FORMACAO",
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(busca)
            .then(function (lista) {
            _this.niveisFormacao = lista.entity;
        })
            .catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    QuestionarioD2AnswerComponent.prototype.carregarEstadoVida = function () {
        var _this = this;
        var busca = {
            grupoNome: "ESTADO_VIDA",
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(busca)
            .then(function (lista) {
            _this.estadosVida = lista.entity;
        })
            .catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    QuestionarioD2AnswerComponent.prototype.carregarPaises = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.paisService.findAll()];
                    case 1:
                        response = _a.sent();
                        this.paises = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioD2AnswerComponent.prototype.carregarEstados = function (paisId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        request = {
                            paisId: paisId,
                            nome: undefined,
                            pageNumber: 0,
                            quantityOfElements: 1000,
                        };
                        return [4 /*yield*/, this.estadoService.buscarEstados(request)];
                    case 1:
                        response = _a.sent();
                        this.estados = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioD2AnswerComponent.prototype.carregarCidades = function (estadoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, e_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        request = {
                            estadoId: estadoId,
                            nome: undefined,
                            pageNumber: 0,
                            quantityOfElements: 1000,
                        };
                        return [4 /*yield*/, this.cidadeService.buscarCidades(request)];
                    case 1:
                        response = _a.sent();
                        this.cidades = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_5.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioD2AnswerComponent.prototype.logOnConsole = function (dadosPessoaisForm) {
        console.log(dadosPessoaisForm);
    };
    QuestionarioD2AnswerComponent.prototype.activateTab = function (tab) {
        $('.nav-tabs a[href="#' + tab + '"]').removeClass("disabled");
        $('.nav-tabs a[href="#' + tab + '"]').tab("show");
    };
    QuestionarioD2AnswerComponent.prototype.activateTab2 = function (action) {
        if (action.localeCompare("previous") == 0) {
            if (this.activeTabNumber > 0) {
                this.activeTabNumber -= 1;
            }
        }
        else if (action.localeCompare("next") == 0) {
            if (this.activeTabNumber < this.tabNames.length - 1) {
                this.activeTabNumber += 1;
            }
        }
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').removeClass("disabled");
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').tab("show");
    };
    QuestionarioD2AnswerComponent.prototype.setarElementoSelecionado = function (valor) {
        this.pessoa.usuarioId = valor;
    };
    QuestionarioD2AnswerComponent.prototype.salvar = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, blogImage, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.pessoaService.update(pessoa.id, pessoa)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        if (!(this.pessoa.imagemId != null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getPessoaImagem(this.pessoa.imagemId)];
                    case 2:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.pessoa);
                        _a.label = 3;
                    case 3:
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 5];
                    case 4:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 5];
                    case 5:
                        this.atualizarDadoFormandoCV();
                        this.atualizarCaminhoFormativo();
                        this.atualizarQuestionarioFormando("INICIADO");
                        return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioD2AnswerComponent.prototype.salvarImagem = function () {
        var _this = this;
        this.imagemService
            .salvarCropped({ imageData: this.croppedImage })
            .then(function (response) {
            _this.swtAlert2Service.successAlert(response.message);
            _this.pessoa.imagemId = response.entity.id;
            _this.salvar(_this.pessoa);
            $("#modal-pessoa-imagem").modal("hide");
        })
            .catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    QuestionarioD2AnswerComponent.prototype.fileChangeEvent = function (event) {
        this.imageChangedEvent = event;
        console.log(event);
    };
    QuestionarioD2AnswerComponent.prototype.imageCropped = function (event) {
        this.croppedImage = event.base64;
    };
    QuestionarioD2AnswerComponent.prototype.imageLoaded = function () {
        console.log("Imagem carregada");
    };
    QuestionarioD2AnswerComponent.prototype.cropperReady = function () {
        console.log("Imagem cortada");
    };
    QuestionarioD2AnswerComponent.prototype.loadImageFailed = function () {
        console.log("Carregamento da imagem falhou!");
    };
    QuestionarioD2AnswerComponent.prototype.setarFormaVidaSelecionada = function () {
        for (var i = 0; i < this.formasVida.length; i++) {
            if (this.formasVida[i].id == this.pessoa.formaVidaId) {
                this.formaVidaSelecionada = this.formasVida[i];
            }
        }
    };
    QuestionarioD2AnswerComponent.prototype.carregarDadoVocacional = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.dadoVocacionalService.porPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response.entity != undefined && response.entity != null) {
                            this.dadoVocacional = response.entity;
                            this.pessoaFormadorPessoalSelecionado =
                                this.pessoaInfoBuilder.getInstance();
                            this.pessoaFormadorPessoalSelecionado.nome =
                                this.dadoVocacional.formadorPessoalPessoaNome;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioD2AnswerComponent.prototype.selecionarFormadorPessoal = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
                this.pessoaFormadorPessoalSelecionado = pessoa;
                $("#listagem-pessoa-formador-pessoal").modal("hide");
                return [2 /*return*/];
            });
        });
    };
    QuestionarioD2AnswerComponent.prototype.selecionarFormadorComunitario = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                //this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
                this.pessoaFormadorComunitarioSelecionado = pessoa;
                $("#listagem-pessoa-formador-comunitario").modal("hide");
                return [2 /*return*/];
            });
        });
    };
    QuestionarioD2AnswerComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioD2AnswerComponent.prototype.carregarDadoFormandoCV = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.dadoFormandoCVService.porPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.dadoFormandoCV = response;
                            this.carregarEstados(this.dadoFormandoCV.pais_origem_id);
                            this.carregarCidades(this.dadoFormandoCV.estado_origem_id);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_5.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioD2AnswerComponent.prototype.carregarCaminhoFormativo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.caminhoFormativoService.porPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.caminhoFormativo = response;
                            console.log(this.caminhoFormativo);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_6 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_6.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioD2AnswerComponent.prototype.atualizarCaminhoFormativo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, err_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        this.caminhoFormativo.missao_atual_id =
                            this.pessoa.vinculoMissao.missaoAtualId;
                        this.caminhoFormativo.nome_formador_pessoal =
                            this.pessoaFormadorPessoalSelecionado.nome;
                        if (!(this.caminhoFormativo.id == null)) return [3 /*break*/, 2];
                        this.caminhoFormativo.pessoaId = this.pessoa.id;
                        return [4 /*yield*/, this.caminhoFormativoService.cadastrar(this.caminhoFormativo)];
                    case 1:
                        response = _a.sent();
                        this.caminhoFormativo = response;
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.caminhoFormativoService.atualizar(this.caminhoFormativo)];
                    case 3:
                        response = _a.sent();
                        this.caminhoFormativo = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_7 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_7.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioD2AnswerComponent.prototype.carregarRespostas = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_8;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.respostaQuestionarioService.porPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.respostas = response.content;
                            console.log(this.respostas);
                            this.respostas.forEach(function (resposta) {
                                switch (resposta.questionario_pergunta_id) {
                                    case 1:
                                        _this.resposta1 = resposta;
                                    case 2:
                                        _this.resposta2 = resposta;
                                    case 3:
                                        _this.resposta3 = resposta;
                                    case 4:
                                        _this.resposta4 = resposta;
                                    case 5:
                                        _this.resposta5 = resposta;
                                    case 6:
                                        _this.resposta6 = resposta;
                                    case 7:
                                        _this.resposta7 = resposta;
                                    case 8:
                                        _this.resposta8 = resposta;
                                    case 9:
                                        _this.resposta9 = resposta;
                                    case 10:
                                        _this.resposta10 = resposta;
                                    case 11:
                                        _this.resposta11 = resposta;
                                    case 12:
                                        _this.resposta12 = resposta;
                                    case 13:
                                        _this.resposta13 = resposta;
                                    case 14:
                                        _this.resposta14 = resposta;
                                    case 15:
                                        _this.resposta15 = resposta;
                                    case 16:
                                        _this.resposta16 = resposta;
                                    case 17:
                                        _this.resposta17 = resposta;
                                    case 18:
                                        _this.resposta18 = resposta;
                                    case 19:
                                        _this.resposta19 = resposta;
                                    case 20:
                                        _this.resposta20 = resposta;
                                }
                            });
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_8 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_8.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioD2AnswerComponent.prototype.carregarQuestionarioFormando = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.questionarioFormandoService.buscarPorId(this.questionarioFormandoId)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.questionarioFormando = response;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_9 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_9.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioD2AnswerComponent.prototype.carregarMensagemAF = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_10;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.dadoFormandoCVService.porPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.dadoFormandoCV = response;
                            this.carregarEstados(this.dadoFormandoCV.pais_origem_id);
                            this.carregarCidades(this.dadoFormandoCV.estado_origem_id);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_10 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_10.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioD2AnswerComponent.prototype.carregarMensagemRL = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_11;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.dadoFormandoCVService.porPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.dadoFormandoCV = response;
                            this.carregarEstados(this.dadoFormandoCV.pais_origem_id);
                            this.carregarCidades(this.dadoFormandoCV.estado_origem_id);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_11 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_11.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioD2AnswerComponent.prototype.atualizarDadoFormandoCV = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, err_12;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!(this.dadoFormandoCV.id == null)) return [3 /*break*/, 2];
                        this.dadoFormandoCV.pessoaId = this.pessoa.id;
                        console.log(this.dadoFormandoCV);
                        return [4 /*yield*/, this.dadoFormandoCVService.cadastrar(this.dadoFormandoCV)];
                    case 1:
                        response = _a.sent();
                        this.dadoFormandoCV = response;
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.dadoFormandoCVService.atualizar(this.dadoFormandoCV)];
                    case 3:
                        response = _a.sent();
                        this.dadoFormandoCV = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_12 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_12.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioD2AnswerComponent.prototype.atualizarResposta = function (resposta) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, err_13;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        resposta.pessoaId = this.pessoa.id;
                        if (!(resposta.id == null)) return [3 /*break*/, 2];
                        console.log(resposta);
                        return [4 /*yield*/, this.respostaQuestionarioService.cadastrar(resposta)];
                    case 1:
                        response = _a.sent();
                        resposta = response;
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.respostaQuestionarioService.atualizar(resposta.id, resposta)];
                    case 3:
                        response = _a.sent();
                        resposta = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_13 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_13.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioD2AnswerComponent.prototype.atualizarMensagemAF = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, err_14;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!(this.mensagemAF.id == null)) return [3 /*break*/, 2];
                        this.mensagemAF.origem = 1;
                        this.mensagemAF.origem_id = this.questionarioFormandoId;
                        this.mensagemAF.lida = 0;
                        return [4 /*yield*/, this.mensagemService.cadastrarMensagemAF(this.mensagemAF)];
                    case 1:
                        response = _a.sent();
                        this.mensagemAF = response;
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.mensagemService.atualizarMensagemAF(this.mensagemAF)];
                    case 3:
                        response = _a.sent();
                        this.mensagemAF = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_14 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_14.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioD2AnswerComponent.prototype.atualizarMensagemRL = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, err_15;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!(this.mensagemRL.id == null)) return [3 /*break*/, 2];
                        this.mensagemRL.origem = 1;
                        this.mensagemRL.origem_id = this.questionarioFormandoId;
                        this.mensagemRL.lida = 0;
                        return [4 /*yield*/, this.mensagemService.cadastrarMensagemRL(this.mensagemRL)];
                    case 1:
                        response = _a.sent();
                        this.mensagemRL = response;
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.mensagemService.atualizarMensagemRL(this.mensagemRL)];
                    case 3:
                        response = _a.sent();
                        this.mensagemRL = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_15 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_15.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioD2AnswerComponent.prototype.salvarQuestionario = function () {
        this.resposta1.questionario_pergunta_id = 1;
        this.atualizarResposta(this.resposta1);
        this.resposta2.questionario_pergunta_id = 2;
        this.atualizarResposta(this.resposta2);
        this.resposta3.questionario_pergunta_id = 3;
        this.atualizarResposta(this.resposta3);
        this.resposta4.questionario_pergunta_id = 4;
        this.atualizarResposta(this.resposta4);
        this.resposta5.questionario_pergunta_id = 5;
        this.atualizarResposta(this.resposta5);
        this.resposta6.questionario_pergunta_id = 6;
        this.atualizarResposta(this.resposta6);
        this.resposta7.questionario_pergunta_id = 7;
        this.atualizarResposta(this.resposta7);
        this.resposta8.questionario_pergunta_id = 8;
        this.atualizarResposta(this.resposta8);
        this.resposta9.questionario_pergunta_id = 9;
        this.atualizarResposta(this.resposta9);
        this.resposta10.questionario_pergunta_id = 10;
        this.atualizarResposta(this.resposta10);
        this.resposta11.questionario_pergunta_id = 11;
        this.atualizarResposta(this.resposta11);
        this.resposta12.questionario_pergunta_id = 12;
        this.atualizarResposta(this.resposta12);
        this.resposta13.questionario_pergunta_id = 13;
        this.atualizarResposta(this.resposta13);
        this.resposta14.questionario_pergunta_id = 14;
        this.atualizarResposta(this.resposta14);
        this.resposta15.questionario_pergunta_id = 15;
        this.atualizarResposta(this.resposta15);
        this.resposta16.questionario_pergunta_id = 16;
        this.atualizarResposta(this.resposta16);
        this.resposta17.questionario_pergunta_id = 17;
        this.atualizarResposta(this.resposta17);
        this.resposta18.questionario_pergunta_id = 18;
        this.atualizarResposta(this.resposta18);
        this.resposta19.questionario_pergunta_id = 19;
        this.atualizarResposta(this.resposta19);
        this.resposta20.questionario_pergunta_id = 20;
        this.atualizarResposta(this.resposta20);
        if (this.mensagemAF.mensagem != null)
            this.atualizarMensagemAF();
        if (this.mensagemRL.mensagem != null)
            this.atualizarMensagemRL();
        this.atualizarQuestionarioFormando("INICIADO");
    };
    QuestionarioD2AnswerComponent.prototype.enviarQuestionario = function () {
        this.salvarQuestionario();
        this.atualizarQuestionarioFormando("ENVIADO");
        this.router.navigate(["secured/questionario-agradecimento"]);
    };
    QuestionarioD2AnswerComponent.prototype.atualizarQuestionarioFormando = function (status) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_16;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!(this.questionarioFormando.id != null)) return [3 /*break*/, 2];
                        this.questionarioFormando.status = status;
                        if (status == "ENVIADO") {
                            this.questionarioFormando.data_envio = new Date();
                        }
                        return [4 /*yield*/, this.questionarioFormandoService.atualizar(this.questionarioFormando.id, this.questionarioFormando)];
                    case 1:
                        response = _a.sent();
                        this.questionarioFormando = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        err_16 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_16.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return QuestionarioD2AnswerComponent;
}());
export { QuestionarioD2AnswerComponent };
