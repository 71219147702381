import * as tslib_1 from "tslib";
import { VinculoDifusao } from '../model/vinculodifusao.model';
import { AbstractBuilder } from './abstract.builder';
var VinculoDifusaoBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(VinculoDifusaoBuilder, _super);
    function VinculoDifusaoBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    VinculoDifusaoBuilder.prototype.reset = function () {
        this.entity = new VinculoDifusao(null, null, null, null, null, null);
    };
    return VinculoDifusaoBuilder;
}(AbstractBuilder));
export { VinculoDifusaoBuilder };
