import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
var ParecerD2FCAnswerComponent = /** @class */ (function () {
    function ParecerD2FCAnswerComponent(wopGetAllPublic, pessoaService, imagemService, paisService, estadoService, cidadeService, elementoGrupoDominioService, elementoGrupoDominioLocalService, missaoService, swtAlert2Service, usuarioService, pessoaBuilder, pessoaInfoBuilder, dadoVocacionalService, dadoFormandoCVService, caminhoFormativoService, respostaParecerService, questionarioFormandoService, parecerResponsavelService, dadoFormandoCVBuilder, caminhoFormativoFormandoBuilder, respostaParecerBuilder, questionarioFormandoBuilder, route, router) {
        this.wopGetAllPublic = wopGetAllPublic;
        this.pessoaService = pessoaService;
        this.imagemService = imagemService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.missaoService = missaoService;
        this.swtAlert2Service = swtAlert2Service;
        this.usuarioService = usuarioService;
        this.pessoaBuilder = pessoaBuilder;
        this.pessoaInfoBuilder = pessoaInfoBuilder;
        this.dadoVocacionalService = dadoVocacionalService;
        this.dadoFormandoCVService = dadoFormandoCVService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.respostaParecerService = respostaParecerService;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelService = parecerResponsavelService;
        this.dadoFormandoCVBuilder = dadoFormandoCVBuilder;
        this.caminhoFormativoFormandoBuilder = caminhoFormativoFormandoBuilder;
        this.respostaParecerBuilder = respostaParecerBuilder;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.route = route;
        this.router = router;
        this.intlTelInputCalled = false;
        this.missoesVinculo = new Array();
        this.missoes = new Array();
        this.formasVida = new Array();
        this.niveisFormacao = new Array();
        this.dadoFormandoCV = this.dadoFormandoCVBuilder.getInstance();
        this.caminhoFormativoFormando =
            this.caminhoFormativoFormandoBuilder.getInstance();
        this.imageChangedEvent = "";
        this.croppedImage = "";
        this.nextClicked = false;
        this.pessoaResponsavel = this.pessoaBuilder.getInstance();
        this.formando = this.pessoaBuilder.getInstance();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.url = environment.usuario.selectPorUsername;
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.tabNames = ["dados-pessoais-tab-content", "questionario-tab-content"];
        this.activeTabNumber = 0;
        this.resposta1 = respostaParecerBuilder.getInstance();
        this.resposta2 = respostaParecerBuilder.getInstance();
        this.resposta3 = respostaParecerBuilder.getInstance();
        this.resposta4 = respostaParecerBuilder.getInstance();
        this.resposta5 = respostaParecerBuilder.getInstance();
        this.resposta6 = respostaParecerBuilder.getInstance();
        this.resposta7 = respostaParecerBuilder.getInstance();
        this.resposta8 = respostaParecerBuilder.getInstance();
        this.resposta9 = respostaParecerBuilder.getInstance();
        this.resposta10 = respostaParecerBuilder.getInstance();
        this.resposta11 = respostaParecerBuilder.getInstance();
        this.resposta12 = respostaParecerBuilder.getInstance();
        this.resposta13 = respostaParecerBuilder.getInstance();
        this.resposta14 = respostaParecerBuilder.getInstance();
        this.resposta15 = respostaParecerBuilder.getInstance();
        this.resposta16 = respostaParecerBuilder.getInstance();
        this.resposta17 = respostaParecerBuilder.getInstance();
        this.resposta18 = respostaParecerBuilder.getInstance();
    }
    ParecerD2FCAnswerComponent.prototype.ngOnInit = function () {
        this.initialization();
    };
    ParecerD2FCAnswerComponent.prototype.initialization = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.getUsuarioAtual()];
                    case 1:
                        _b.sent();
                        this.carregarPaises();
                        this.carregarFormasVida();
                        this.carregarNiveisFormacao();
                        return [4 /*yield*/, this.carregarMissoesVinculo()];
                    case 2:
                        _b.sent();
                        this.parecerResponsavelId = Number(this.route.snapshot.paramMap.get("id"));
                        return [4 /*yield*/, this.carregarParecerResponsavel()];
                    case 3:
                        _b.sent();
                        return [4 /*yield*/, this.carregarQuestionarioFormando()];
                    case 4:
                        _b.sent();
                        return [4 /*yield*/, this.carregarPessoaFormando(this.questionarioFormando.pessoaId)];
                    case 5:
                        _b.sent();
                        _a = this;
                        return [4 /*yield*/, this.carregarPessoaResponsavel(this.usuarioAtual.id)];
                    case 6:
                        _a.pessoaResponsavel = _b.sent();
                        return [4 /*yield*/, this.carregarDadoVocacional()];
                    case 7:
                        _b.sent();
                        return [4 /*yield*/, this.carregarDadoFormandoCV()];
                    case 8:
                        _b.sent();
                        return [4 /*yield*/, this.carregarCaminhoFormativo()];
                    case 9:
                        _b.sent();
                        return [4 /*yield*/, this.carregarRespostas()];
                    case 10:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ParecerD2FCAnswerComponent.prototype.getUsuarioAtual = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuarioAtual = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerD2FCAnswerComponent.prototype.carregarMissoesVinculo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.wopGetAllPublic.getAllMissao()];
                    case 1:
                        response = _a.sent();
                        this.missoesVinculo = response.entity;
                        this.missoesVinculo = this.missoesVinculo.sort(function (a, b) {
                            return a.nome.localeCompare(b.nome);
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerD2FCAnswerComponent.prototype.carregarPessoaFormando = function (pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, blogImage, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.pessoaService.buscaPorId(pessoaId)];
                    case 1:
                        response = _a.sent();
                        this.formando = response.entity;
                        this.pessoaFormandoId = this.formando.id;
                        if (!(this.formando.imagemId != null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getPessoaImagem(this.formando.imagemId)];
                    case 2:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.formando);
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ParecerD2FCAnswerComponent.prototype.carregarPessoaResponsavel = function (pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.pessoaService.buscaPorId(pessoaId)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.entity];
                    case 2:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerD2FCAnswerComponent.prototype.carregarFormasVida = function () {
        var _this = this;
        var buscaFormaVida = {
            grupoNome: "FORMA_VIDA",
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(buscaFormaVida)
            .then(function (lista) {
            _this.formasVida = lista.entity;
        })
            .catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    ParecerD2FCAnswerComponent.prototype.carregarNiveisFormacao = function () {
        var _this = this;
        var busca = {
            grupoNome: "NIVEL_FORMACAO",
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(busca)
            .then(function (lista) {
            _this.niveisFormacao = lista.entity;
        })
            .catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    ParecerD2FCAnswerComponent.prototype.carregarPaises = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.paisService.findAll()];
                    case 1:
                        response = _a.sent();
                        this.paises = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerD2FCAnswerComponent.prototype.carregarEstados = function (paisId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        request = {
                            paisId: paisId,
                            nome: undefined,
                            pageNumber: 0,
                            quantityOfElements: 1000,
                        };
                        return [4 /*yield*/, this.estadoService.buscarEstados(request)];
                    case 1:
                        response = _a.sent();
                        this.estados = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerD2FCAnswerComponent.prototype.carregarCidades = function (estadoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, e_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        request = {
                            estadoId: estadoId,
                            nome: undefined,
                            pageNumber: 0,
                            quantityOfElements: 1000,
                        };
                        return [4 /*yield*/, this.cidadeService.buscarCidades(request)];
                    case 1:
                        response = _a.sent();
                        this.cidades = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_5.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerD2FCAnswerComponent.prototype.logOnConsole = function (dadosPessoaisForm) {
        console.log(dadosPessoaisForm);
    };
    ParecerD2FCAnswerComponent.prototype.activateTab = function (tab) {
        $('.nav-tabs a[href="#' + tab + '"]').removeClass("disabled");
        $('.nav-tabs a[href="#' + tab + '"]').tab("show");
    };
    ParecerD2FCAnswerComponent.prototype.activateTab2 = function (action) {
        if (action.localeCompare("previous") == 0) {
            if (this.activeTabNumber > 0) {
                this.activeTabNumber -= 1;
            }
        }
        else if (action.localeCompare("next") == 0) {
            if (this.activeTabNumber < this.tabNames.length - 1) {
                this.activeTabNumber += 1;
            }
        }
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').removeClass("disabled");
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').tab("show");
    };
    ParecerD2FCAnswerComponent.prototype.salvar = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                //this.atualizarCaminhoFormativo();
                this.atualizarParecerResponsavel("INICIADO");
                return [2 /*return*/];
            });
        });
    };
    ParecerD2FCAnswerComponent.prototype.fileChangeEvent = function (event) {
        this.imageChangedEvent = event;
        console.log(event);
    };
    ParecerD2FCAnswerComponent.prototype.imageCropped = function (event) {
        this.croppedImage = event.base64;
    };
    ParecerD2FCAnswerComponent.prototype.imageLoaded = function () {
        console.log("Imagem carregada");
    };
    ParecerD2FCAnswerComponent.prototype.cropperReady = function () {
        console.log("Imagem cortada");
    };
    ParecerD2FCAnswerComponent.prototype.loadImageFailed = function () {
        console.log("Carregamento da imagem falhou!");
    };
    ParecerD2FCAnswerComponent.prototype.carregarDadoVocacional = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.dadoVocacionalService.porPessoaId(this.formando.id)];
                    case 1:
                        response = _a.sent();
                        if (response.entity != undefined && response.entity != null) {
                            this.dadoVocacional = response.entity;
                            this.pessoaFormadorPessoalSelecionado =
                                this.pessoaInfoBuilder.getInstance();
                            this.pessoaFormadorPessoalSelecionado.nome =
                                this.dadoVocacional.formadorPessoalPessoaNome;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerD2FCAnswerComponent.prototype.selecionarFormadorComunitario = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    ParecerD2FCAnswerComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerD2FCAnswerComponent.prototype.carregarDadoFormandoCV = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.dadoFormandoCVService.porPessoaId(this.formando.id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.dadoFormandoCV = response;
                            this.carregarEstados(this.dadoFormandoCV.pais_origem_id);
                            this.carregarCidades(this.dadoFormandoCV.estado_origem_id);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_5.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerD2FCAnswerComponent.prototype.carregarCaminhoFormativo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.caminhoFormativoService.porPessoaId(this.formando.id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.caminhoFormativoFormando = response;
                            console.log(this.caminhoFormativoFormando);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_6 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_6.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerD2FCAnswerComponent.prototype.atualizarCaminhoFormativo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!(this.caminhoFormativoFormando.id != null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.caminhoFormativoService.atualizar(this.caminhoFormativoFormando)];
                    case 1:
                        response = _a.sent();
                        this.caminhoFormativoFormando = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        err_7 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_7.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ParecerD2FCAnswerComponent.prototype.carregarRespostas = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_8;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.respostaParecerService.porPessoaId(this.pessoaResponsavel.id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.respostas = response.content;
                            console.log(this.respostas);
                            this.respostas.forEach(function (resposta) {
                                switch (resposta.parecer_pergunta_id) {
                                    case 1:
                                        _this.resposta1 = resposta;
                                    case 2:
                                        _this.resposta2 = resposta;
                                    case 3:
                                        _this.resposta3 = resposta;
                                    case 4:
                                        _this.resposta4 = resposta;
                                    case 5:
                                        _this.resposta5 = resposta;
                                    case 6:
                                        _this.resposta6 = resposta;
                                    case 7:
                                        _this.resposta7 = resposta;
                                    case 8:
                                        _this.resposta8 = resposta;
                                    case 9:
                                        _this.resposta9 = resposta;
                                    case 10:
                                        _this.resposta10 = resposta;
                                    case 11:
                                        _this.resposta11 = resposta;
                                    case 12:
                                        _this.resposta12 = resposta;
                                    case 13:
                                        _this.resposta13 = resposta;
                                    case 14:
                                        _this.resposta14 = resposta;
                                    case 15:
                                        _this.resposta15 = resposta;
                                    case 16:
                                        _this.resposta16 = resposta;
                                    case 17:
                                        _this.resposta17 = resposta;
                                    case 18:
                                        _this.resposta18 = resposta;
                                }
                            });
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_8 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_8.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerD2FCAnswerComponent.prototype.carregarParecerResponsavel = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.parecerResponsavelService.buscarPorId(this.parecerResponsavelId)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.parecerResponsavel = response;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_9 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_9.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerD2FCAnswerComponent.prototype.carregarQuestionarioFormando = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_10;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.questionarioFormandoService.buscarPorId(this.parecerResponsavel.questionarioFormandoId)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.questionarioFormando = response;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_10 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_10.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerD2FCAnswerComponent.prototype.atualizarResposta = function (resposta) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, err_11;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        resposta.pessoaId = this.pessoaResponsavel.id;
                        if (!(resposta.id == null)) return [3 /*break*/, 2];
                        console.log(resposta);
                        return [4 /*yield*/, this.respostaParecerService.cadastrar(resposta)];
                    case 1:
                        response = _a.sent();
                        resposta = response;
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.respostaParecerService.atualizar(resposta.id, resposta)];
                    case 3:
                        response = _a.sent();
                        resposta = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_11 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_11.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ParecerD2FCAnswerComponent.prototype.salvarQuestionario = function () {
        this.resposta1.parecer_pergunta_id = 1;
        this.atualizarResposta(this.resposta1);
        this.resposta2.parecer_pergunta_id = 2;
        this.atualizarResposta(this.resposta2);
        this.resposta3.parecer_pergunta_id = 3;
        this.atualizarResposta(this.resposta3);
        this.resposta4.parecer_pergunta_id = 4;
        this.atualizarResposta(this.resposta4);
        this.resposta5.parecer_pergunta_id = 5;
        this.atualizarResposta(this.resposta5);
        this.resposta6.parecer_pergunta_id = 6;
        this.atualizarResposta(this.resposta6);
        this.resposta7.parecer_pergunta_id = 7;
        this.atualizarResposta(this.resposta7);
        this.resposta8.parecer_pergunta_id = 8;
        this.atualizarResposta(this.resposta8);
        this.resposta9.parecer_pergunta_id = 9;
        this.atualizarResposta(this.resposta9);
        this.resposta10.parecer_pergunta_id = 10;
        this.atualizarResposta(this.resposta10);
        this.resposta11.parecer_pergunta_id = 11;
        this.atualizarResposta(this.resposta11);
        this.resposta12.parecer_pergunta_id = 12;
        this.atualizarResposta(this.resposta12);
        this.resposta13.parecer_pergunta_id = 13;
        this.atualizarResposta(this.resposta13);
        this.resposta14.parecer_pergunta_id = 14;
        this.atualizarResposta(this.resposta14);
        this.resposta15.parecer_pergunta_id = 15;
        this.atualizarResposta(this.resposta15);
        this.resposta16.parecer_pergunta_id = 16;
        this.atualizarResposta(this.resposta16);
        this.resposta17.parecer_pergunta_id = 17;
        this.atualizarResposta(this.resposta17);
        this.resposta18.parecer_pergunta_id = 18;
        this.atualizarResposta(this.resposta18);
        this.atualizarParecerResponsavel("INICIADO");
    };
    ParecerD2FCAnswerComponent.prototype.enviarQuestionario = function () {
        this.salvarQuestionario();
        this.atualizarParecerResponsavel("ENVIADO");
        this.router.navigate(["secured/parecer-agradecimento"]);
    };
    ParecerD2FCAnswerComponent.prototype.atualizarParecerResponsavel = function (status) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_12;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!(this.parecerResponsavel.id != null)) return [3 /*break*/, 2];
                        this.parecerResponsavel.status = status;
                        if (status == "ENVIADO") {
                            this.parecerResponsavel.data_envio = new Date();
                        }
                        return [4 /*yield*/, this.parecerResponsavelService.atualizar(this.parecerResponsavel.id, this.parecerResponsavel)];
                    case 1:
                        response = _a.sent();
                        this.parecerResponsavel = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        err_12 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_12.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return ParecerD2FCAnswerComponent;
}());
export { ParecerD2FCAnswerComponent };
