import * as tslib_1 from "tslib";
import { Estado } from '../model/estado.model';
import { AbstractBuilder } from './abstract.builder';
var EstadoBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(EstadoBuilder, _super);
    function EstadoBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EstadoBuilder.prototype.reset = function () {
        this.entity = new Estado(null, null, null, []);
    };
    return EstadoBuilder;
}(AbstractBuilder));
export { EstadoBuilder };
