<div class="hold-transition register-page-container">
    <header>
        <nav class="navbar navbar-expand-md fixed-top">
            <a class="brand-link">
                <img src="assets/images/logo.png" alt="Pastoreio" class="brand-image img-circle elevation-3"
                    style="opacity: .8; height: 2rem; margin-right: 0.5rem;">
                <span class="brand-text font-weight-strong">Missionário</span>
            </a>
        </nav>
    </header>
    <!-- <main class="min-vh-100 d-flex flex-column justify-content-center align-items-center" *ngIf="linkInfo != null"> -->
    <main *ngIf="linkInfo != null">
        <img src="assets/images/logo completo_fundotransparente.png" />
        <h1 class="mt-4" style="text-wrap: nowrap;">Célula {{linkInfo.nome}}</h1>
        <h5 style="text-wrap: nowrap;">{{linkInfo.centroEvangelizacaoNome}}</h5>
        <p>
            <small><strong>Nível:</strong> {{linkInfo.nivelCelulaNome}} | <strong>Missão:</strong>
                {{linkInfo.missaoNome}} | <strong>Regional:</strong> {{linkInfo.regionalNome}}</small>
        </p>

        <div class="card">
            <div class="card-body login-card-body">
                <p i18n class="login-box-msg">Faça o login se já possui cadastro no WOP</p>

                <form #loginForm="ngForm">
                    <div class="input-group mb-3">
                        <input type="username" class="form-control" placeholder="Username" i18n-placeholder
                            name="username" [(ngModel)]="formularioCadastro.loginWOP.username">
                        <div class="input-group-append input-group-text">
                            <span class="fas fa-user"></span>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input type="{{passwordFieldType}}" class="form-control" placeholder="Password" i18n-placeholder
                            name="password" [(ngModel)]="formularioCadastro.loginWOP.password">
                        <div class="input-group-append input-group-text" (click)="togglePasswordVisibility()">
                            <span class="fas fa-eye"></span>
                        </div>
                        <div class="input-group-append input-group-text">
                            <span class="fas fa-lock"></span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <a class="cursor-pointer" style="line-height: 2rem;" data-toggle="modal"
                                data-target="#modal-esqueceu-senha">Esqueceu a senha?</a>
                        </div>
                        <!-- /.col -->
                        <div class="col-6">
                            <button i18n type="submit" class="btn btn-pastoreio btn-block btn-flat"
                                (click)="cadastrarSeEmCelulaPorLoginWOP()"
                                [disabled]="!loginForm.form.valid">Inscrever-se</button>
                        </div>
                        <!-- /.col -->
                    </div>
                </form>


                <p class="mb-1 mt-3">
                    Não possui cadastro? <a i18n href="" data-toggle="modal" data-target="#form-cadastro"
                        class="text-center">Cadastre-se aqui!</a>
                </p>

            </div>
        </div>

    </main>
    <!-- <main class="min-vh-100 d-flex flex-column justify-content-center align-items-center" *ngIf="linkInfo == null"> -->
    <main *ngIf="linkInfo == null">
        <img title="WoP - Missionário" src="assets/images/logo completo_fundotransparente.png" />
        <h4 class="mt-4" style="text-wrap: nowrap;">{{mensagemTextoNaoEncontrado}}</h4>
    </main>
    <div class="modal fade" id="form-cadastro">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Cadastro</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="false">
                            <div class="form-group">
                                <label i18n for="ja_inscrito">Já se inscreveu no formulário da célula?</label>
                                <select class="form-control" id="ja_inscrito"
                                    [(ngModel)]="possuiDadosCadastradosNoFormularioDeImportacao" name="ja_inscrito">
                                    <option [ngValue]="null" i18n>Selecione uma opção</option>
                                    <option [ngValue]="true" i18n>Sim</option>
                                    <option [ngValue]="false" i18n>Não</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="false">
                        <div class="card card-default">
                            <form #importacaoDadosForm="ngForm"
                                (ngSubmit)="cadastrarSeEmCelulaPorImportacaoFormulario()">
                                <div class="card-body">
                                    <div class="row">

                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="npessoa_user">Nome de usuário</label>
                                                <input [(ngModel)]="formularioCadastro.loginWOP.username"
                                                    name="npessoa_user" type="text" class="form-control"
                                                    id="npessoa_user" i18n-placeholder required>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="npessoa_pass">Senha</label>
                                                <input [(ngModel)]="formularioCadastro.loginWOP.password"
                                                    name="npessoa_pass" type="password" class="form-control"
                                                    id="npessoa_pass" i18n-placeholder required>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="npessoa_mail">E-mail</label>
                                                <input [(ngModel)]="formularioCadastro.loginWOP.emailImportacaoCal"
                                                    name="npessoa_mail" type="email" class="form-control"
                                                    id="npessoa_mail" i18n-placeholder required>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox"
                                                    [(ngModel)]="formularioCadastro.cadastroPublico.aceiteTermo"
                                                    [ngModelOptions]="{standalone: true}">
                                                <label class="form-check-label">Li e concordo com os <a i18n
                                                        data-toggle="modal" data-target="#termo-uso"
                                                        style="cursor: pointer" class="text-center text-bold">Termos de
                                                        uso</a></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-12"
                                            *ngIf="termoTratamentoDadosVigente != null">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox"
                                                    [(ngModel)]="formularioCadastro.cadastroPublico.aceiteTermoTratamentoDados"
                                                    [ngModelOptions]="{standalone: true}">
                                                <label class="form-check-label">Li e concordo com os <a i18n
                                                        data-toggle="modal" data-target="#termo-tratamento-dados"
                                                        style="cursor: pointer" class="text-center text-bold">Termos de
                                                        tratamento de dados</a></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer text-right">
                                    <button type="submit" class="btn btn-pastoreio"
                                        [disabled]="(importacaoDadosForm.valid==false || formularioCadastro.cadastroPublico.aceiteTermo==false)"
                                        i18n>Cadastrar</button>
                                </div>
                            </form>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="true">
                        <form #dadosPessoaisForm="ngForm" (ngSubmit)="cadastrar(formularioCadastro)">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="card card-default">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_nome">Nome</label>
                                                        <input [(ngModel)]="formularioCadastro.cadastroPublico.nome"
                                                            name="npessoa_nome" type="text" class="form-control"
                                                            id="ncev_nome" placeholder="Digite o seu nome"
                                                            i18n-placeholder required>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_sobrenome">Sobrenome</label>
                                                        <input
                                                            [(ngModel)]="formularioCadastro.cadastroPublico.sobrenome"
                                                            name="npessoa_sobrenome" type="text" class="form-control"
                                                            id="npessoa_sobrenome" placeholder="Digite o seu sobrenome"
                                                            i18n-placeholder required>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_genero">Sexo</label>
                                                        <select class="form-control" id="npessoa_genero"
                                                            [(ngModel)]="formularioCadastro.cadastroPublico.sexo"
                                                            name="npessoa_genero" required>
                                                            <option disabled value="null" i18n>Selecione o sexo</option>
                                                            <option value="masculino" i18n>Masculino</option>
                                                            <option value="feminino" i18n>Feminino</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_dtnas">Data de Nascimento</label>
                                                        <input
                                                            [(ngModel)]="formularioCadastro.cadastroPublico.dataNascimento"
                                                            name="npessoa_dtnas" type="date" class="form-control"
                                                            id="npessoa_dtnas" i18n-placeholder required>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_nacionalidade">Nacionalidade</label>
                                                        <select class="form-control" id="npessoa_nacionalidade"
                                                            [(ngModel)]="formularioCadastro.cadastroPublico.nacionalidadeId"
                                                            name="npessoa_nacionalidade" required>
                                                            <option disabled value="null" i18n>Selecione a sua
                                                                nacionalidade</option>
                                                            <option *ngFor="let pais of paises" [value]="pais.id">
                                                                {{pais.nome}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_telefone">Telefone</label>
                                                        <input [(ngModel)]="formularioCadastro.cadastroPublico.telefone"
                                                            name="npessoa_telefone" type="tel" class="form-control"
                                                            id="npessoa_telefone" i18n-placeholder required>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_user">Nome de usuário</label>
                                                        <input [(ngModel)]="formularioCadastro.cadastroPublico.username"
                                                            name="npessoa_user" type="text" class="form-control"
                                                            id="npessoa_user" i18n-placeholder required>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_pass">Senha</label>
                                                        <input [(ngModel)]="formularioCadastro.cadastroPublico.password"
                                                            name="npessoa_pass" type="password" class="form-control"
                                                            id="npessoa_pass" i18n-placeholder required>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_pass">Confirmar Senha</label>
                                                        <input
                                                            [(ngModel)]="formularioCadastro.cadastroPublico.confPassword"
                                                            name="npessoa_confpass" type="password" class="form-control"
                                                            id="npessoa_confpass" i18n-placeholder required>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_mail">E-mail</label>
                                                        <input [(ngModel)]="formularioCadastro.cadastroPublico.email"
                                                            name="npessoa_mail" type="email" class="form-control"
                                                            id="npessoa_mail" i18n-placeholder required>
                                                    </div>
                                                </div>
                                                <!--div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_mail">Confirmação de E-mail</label>
                                                        <input name="npessoa_confmail" type="email" class="form-control" id="npessoa_confmail" i18n-placeholder required>
                                                    </div>
                                                </div-->
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_formavida">Forma de Vida</label>
                                                        <select class="form-control" id="npessoa_formavida"
                                                            [(ngModel)]="formularioCadastro.cadastroPublico.formaVidaId"
                                                            name="npessoa_formavida" required>
                                                            <option disabled value="null" i18n>Selecione a sua forma de
                                                                vida</option>
                                                            <option *ngFor="let formaVida of formasVida"
                                                                [value]="formaVida.id">{{formaVida.nome}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_nivelformativo">Nível Formativo</label>
                                                        <select class="form-control" id="npessoa_nivelformativo"
                                                            [(ngModel)]="formularioCadastro.cadastroPublico.nivelFormacaoId"
                                                            name="npessoa_nivelformativo" required>
                                                            <option disabled value="null" i18n>Selecione a seu nível
                                                                formativo</option>
                                                            <option *ngFor="let nivel of niveisFormacao"
                                                                [value]="nivel.id">{{nivel.nome}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_missao_atual">Se você participa de uma
                                                            missão shalom, selecione sua missão atual</label>
                                                        <select class="form-control" id="npessoa_missao_atual"
                                                            [(ngModel)]="formularioCadastro.cadastroPublico.missaoAtualId"
                                                            name="npessoa_missao_atual">
                                                            <option [ngValue]="null" i18n>Selecione uma missão</option>
                                                            <option *ngFor="let missao of missoesVinculo"
                                                                [value]="missao.id">{{missao.nome}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_difusao_atual">Se você participa de uma
                                                            difusão shalom, selecione sua difusão atual</label>
                                                        <select class="form-control" id="npessoa_difusao_atual"
                                                            [(ngModel)]="formularioCadastro.cadastroPublico.difusaoAtualId"
                                                            name="npessoa_difusao_atual">
                                                            <option [ngValue]="null" i18n>Selecione uma difusão</option>
                                                            <option *ngFor="let difusao of difusoesVinculo"
                                                                [value]="difusao.id">{{difusao.nome}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12 col-lg-12">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox"
                                                            [(ngModel)]="formularioCadastro.cadastroPublico.aceiteTermo"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label class="form-check-label">Li e concordo com os <a i18n
                                                                data-toggle="modal" data-target="#termo-uso"
                                                                style="cursor: pointer"
                                                                class="text-center text-bold">Termos de uso</a></label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12 col-lg-12"
                                                    *ngIf="termoTratamentoDadosVigente != null">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox"
                                                            [(ngModel)]="formularioCadastro.cadastroPublico.aceiteTermoTratamentoDados"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label class="form-check-label">Li e concordo com os <a i18n
                                                                data-toggle="modal"
                                                                data-target="#termo-tratamento-dados"
                                                                style="cursor: pointer"
                                                                class="text-center text-bold">Termos de tratamento de
                                                                dados</a></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer text-right">
                                            <button type="submit" class="btn btn-pastoreio"
                                                [disabled]="(dadosPessoaisForm.valid==false || formularioCadastro.cadastroPublico.aceiteTermo==false)"
                                                i18n>Cadastrar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="termo-uso">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Termo de Uso</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div [ngxSummernoteView]="termoVigente?.termo"></div>
                </div>
                <div class="modal-footer justify-content-right">
                    <button type="button" class="btn btn-pastoreio" data-dismiss="modal">Fechar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="termo-tratamento-dados">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Termo de Tratamento de Dados</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div [ngxSummernoteView]="termoTratamentoDadosVigente?.termo"></div>
                </div>
                <div class="modal-footer justify-content-right">
                    <button type="button" class="btn btn-pastoreio" data-dismiss="modal">Fechar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal-esqueceu-senha" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Esqueceu a Senha?</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>
                        Digite seu e-mail de cadastro abaixo para recuperar sua senha!<br>
                        <small>Um e-mail será enviado com um link de recuperação!</small><br>
                        <small>Caso não esteja na caixa de entrada do seu e-mail, verifique também sua pasta de Spam ou
                            Lixo Eletronico!</small>
                    </p>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="email">E-mail</label>
                                <input class="form-control" [(ngModel)]="emailRecuperacao" name="email" type="text"
                                    placeholder="E-mail cadastrado">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-pastoreio" data-dismiss="modal">Fechar</button>
                    <button type="button" (click)="recuperarSenha()" class="btn btn-primary">Recuperar senha!</button>
                </div>
            </div>
        </div>
    </div>
</div>