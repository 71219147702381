import * as tslib_1 from "tslib";
import { AbstractBuilder } from './abstract.builder';
import { Trabalho } from '../model/trabalho.model';
var TrabalhoBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(TrabalhoBuilder, _super);
    function TrabalhoBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TrabalhoBuilder.prototype.reset = function () {
        this.entity = new Trabalho(null, null, null, null, null, null);
    };
    return TrabalhoBuilder;
}(AbstractBuilder));
export { TrabalhoBuilder };
