import * as tslib_1 from "tslib";
import { AbstractBuilder } from './abstract.builder';
import { DadoFormandoCA } from '../model/dadoformandoca.model';
var DadoFormandoCABuilder = /** @class */ (function (_super) {
    tslib_1.__extends(DadoFormandoCABuilder, _super);
    function DadoFormandoCABuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DadoFormandoCABuilder.prototype.reset = function () {
        this.entity = new DadoFormandoCA(null, null, null, null, null, null, null, null);
    };
    return DadoFormandoCABuilder;
}(AbstractBuilder));
export { DadoFormandoCABuilder };
