import * as tslib_1 from "tslib";
import { Pais } from '../model/pais.model';
import { AbstractBuilder } from './abstract.builder';
var PaisBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(PaisBuilder, _super);
    function PaisBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PaisBuilder.prototype.reset = function () {
        this.entity = new Pais(null, null, []);
    };
    return PaisBuilder;
}(AbstractBuilder));
export { PaisBuilder };
