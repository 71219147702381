import * as tslib_1 from "tslib";
import { Visto } from '../model/visto.model';
import { AbstractBuilder } from './abstract.builder';
var VistoBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(VistoBuilder, _super);
    function VistoBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    VistoBuilder.prototype.reset = function () {
        this.entity = new Visto(null, null, null, null, null, null, null, null, null);
    };
    return VistoBuilder;
}(AbstractBuilder));
export { VistoBuilder };
