import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CelulaComponent } from './celula.component';
import { CelulaEditComponent } from './celula.edit.component';
import { CelulasComponent } from './celulas.component';
import { GerenciamentoFcCelulaComponent } from './telas-formador-comunitario/gerenciamento.fc.celula.component';
import { GerenciamentoCelulaComponent } from './gerenciamento.celula.component';
import { CelulasCoordenadorColegiadoComponent } from './telas-coordenador-colegiado/celulas.coordenadorcolegiado.component';
import { CelulaManageComponent } from './tela-gerenciar/celula.manage.component';


const celulaRoutes: Routes = [
    {
        path: 'secured/celula',
        component: CelulaComponent
    },
    // {
    //     path: 'secured/celulas',
    //     component: CelulasComponent
    // },
    {
        path: 'secured/cc/celulas',
        component: CelulasCoordenadorColegiadoComponent
    },
    {
        path: 'secured/celula/edit/:id',
        component: CelulaEditComponent
    },
    {
        path: 'secured/celula/quadrante/:id',
        component: GerenciamentoCelulaComponent
    },
    {
        path: 'secured/celula/gerenciamento',
        component: GerenciamentoFcCelulaComponent
    },
    {
        path: 'secured/celula/:id/gerenciar',
        component: CelulaManageComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(celulaRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class CelulaRoutingModule {
    
}