import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
var DadoVocacionalService = /** @class */ (function (_super) {
    tslib_1.__extends(DadoVocacionalService, _super);
    function DadoVocacionalService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType('dadovocacional');
        return _this;
    }
    DadoVocacionalService.prototype.porPessoaId = function (id) {
        return this.http.get(this.apiURL + "/porpessoa/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    return DadoVocacionalService;
}(GenericService));
export { DadoVocacionalService };
