import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var ConfirmacaoCadastroComponent = /** @class */ (function () {
    function ConfirmacaoCadastroComponent(confirmacaoCadastroService, route, router, swtAlert2Service) {
        this.confirmacaoCadastroService = confirmacaoCadastroService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.mensagemConfirmacao = "Aguardando confirmação";
    }
    ConfirmacaoCadastroComponent.prototype.ngOnInit = function () {
        this.token = this.route.snapshot.paramMap.get('token');
        this.confirmacaoCadastro();
    };
    ConfirmacaoCadastroComponent.prototype.confirmacaoCadastro = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.confirmacaoCadastroService.confirmacao(this.token)];
                    case 1:
                        response = _a.sent();
                        this.mensagemConfirmacao = response.message;
                        setTimeout(function () {
                            _this.router.navigate(['../login']);
                        }, 5000);
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        this.mensagemConfirmacao = "Não foi possível confirmar seu cadastro, entre em contato com os responsáveis do sistema.";
                        this.swtAlert2Service.errorAlert(e_1.error.errors);
                        setTimeout(function () {
                            _this.router.navigate(['../login']);
                        }, 10000);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return ConfirmacaoCadastroComponent;
}());
export { ConfirmacaoCadastroComponent };
