import * as tslib_1 from "tslib";
import { GenericService } from "src/app/core/services/generic.service";
import { environment } from "src/environments/environment";
var CaminhoFormativoService = /** @class */ (function (_super) {
    tslib_1.__extends(CaminhoFormativoService, _super);
    function CaminhoFormativoService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType("caminhoformativo");
        return _this;
    }
    CaminhoFormativoService.prototype.porPessoaId = function (id) {
        return this.http
            .get("" + environment.moduloFormacao.buscarCaminhoFormativo + id, {
            headers: this.getHeaders(),
        })
            .toPromise();
    };
    CaminhoFormativoService.prototype.cadastrar = function (request) {
        return this.http
            .post(environment.moduloFormacao.cadastrarCaminhoFormativo, JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    CaminhoFormativoService.prototype.atualizar = function (request) {
        return this.http
            .put(environment.moduloFormacao.atualizarCaminhoFormativo, JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    return CaminhoFormativoService;
}(GenericService));
export { CaminhoFormativoService };
