import { Routes } from "@angular/router";
import { SecuredComponent } from "./secured.component";
var ɵ0 = function () {
    return import("./../component/colegiado/colegiado.module.ngfactory").then(function (module) { return module.ColegiadoModuleNgFactory; });
};
var securedRoutes = [
    {
        path: "secured",
        component: SecuredComponent,
    },
    {
        path: "secured/celula/cc/meucolegiado",
        loadChildren: ɵ0,
    },
];
var SecuredRoutingModule = /** @class */ (function () {
    function SecuredRoutingModule() {
    }
    return SecuredRoutingModule;
}());
export { SecuredRoutingModule };
export { ɵ0 };
