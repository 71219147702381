import * as tslib_1 from "tslib";
import { GenericService } from "src/app/core/services/generic.service";
import { environment } from "src/environments/environment";
var QuestionarioFormandoService = /** @class */ (function (_super) {
    tslib_1.__extends(QuestionarioFormandoService, _super);
    function QuestionarioFormandoService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType("questionarioformando");
        return _this;
    }
    QuestionarioFormandoService.prototype.buscarPorPessoaId = function (id) {
        return this.http
            .get(environment.moduloFormacao.urlModuloFormacao + "questionarioFormando/porpessoaid/" + id, { headers: this.getHeaders() })
            .toPromise();
    };
    QuestionarioFormandoService.prototype.buscarPorId = function (id) {
        return this.http
            .get(environment.moduloFormacao.urlModuloFormacao + "questionarioFormando/" + id, { headers: this.getHeaders() })
            .toPromise();
    };
    QuestionarioFormandoService.prototype.cadastrar = function (request) {
        return this.http
            .post(environment.moduloFormacao.urlModuloFormacao + "questionarioFormando", JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    QuestionarioFormandoService.prototype.atualizar = function (id, request) {
        return this.http
            .put(environment.moduloFormacao.urlModuloFormacao + "questionarioFormando/" + id, JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    return QuestionarioFormandoService;
}(GenericService));
export { QuestionarioFormandoService };
