import * as tslib_1 from "tslib";
import { HistoricoMissionarioMissaoApostolado } from '../model/historicomissionariomissaoapostolado.model';
import { AbstractBuilder } from './abstract.builder';
var HistoricoMissionarioMissaoApostoladoBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(HistoricoMissionarioMissaoApostoladoBuilder, _super);
    function HistoricoMissionarioMissaoApostoladoBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HistoricoMissionarioMissaoApostoladoBuilder.prototype.reset = function () {
        this.entity = new HistoricoMissionarioMissaoApostolado(null, null, null, null, 0, 0, false, null, false);
    };
    return HistoricoMissionarioMissaoApostoladoBuilder;
}(AbstractBuilder));
export { HistoricoMissionarioMissaoApostoladoBuilder };
