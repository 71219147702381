import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
var QuestionariosComponent = /** @class */ (function () {
    function QuestionariosComponent(missaoConfiguracaoService, pessoaService, elementoGrupoDominioService, elementoGrupoDominioLocalService, pessoaBuilder, questionarioFormandoBuilder, dadoVocacionalService, questionarioFormandoService, swtAlert2Service, usuarioService, route, router) {
        this.missaoConfiguracaoService = missaoConfiguracaoService;
        this.pessoaService = pessoaService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.pessoaBuilder = pessoaBuilder;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.dadoVocacionalService = dadoVocacionalService;
        this.questionarioFormandoService = questionarioFormandoService;
        this.swtAlert2Service = swtAlert2Service;
        this.usuarioService = usuarioService;
        this.route = route;
        this.router = router;
        this.niveisFormacao = new Array();
        this.formasVida = new Array();
        this.pessoa = this.pessoaBuilder.getInstance();
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.nivelFormacao = "";
        this.formaVida = "";
        this.verificouDados = false;
        this.temQuestionarioVinculado = false;
    }
    QuestionariosComponent.prototype.ngOnInit = function () {
        this.initialization();
    };
    QuestionariosComponent.prototype.initialization = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUsuarioAtual()];
                    case 1:
                        _a.sent();
                        this.carregarNiveisFormacao();
                        return [4 /*yield*/, this.carregarPessoa()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.carregarDadoVocacional()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.carregarQuestionarios()];
                    case 4:
                        _a.sent();
                        this.carregarQuestionarioConfiguracao();
                        return [2 /*return*/];
                }
            });
        });
    };
    QuestionariosComponent.prototype.carregarQuestionarioConfiguracao = function () {
        var _this = this;
        var missaoId = this.pessoa.vinculoMissao.missaoAtualId;
        this.missaoConfiguracaoService
            .obterConfiguracaoQuestionarioPorMissaoEQuestionario(missaoId, 3
        // this.questionarioFormando.questionarioId
        )
            .subscribe(function (questionarioConfiguracao) {
            return (_this.questionarioConfiguracao = questionarioConfiguracao);
        });
    };
    QuestionariosComponent.prototype.getUsuarioAtual = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuarioAtual = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionariosComponent.prototype.carregarNiveisFormacao = function () {
        var _this = this;
        var busca = {
            grupoNome: "NIVEL_FORMACAO",
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(busca)
            .then(function (lista) {
            _this.niveisFormacao = lista.entity;
        })
            .catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    QuestionariosComponent.prototype.carregarDadoVocacional = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.dadoVocacionalService.porPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response.entity != undefined && response.entity != null) {
                            this.dadoVocacional = response.entity;
                            this.nivelFormacao = this.dadoVocacional.nivelFormacaoNome;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionariosComponent.prototype.carregarQuestionarios = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.questionarioFormandoService.buscarPorPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response != undefined && response != null) {
                            this.questionarioFormando = response;
                            if (this.questionarioFormando.questionarioId == 3)
                                this.temQuestionarioVinculado = true;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionariosComponent.prototype.cadastrarVinculoQuestionarioCAL = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.questionarioFormando.anoFormativoId = 2;
                        this.questionarioFormando.questionarioId = 3;
                        this.questionarioFormando.pessoaId = this.pessoa.id;
                        return [4 /*yield*/, this.questionarioFormandoService.cadastrar(this.questionarioFormando)];
                    case 1:
                        response = _a.sent();
                        if (response != undefined && response != null) {
                            this.questionarioFormando = response;
                            this.router.navigate([
                                "/secured/questionariocal-answer/" + this.questionarioFormando.id,
                            ]);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionariosComponent.prototype.cadastrarVinculoQuestionarioD2 = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.questionarioFormando.anoFormativoId = 2;
                        this.questionarioFormando.questionarioId = 1;
                        this.questionarioFormando.pessoaId = this.pessoa.id;
                        return [4 /*yield*/, this.questionarioFormandoService.cadastrar(this.questionarioFormando)];
                    case 1:
                        response = _a.sent();
                        if (response != undefined && response != null) {
                            this.questionarioFormando = response;
                            this.router.navigate([
                                "/secured/questionariod2-answer/" + this.questionarioFormando.id,
                            ]);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionariosComponent.prototype.carregarPessoa = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.pessoaService.buscaPorUsuarioId(this.usuarioAtual.id)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        this.pessoaId = this.pessoa.id;
                        if (this.pessoa.formaVidaId != null) {
                            this.setarFormaVidaSelecionada();
                            this.formaVida = this.pessoa.formaVidaValor;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_5.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionariosComponent.prototype.setarFormaVidaSelecionada = function () {
        for (var i = 0; i < this.formasVida.length; i++) {
            if (this.formasVida[i].id == this.pessoa.formaVidaId) {
                this.formaVidaSelecionada = this.formasVida[i];
            }
        }
    };
    return QuestionariosComponent;
}());
export { QuestionariosComponent };
