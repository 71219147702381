import * as tslib_1 from "tslib";
import { CadastroPublico } from "../model/cadastro.publico.model";
import { AbstractBuilder } from "./abstract.builder";
var CadastroPublicoBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(CadastroPublicoBuilder, _super);
    function CadastroPublicoBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CadastroPublicoBuilder.prototype.reset = function () {
        this.entity = new CadastroPublico(null, null, null, null, null, null, null, null, null, null, null, null, false, false, null, null, null, null);
    };
    return CadastroPublicoBuilder;
}(AbstractBuilder));
export { CadastroPublicoBuilder };
