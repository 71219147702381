import * as tslib_1 from "tslib";
import { AbstractBuilder } from './abstract.builder';
import { Escolaridade } from '../model/escolaridade.model';
var EscolaridadeBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(EscolaridadeBuilder, _super);
    function EscolaridadeBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EscolaridadeBuilder.prototype.reset = function () {
        this.entity = new Escolaridade(null, null, null, null, null, null);
    };
    return EscolaridadeBuilder;
}(AbstractBuilder));
export { EscolaridadeBuilder };
