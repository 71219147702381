import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var SaudeEditComponent = /** @class */ (function () {
    function SaudeEditComponent(usuarioService, pessoaService, saudeService, pessoaBuilder, saudeBuilder, swtAlert2Service, route, router) {
        this.usuarioService = usuarioService;
        this.pessoaService = pessoaService;
        this.saudeService = saudeService;
        this.pessoaBuilder = pessoaBuilder;
        this.saudeBuilder = saudeBuilder;
        this.swtAlert2Service = swtAlert2Service;
        this.route = route;
        this.router = router;
        this.pessoa = this.pessoaBuilder.getInstance();
        this.saude = this.saudeBuilder.getInstance();
    }
    SaudeEditComponent.prototype.ngOnInit = function () {
        this.initialization();
    };
    SaudeEditComponent.prototype.initialization = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUsuarioAtual()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.carregarPessoa()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.carregarSaude()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SaudeEditComponent.prototype.getUsuarioAtual = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuarioAtual = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SaudeEditComponent.prototype.carregarPessoa = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.pessoaService.buscaPorUsuarioId(this.usuarioAtual.id)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SaudeEditComponent.prototype.carregarSaude = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.saudeService.porPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        if (response.entity != undefined && response.entity != null) {
                            this.saude = response.entity;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SaudeEditComponent.prototype.atualizarSaude = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!(this.saude.id == null)) return [3 /*break*/, 2];
                        this.saude.pessoaId = this.pessoa.id;
                        return [4 /*yield*/, this.saudeService.create(this.saude)];
                    case 1:
                        response = _a.sent();
                        this.saude = response.entity;
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.saudeService.update(this.saude.id, this.saude)];
                    case 3:
                        response = _a.sent();
                        this.saude = response.entity;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    return SaudeEditComponent;
}());
export { SaudeEditComponent };
