import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { MembroComunidadeBuilder } from "src/app/builder/membro.comunidade.builder";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
var ParecerCelulaComponent = /** @class */ (function () {
    function ParecerCelulaComponent(missaoConfiguracaoService, usuarioService, imagemService, pessoaService, celulaService, membroService, formadorComunitarioService, questionarioFormandoBuilder, questionarioFormandoService, parecerResponsavelBuilder, parecerResponsavelService, route, router, swtAlert2Service) {
        this.missaoConfiguracaoService = missaoConfiguracaoService;
        this.usuarioService = usuarioService;
        this.imagemService = imagemService;
        this.pessoaService = pessoaService;
        this.celulaService = celulaService;
        this.membroService = membroService;
        this.formadorComunitarioService = formadorComunitarioService;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelBuilder = parecerResponsavelBuilder;
        this.parecerResponsavelService = parecerResponsavelService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.totalDePaginas = 1;
        this.pessoaBuilder = new PessoaBuilder();
        this.membrosCelula = new Array();
        this.membroComunidadeBuilder = new MembroComunidadeBuilder();
        this.membroComunidade = this.membroComunidadeBuilder.getInstance();
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
    }
    ParecerCelulaComponent.prototype.ngOnInit = function () {
        this.initializer();
        this.hasCelulaAssociada = false;
        this.buscaMembros = {
            celulaId: 0,
            pageNumber: 0,
            quantityOfElements: 0,
        };
    };
    ParecerCelulaComponent.prototype.initializer = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.carregarUsuario()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.carregarPessoa()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.carregarFormadorComunitario()];
                    case 3:
                        _a.sent();
                        if (!(this.formadorComunitario != undefined &&
                            this.formadorComunitario.casaCelulaId != null)) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.carregarCelula()];
                    case 4:
                        _a.sent();
                        this.buscaMembros = {
                            celulaId: this.celula.id,
                            pageNumber: 0,
                            quantityOfElements: 4,
                        };
                        return [4 /*yield*/, this.carregarMembrosCelula()];
                    case 5:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        this.hasCelulaAssociada = false;
                        _a.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaComponent.prototype.carregarQuestionarioConfiguracao = function (questionarioId) {
        var _this = this;
        var missaoId = this.pessoa.vinculoMissao.missaoAtualId;
        this.missaoConfiguracaoService
            .obterConfiguracaoQuestionarioPorMissaoEQuestionario(missaoId, questionarioId)
            .subscribe(function (questionarioConfiguracao) {
            return (_this.questionarioConfiguracao = questionarioConfiguracao);
        });
    };
    ParecerCelulaComponent.prototype.carregarUsuario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuario = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaComponent.prototype.carregarPessoa = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.pessoaService.buscaPorUsuarioId(this.usuario.id)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.log(e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaComponent.prototype.carregarFormadorComunitario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.formadorComunitarioService.buscarCelulaPorPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        this.formadorComunitario = response;
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        console.log(e_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaComponent.prototype.carregarCelula = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.celulaService.find(this.formadorComunitario.casaCelulaId)];
                    case 1:
                        response = _a.sent();
                        this.celula = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_4 = _a.sent();
                        console.log(e_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaComponent.prototype.carregarMembrosCelula = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, i, blogImage, questionarioFormando, parecerResponsavelAtual, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 10, , 11]);
                        return [4 /*yield*/, this.membroService.listarMembrosCelula(this.celula.id)];
                    case 1:
                        response = _a.sent();
                        this.membrosCelula = response;
                        this.totalDePaginasMembros = 1;
                        //this.membrosCelula = response.content;
                        //this.totalDePaginasMembros = response.totalPages;
                        if (this.membrosCelula.length > 0) {
                            this.membrosCelula = this.membrosCelula.sort(function (a, b) {
                                return a.pessoaNome.localeCompare(b.pessoaNome);
                            });
                        }
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.membrosCelula.length)) return [3 /*break*/, 9];
                        if (!(this.membrosCelula[i].pessoaImagemId != null)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getPessoaImagem(this.membrosCelula[i].pessoaImagemId)];
                    case 3:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.membrosCelula[i]);
                        _a.label = 4;
                    case 4: return [4 /*yield*/, this.carregarQuestionario(this.membrosCelula[i].pessoaId)];
                    case 5:
                        questionarioFormando = _a.sent();
                        if (!(questionarioFormando != null)) return [3 /*break*/, 7];
                        if (!this.questionarioConfiguracao) {
                            this.carregarQuestionarioConfiguracao(questionarioFormando.questionarioId);
                        }
                        this.membrosCelula[i].statusQuestionario =
                            questionarioFormando.status;
                        this.membrosCelula[i].idQuestionario = questionarioFormando.id;
                        return [4 /*yield*/, this.carregarParecer(questionarioFormando.id)];
                    case 6:
                        parecerResponsavelAtual = _a.sent();
                        if (parecerResponsavelAtual != null) {
                            this.membrosCelula[i].statusParecer =
                                parecerResponsavelAtual.status;
                            this.membrosCelula[i].idParecer = parecerResponsavelAtual.id;
                        }
                        else {
                            this.membrosCelula[i].statusParecer = "Não iniciado";
                            this.membrosCelula[i].idParecer = null;
                        }
                        _a.label = 7;
                    case 7:
                        if (questionarioFormando == null) {
                            this.membrosCelula[i].statusQuestionario = "Não iniciado";
                            this.membrosCelula[i].idQuestionario = null;
                        }
                        console.log(this.membrosCelula);
                        _a.label = 8;
                    case 8:
                        i++;
                        return [3 /*break*/, 2];
                    case 9: return [3 /*break*/, 11];
                    case 10:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 11];
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaComponent.prototype.carregarQuestionario = function (pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.questionarioFormandoService.buscarPorPessoaId(pessoaId)];
                    case 1:
                        response = _a.sent();
                        if (response != undefined && response != null) {
                            return [2 /*return*/, response];
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaComponent.prototype.carregarParecer = function (idQuestionario) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.parecerResponsavelService.buscarPorQuestionarioFormandoId(idQuestionario)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                    case 2:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaComponent.prototype.cadastrarVinculoQuestionarioCAL = function (pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var responsePorPessoa, response, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, this.questionarioFormandoService.buscarPorPessoaId(pessoaId)];
                    case 1:
                        responsePorPessoa = _a.sent();
                        if (!(responsePorPessoa != undefined &&
                            responsePorPessoa != null &&
                            responsePorPessoa.questionarioId != 3)) return [3 /*break*/, 2];
                        this.questionarioFormando = responsePorPessoa;
                        this.cadastrarVinculoParecerCAL(this.questionarioFormando.id);
                        return [3 /*break*/, 4];
                    case 2:
                        this.questionarioFormando.anoFormativoId = 2;
                        this.questionarioFormando.questionarioId = 3;
                        this.questionarioFormando.pessoaId = pessoaId;
                        return [4 /*yield*/, this.questionarioFormandoService.cadastrar(this.questionarioFormando)];
                    case 3:
                        response = _a.sent();
                        if (response != undefined && response != null) {
                            this.questionarioFormando = response;
                            this.cadastrarVinculoParecerCAL(this.questionarioFormando.id);
                        }
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaComponent.prototype.cadastrarVinculoParecerCAL = function (questionarioFormandoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.parecerResponsavel.questionarioFormandoId = questionarioFormandoId;
                        this.parecerResponsavel.parecer_id = 2;
                        this.parecerResponsavel.pessoaResponsavelId = this.pessoa.id;
                        return [4 /*yield*/, this.parecerResponsavelService.cadastrar(this.parecerResponsavel)];
                    case 1:
                        response = _a.sent();
                        if (response != undefined && response != null) {
                            this.parecerResponsavel = response;
                            this.router.navigate([
                                "/secured/parecer-cal-answer/" + this.parecerResponsavel.id,
                            ]);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_5.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_6 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_6.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaComponent.prototype.consultarVinculoQuestionarioCAL = function (formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!(formando.idQuestionario != null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.parecerResponsavelService.buscarPorQuestionarioFormandoId(formando.idQuestionario)];
                    case 1:
                        response = _a.sent();
                        if (response != undefined && response != null) {
                            this.parecerResponsavel = response;
                            // this.router.navigate(['/secured/parecer-cal-answer/' + this.parecerResponsavel.id]);
                            this.router.navigate([
                                "/secured/parecer-cal-answer/" + this.parecerResponsavel.id,
                            ]);
                        }
                        else {
                            this.cadastrarVinculoParecerCAL(formando.idQuestionario);
                        }
                        _a.label = 2;
                    case 2:
                        if (formando.idQuestionario == null) {
                            this.cadastrarVinculoQuestionarioCAL(formando.pessoaId);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_7 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_7.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaComponent.prototype.cliqueNoBotaoListener = function (botao) {
        this.buscaMembros.pageNumber = botao.numero - 1;
        this.carregarMembrosCelula();
    };
    return ParecerCelulaComponent;
}());
export { ParecerCelulaComponent };
