import * as tslib_1 from "tslib";
import { Relacionamento } from "../model/relacionamento.model";
import { AbstractBuilder } from "./abstract.builder";
var RelacionamentoBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(RelacionamentoBuilder, _super);
    function RelacionamentoBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RelacionamentoBuilder.prototype.reset = function () {
        this.entity = new Relacionamento(null, null, null, null, null, true, null, null, null, null, null, null, null);
    };
    return RelacionamentoBuilder;
}(AbstractBuilder));
export { RelacionamentoBuilder };
