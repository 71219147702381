import * as tslib_1 from "tslib";
import { ElementRef, Renderer2, OnInit, AfterViewInit } from '@angular/core';
import { UsuarioBuilder } from 'src/app/builder/usuario.builder';
var SecuredComponent = /** @class */ (function () {
    function SecuredComponent(loginService, usuarioService, routerManagerService, appRouteChangeService, //necessário para rodar as mudanças de rota na aplicação
    aceiteTermoService, aceiteTermoTratamentoDadosService, cadastroPublicoService, swtAlert2Service, router, renderer) {
        this.loginService = loginService;
        this.usuarioService = usuarioService;
        this.routerManagerService = routerManagerService;
        this.appRouteChangeService = appRouteChangeService;
        this.aceiteTermoService = aceiteTermoService;
        this.aceiteTermoTratamentoDadosService = aceiteTermoTratamentoDadosService;
        this.cadastroPublicoService = cadastroPublicoService;
        this.swtAlert2Service = swtAlert2Service;
        this.router = router;
        this.renderer = renderer;
        this.usuarioBuilder = new UsuarioBuilder();
        this.usuarioAtual = this.usuarioBuilder.getInstance();
    }
    SecuredComponent.prototype.ngOnInit = function () {
        this.initialization();
    };
    SecuredComponent.prototype.ngAfterViewInit = function () {
        this.initializationAfterRender();
    };
    SecuredComponent.prototype.initialization = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loadTermsInfo();
                        if (!(this.hasTermoUsoVigente == undefined)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.carregarTermoUsoVigente()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!(this.hasTermoTratamentoDadosVigente == undefined)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.carregarTermoTratamentoDadosVigente()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        if (!(this.hasTermoUsoVigente && this.usuarioAceitouTermoUso != true)) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.didUserAcceptUserTerm()];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6:
                        if (!(this.hasTermoTratamentoDadosVigente && this.usuarioAceitouTermoTratamentoDados != true)) return [3 /*break*/, 8];
                        return [4 /*yield*/, this.didUserAcceptDataProcessingTerm()];
                    case 7:
                        _a.sent();
                        _a.label = 8;
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    SecuredComponent.prototype.initializationAfterRender = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUsuarioAtual()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.verificarUsuario()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.liberarMenuPorAutorizacao()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SecuredComponent.prototype.liberarMenuPorAutorizacao = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var autorizacaoUsuarioLogado;
            return tslib_1.__generator(this, function (_a) {
                autorizacaoUsuarioLogado = this.usuarioAtual.autorizacoesNome.length > 0 ? this.usuarioAtual.autorizacoesNome[0] : undefined;
                switch (autorizacaoUsuarioLogado) {
                    case 'ROLE_MISSIONARIO':
                        {
                            this.renderer.removeChild(this.menuCoordenadorColegiadoRef.nativeElement.parentNode, this.menuCoordenadorColegiadoRef.nativeElement);
                            this.renderer.removeChild(this.menuFormadorComunitarioRef.nativeElement.parentNode, this.menuFormadorComunitarioRef.nativeElement);
                            this.renderer.removeChild(this.menuAcessoNegadoRef.nativeElement.parentNode, this.menuAcessoNegadoRef.nativeElement);
                            break;
                        }
                    case 'ROLE_FORMADOR_COMUNITARIO':
                        {
                            this.renderer.removeChild(this.menuCoordenadorColegiadoRef.nativeElement.parentNode, this.menuCoordenadorColegiadoRef.nativeElement);
                            this.renderer.removeChild(this.menuMissionarioRef.nativeElement.parentNode, this.menuMissionarioRef.nativeElement);
                            this.renderer.removeChild(this.menuAcessoNegadoRef.nativeElement.parentNode, this.menuAcessoNegadoRef.nativeElement);
                            break;
                        }
                    case 'ROLE_COORDENADOR_COLEGIADO':
                        {
                            this.renderer.removeChild(this.menuFormadorComunitarioRef.nativeElement.parentNode, this.menuFormadorComunitarioRef.nativeElement);
                            this.renderer.removeChild(this.menuMissionarioRef.nativeElement.parentNode, this.menuMissionarioRef.nativeElement);
                            this.renderer.removeChild(this.menuAcessoNegadoRef.nativeElement.parentNode, this.menuAcessoNegadoRef.nativeElement);
                            break;
                        }
                    case 'ROLE_ADMIN':
                        {
                            this.renderer.removeChild(this.menuFormadorComunitarioRef.nativeElement.parentNode, this.menuFormadorComunitarioRef.nativeElement);
                            this.renderer.removeChild(this.menuMissionarioRef.nativeElement.parentNode, this.menuMissionarioRef.nativeElement);
                            this.renderer.removeChild(this.menuAcessoNegadoRef.nativeElement.parentNode, this.menuAcessoNegadoRef.nativeElement);
                            break;
                        }
                    default:
                        {
                            this.renderer.removeChild(this.menuCoordenadorColegiadoRef.nativeElement.parentNode, this.menuCoordenadorColegiadoRef.nativeElement);
                            this.renderer.removeChild(this.menuFormadorComunitarioRef.nativeElement.parentNode, this.menuFormadorComunitarioRef.nativeElement);
                            this.renderer.removeChild(this.menuMissionarioRef.nativeElement.parentNode, this.menuMissionarioRef.nativeElement);
                        }
                }
                return [2 /*return*/];
            });
        });
    };
    SecuredComponent.prototype.carregarTermoUsoVigente = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.cadastroPublicoService.getTermoVigente()];
                    case 1:
                        response = _a.sent();
                        this.termoUsoVigente = response.entity;
                        this.hasTermoUsoVigente = this.termoUsoVigente ? true : false;
                        sessionStorage.setItem("hasTermoUsoVigente", this.hasTermoUsoVigente ? "1" : "0");
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_1.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SecuredComponent.prototype.carregarTermoTratamentoDadosVigente = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.cadastroPublicoService.getTermoTratamentoDadosVigente()];
                    case 1:
                        response = _a.sent();
                        this.termoTratamentoDadosVigente = response.entity;
                        this.hasTermoTratamentoDadosVigente = this.termoTratamentoDadosVigente ? true : false;
                        sessionStorage.setItem("hasTermoTratamentoDadosVigente", this.hasTermoTratamentoDadosVigente ? "1" : "0");
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SecuredComponent.prototype.getUsuarioAtual = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response2, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        console.log("secured - getUsuarioAtual()");
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response2 = _a.sent();
                        this.usuarioAtual = response2.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        this.swtAlert2Service.errorAlert("Não foi possível realizar o login. O sistema pode estar em manutenção, caso o problema persista tente realizar o login novamente mais tarde!");
                        this.router.navigate(['/login']);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SecuredComponent.prototype.didUserAcceptUserTerm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.aceiteTermoService.usuarioAceitouTermo()];
                    case 1:
                        response = _a.sent();
                        this.usuarioAceitouTermoUso = response.entity;
                        sessionStorage.setItem("usuarioAceitouTermoUso", this.usuarioAceitouTermoUso ? "1" : "0");
                        if (!this.usuarioAceitouTermoUso) {
                            this.router.navigate(['/aceitetermouso']);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_4.error.errors);
                        this.router.navigate(['/login']);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SecuredComponent.prototype.didUserAcceptDataProcessingTerm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.aceiteTermoTratamentoDadosService.usuarioAceitouTermo()];
                    case 1:
                        response = _a.sent();
                        this.usuarioAceitouTermoTratamentoDados = response.entity;
                        sessionStorage.setItem("usuarioAceitouTermoTratamentoDados", this.usuarioAceitouTermoTratamentoDados ? "1" : "0");
                        if (!this.usuarioAceitouTermoTratamentoDados) {
                            this.router.navigate(['/aceitetermotratamentodados']);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_5.error.errors);
                        this.router.navigate(['/login']);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SecuredComponent.prototype.verificarUsuario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.routerManagerService.verificarUsuario(this.usuarioAtual)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SecuredComponent.prototype.loadTermsInfo = function () {
        if (sessionStorage.getItem("usuarioAceitouTermoUso") != null)
            this.usuarioAceitouTermoUso = sessionStorage.getItem("usuarioAceitouTermoUso").localeCompare("1") == 0 ? true : false;
        if (sessionStorage.getItem("usuarioAceitouTermoTratamentoDados") != null)
            this.usuarioAceitouTermoTratamentoDados = sessionStorage.getItem("usuarioAceitouTermoTratamentoDados").localeCompare("1") == 0 ? true : false;
        if (sessionStorage.getItem("hasTermoUsoVigente") != null)
            this.hasTermoUsoVigente = sessionStorage.getItem("hasTermoUsoVigente").localeCompare("1") == 0 ? true : false;
        if (sessionStorage.getItem("hasTermoTratamentoDadosVigente") != null)
            this.hasTermoTratamentoDadosVigente = sessionStorage.getItem("hasTermoTratamentoDadosVigente").localeCompare("1") == 0 ? true : false;
    };
    return SecuredComponent;
}());
export { SecuredComponent };
