import * as tslib_1 from "tslib";
import { GenericService } from "src/app/core/services/generic.service";
import { environment } from "src/environments/environment";
var ElementoGrupoDominioLocalService = /** @class */ (function (_super) {
    tslib_1.__extends(ElementoGrupoDominioLocalService, _super);
    function ElementoGrupoDominioLocalService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType("elementogrupodominio");
        return _this;
    }
    ElementoGrupoDominioLocalService.prototype.buscarElementosGrupoDominio = function (busca) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(this.apiURL + "/buscar", JSON.stringify(busca), {
                        headers: this.getHeaders(),
                    })
                        .toPromise()];
            });
        });
    };
    ElementoGrupoDominioLocalService.prototype.buscarPorGrupoDominioNome = function (busca) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(this.apiURL + "/pornomegrupo", JSON.stringify(busca), {
                        headers: this.getHeaders(),
                    })
                        .toPromise()];
            });
        });
    };
    ElementoGrupoDominioLocalService.prototype.buscarPorGrupoDominioNomeModuloFormacao = function (nome) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url;
            return tslib_1.__generator(this, function (_a) {
                url = environment.moduloFormacao.urlModuloFormacao + "secured/elementogrupodominio/pornomegrupo?nome=" + nome;
                return [2 /*return*/, this.http.get(url, { headers: this.getHeaders() }).toPromise()];
            });
        });
    };
    return ElementoGrupoDominioLocalService;
}(GenericService));
export { ElementoGrupoDominioLocalService };
