import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var MissionarioMissaoAtualComponent = /** @class */ (function () {
    function MissionarioMissaoAtualComponent(missaoService, casaComunitariaService, apostoladoService, elementoGrupoDominioService, missionarioMissaoAtualService, salvarMissionarioMissaoAtualBuilder, dadosDetalhementoMissionarioMissaoAtualBuilder, missionarioMissaoApostoladoAtualBuilder, swtAlert2Service) {
        this.missaoService = missaoService;
        this.casaComunitariaService = casaComunitariaService;
        this.apostoladoService = apostoladoService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.missionarioMissaoAtualService = missionarioMissaoAtualService;
        this.salvarMissionarioMissaoAtualBuilder = salvarMissionarioMissaoAtualBuilder;
        this.dadosDetalhementoMissionarioMissaoAtualBuilder = dadosDetalhementoMissionarioMissaoAtualBuilder;
        this.missionarioMissaoApostoladoAtualBuilder = missionarioMissaoApostoladoAtualBuilder;
        this.swtAlert2Service = swtAlert2Service;
        this.tipoApostoladoSelecionadoId = null;
        this.salvarMissionarioMissaoAtual = this.salvarMissionarioMissaoAtualBuilder.getInstance();
        this.dadosDetalhamentoMissionarioMissaoAtual = this.dadosDetalhementoMissionarioMissaoAtualBuilder.getInstance();
        this.salvarMissionarioMissaoApostoladoAtual = this.missionarioMissaoApostoladoAtualBuilder.getInstance();
        this.listaDetalhamentoMissionarioMissaoApostoladoAtual = new Array();
        this.apostolados = new Array();
    }
    MissionarioMissaoAtualComponent.prototype.ngOnInit = function () {
        this.initialization();
    };
    MissionarioMissaoAtualComponent.prototype.initialization = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.carregarMissoes();
                this.carregarApostolados();
                this.carregarTipoApostolado();
                this.carregarDetalhamentoDoUsuarioLogado();
                return [2 /*return*/];
            });
        });
    };
    MissionarioMissaoAtualComponent.prototype.carregarMissoes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.missaoService.getAllAnyStatus()];
                    case 1:
                        response = _a.sent();
                        this.missoes = response.entity;
                        this.missoes = this.missoes.sort(function (a, b) { return a.nome.localeCompare(b.nome, 'fi'); });
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MissionarioMissaoAtualComponent.prototype.carregarCasasComunitarias = function (missaoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.casaComunitariaService.porMissao(missaoId)];
                    case 1:
                        response = _a.sent();
                        this.casasComunitarias = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MissionarioMissaoAtualComponent.prototype.salvar = function (salvarMissionarioMissaoAtual) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.salvarMissionarioMissaoAtual.pessoaId = this.pessoaId;
                        return [4 /*yield*/, this.missionarioMissaoAtualService.create(salvarMissionarioMissaoAtual)];
                    case 1:
                        response = _a.sent();
                        this.dadosDetalhamentoMissionarioMissaoAtual = response;
                        this.swtAlert2Service.successAlert("Missionário vinculado a missão informada com sucesso!");
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_1.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MissionarioMissaoAtualComponent.prototype.atualizar = function (atualizarMissionarioMissaoAtual) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.missionarioMissaoAtualService.update(atualizarMissionarioMissaoAtual.id, atualizarMissionarioMissaoAtual)];
                    case 1:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert("Atualização realizada com sucesso!");
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MissionarioMissaoAtualComponent.prototype.salvarApostolado = function (salvarMissionarioMissaoApostoladoAtual) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, missionarioMissaoAtualId, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.missionarioMissaoAtualService.salvarApostolado(salvarMissionarioMissaoApostoladoAtual)];
                    case 1:
                        response = _a.sent();
                        missionarioMissaoAtualId = this.salvarMissionarioMissaoApostoladoAtual.missionarioMissaoAtualId;
                        this.salvarMissionarioMissaoApostoladoAtual = this.missionarioMissaoApostoladoAtualBuilder.getInstance();
                        this.configModalApostoladoAtual(missionarioMissaoAtualId);
                        this.swtAlert2Service.successAlert("Apostolado salvo com sucesso!");
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MissionarioMissaoAtualComponent.prototype.atualizarApostolado = function (atualizarMissionarioMissaoApostoladoAtual) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.missionarioMissaoAtualService.atualizarApostolado(atualizarMissionarioMissaoApostoladoAtual)];
                    case 1:
                        response = _a.sent();
                        this.carregarMissionarioMissaoApostolado(this.dadosDetalhamentoMissionarioMissaoAtual.id);
                        this.swtAlert2Service.successAlert("Apostolado atualizado com sucesso!");
                        return [3 /*break*/, 3];
                    case 2:
                        e_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MissionarioMissaoAtualComponent.prototype.deletarMissionarioMissaoApostoladoAtual = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!confirm("Deseja deletar esse registro de apostolado?")) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.missionarioMissaoAtualService.deletarApostolado(id)];
                    case 2:
                        response = _a.sent();
                        this.carregarMissionarioMissaoApostolado(this.dadosDetalhamentoMissionarioMissaoAtual.id);
                        this.swtAlert2Service.successAlert("Apostolado deletado com sucesso!");
                        return [3 /*break*/, 4];
                    case 3:
                        e_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_5.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MissionarioMissaoAtualComponent.prototype.carregarDetalhamentoDoUsuarioLogado = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.missionarioMissaoAtualService.detalhamentoPorUsuarioLogado()];
                    case 1:
                        response = _a.sent();
                        this.dadosDetalhamentoMissionarioMissaoAtual = response;
                        return [3 /*break*/, 3];
                    case 2:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MissionarioMissaoAtualComponent.prototype.configModalApostoladoAtual = function (missionarioMissaoAtualId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.salvarMissionarioMissaoApostoladoAtual.missionarioMissaoAtualId = missionarioMissaoAtualId;
                this.carregarMissionarioMissaoApostolado(missionarioMissaoAtualId);
                return [2 /*return*/];
            });
        });
    };
    MissionarioMissaoAtualComponent.prototype.carregarMissionarioMissaoApostolado = function (missionarioMissaoAtualId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.missionarioMissaoAtualService.listarApostoladosPorMissionarioMissaoAtualId(missionarioMissaoAtualId)];
                    case 1:
                        response = _a.sent();
                        if (response != undefined && response != null) {
                            this.listaDetalhamentoMissionarioMissaoApostoladoAtual = response;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MissionarioMissaoAtualComponent.prototype.carregarTipoApostolado = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = {
                            grupoNome: 'TIPO_APOSTOLADO'
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request)];
                    case 2:
                        response = _a.sent();
                        this.tiposApostolado = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        err_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_5.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MissionarioMissaoAtualComponent.prototype.carregarApostolados = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = {
                            ativo: null
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apostoladoService.pesquisar(request)];
                    case 2:
                        response = _a.sent();
                        this.apostolados = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        err_6 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_6.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MissionarioMissaoAtualComponent.prototype.carregarApostoladosPorTipo = function (tipoApostoladoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.apostoladosSelecionados = this.apostolados.filter(function (a) { return a.tipoId == tipoApostoladoId; });
                return [2 /*return*/];
            });
        });
    };
    return MissionarioMissaoAtualComponent;
}());
export { MissionarioMissaoAtualComponent };
