import * as tslib_1 from "tslib";
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
var GenericService = /** @class */ (function () {
    function GenericService(http, router) {
        this.http = http;
        this.router = router;
        this.apiURL = environment.configServidor.apiUrl;
        this.tokenURL = environment.configServidor.tokenUrl;
        this.token = JSON.parse(localStorage.getItem("token"));
        this.tokenHeaders = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'Authorization': environment.authorization
        });
        this.getHeaders();
    }
    GenericService.prototype.setEntityType = function (entityType) {
        this.apiURL += entityType;
    };
    GenericService.prototype.create = function (entity) {
        return this.http.post(this.apiURL, entity, { 'headers': this.getHeaders() }).toPromise();
    };
    GenericService.prototype.createAsync = function (entity) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.post(this.apiURL, entity, { 'headers': this.getHeaders() }).toPromise()];
            });
        });
    };
    GenericService.prototype.update = function (id, entity) {
        return this.http.put(this.apiURL + '/' + id, entity, { 'headers': this.getHeaders() }).toPromise();
    };
    GenericService.prototype.updateAsync = function (id, entity) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.put(this.apiURL + '/' + id, entity, { 'headers': this.getHeaders() }).toPromise()];
            });
        });
    };
    GenericService.prototype.delete = function (id) {
        return this.http.delete(this.apiURL + '/' + id, { 'headers': this.getHeaders() }).toPromise();
    };
    GenericService.prototype.deleteAsync = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.delete(this.apiURL + '/' + id, { 'headers': this.getHeaders() }).toPromise()];
            });
        });
    };
    GenericService.prototype.find = function (id) {
        return this.http.get(this.apiURL + '/' + id, { 'headers': this.getHeaders() }).toPromise();
    };
    GenericService.prototype.findAsync = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(this.apiURL + '/' + id, { 'headers': this.getHeaders() }).toPromise()];
            });
        });
    };
    GenericService.prototype.findAll = function () {
        return this.http.get(this.apiURL, { 'headers': this.getHeaders() }).toPromise();
    };
    GenericService.prototype.findAllAsync = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(this.apiURL, { 'headers': this.getHeaders() }).toPromise()];
            });
        });
    };
    GenericService.prototype.getToken = function (login) {
        var _this = this;
        return this.http.post(this.tokenURL, login.toString(), { 'headers': this.tokenHeaders })
            .toPromise()
            .then(function (response) {
            localStorage.setItem("token", JSON.stringify(response));
            _this.getHeaders();
            return response;
        })
            .catch(function (err) { return _this.handleError(err); });
    };
    GenericService.prototype.refreshToken = function () {
        var _this = this;
        return this.http.post(this.tokenURL, { "grant_type": "refresh_token", "refresh_token": this.token.refresh_token }, { 'headers': this.tokenHeaders })
            .toPromise()
            .then(function (response) {
            localStorage.setItem("token", JSON.stringify(response));
            _this.getHeaders();
        })
            .catch(function (err) { return _this.handleError(err); });
    };
    GenericService.prototype.handleError = function (response) {
        return Promise.reject(response);
    };
    GenericService.prototype.getHeaders = function () {
        this.token = JSON.parse(localStorage.getItem("token"));
        return new HttpHeaders({
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + (this.token ? this.token.access_token : '')
        });
    };
    GenericService.prototype.getCustomHeaders = function (enctype) {
        this.token = JSON.parse(localStorage.getItem("token"));
        return new HttpHeaders({
            'Content-Type': enctype,
            'Authorization': 'Bearer ' + (this.token ? this.token.access_token : '')
        });
    };
    GenericService.prototype.getHeadersNoContentType = function () {
        this.token = JSON.parse(localStorage.getItem("token"));
        return new HttpHeaders({
            'Authorization': 'Bearer ' + (this.token ? this.token.access_token : '')
        });
    };
    return GenericService;
}());
export { GenericService };
