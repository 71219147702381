import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
import { environment } from 'src/environments/environment';
var DadoFormandoCVService = /** @class */ (function (_super) {
    tslib_1.__extends(DadoFormandoCVService, _super);
    function DadoFormandoCVService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType('dadofomandocv');
        return _this;
    }
    DadoFormandoCVService.prototype.porPessoaId = function (id) {
        return this.http.get("" + environment.moduloFormacao.buscarDadosFormandoCV + id).toPromise();
    };
    DadoFormandoCVService.prototype.cadastrar = function (request) {
        return this.http.post(environment.moduloFormacao.cadastrarDadosFormandoCV, JSON.stringify(request), { 'headers': this.getHeaders() }).toPromise();
    };
    DadoFormandoCVService.prototype.atualizar = function (request) {
        return this.http.put(environment.moduloFormacao.atualizarDadosFormandoCV, JSON.stringify(request), { 'headers': this.getHeaders() }).toPromise();
    };
    return DadoFormandoCVService;
}(GenericService));
export { DadoFormandoCVService };
