import * as tslib_1 from "tslib";
import { VinculoMissao } from '../model/vinculomissao.model';
import { AbstractBuilder } from './abstract.builder';
var VinculoMissaoBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(VinculoMissaoBuilder, _super);
    function VinculoMissaoBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    VinculoMissaoBuilder.prototype.reset = function () {
        this.entity = new VinculoMissao(null, null, null, null, null, null);
    };
    return VinculoMissaoBuilder;
}(AbstractBuilder));
export { VinculoMissaoBuilder };
