import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import 'select2';
import intlTelInput from 'intl-tel-input';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { EnderecoBuilder } from 'src/app/builder/endereco.builder';
import { DocumentoBuilder } from 'src/app/builder/documento.builder';
import { AptidaoBuilder } from 'src/app/builder/aptidao.builder';
import { EscolaridadeBuilder } from 'src/app/builder/escolaridade.builder';
import { TrabalhoBuilder } from 'src/app/builder/trabalho.builder';
import { environment } from 'src/environments/environment';
var PessoaEditComponent = /** @class */ (function () {
    function PessoaEditComponent(pessoaService, enderecoService, paisService, estadoService, cidadeService, imagemService, elementoGrupoDominioService, documentoService, regionalService, missaoService, difusaoService, escolaridadeService, trabalhoService, aptidaoService, swtAlert2Service, usuarioService, route, router) {
        this.pessoaService = pessoaService;
        this.enderecoService = enderecoService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.imagemService = imagemService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.documentoService = documentoService;
        this.regionalService = regionalService;
        this.missaoService = missaoService;
        this.difusaoService = difusaoService;
        this.escolaridadeService = escolaridadeService;
        this.trabalhoService = trabalhoService;
        this.aptidaoService = aptidaoService;
        this.swtAlert2Service = swtAlert2Service;
        this.usuarioService = usuarioService;
        this.route = route;
        this.router = router;
        this.intlTelInputCalled = false;
        this.regionais = new Array();
        this.missoesVinculo = new Array();
        this.difusoesVinculo = new Array();
        this.missoes = new Array();
        this.difusoes = new Array();
        this.paises = new Array();
        this.estados = new Array();
        this.cidades = new Array();
        this.escolaridades = new Array();
        this.trabalhos = new Array();
        this.estadosCivil = new Array();
        this.tiposDocumento = new Array();
        this.formasVida = new Array();
        this.niveisFormacao = new Array();
        this.estadosVida = new Array();
        this.habilidades = new Array();
        this.linguas = new Array();
        this.niveisEscolaridade = new Array();
        this.imageChangedEvent = '';
        this.croppedImage = '';
        this.nextClicked = false;
        this.pessoaBuilder = new PessoaBuilder();
        this.enderecoBuilder = new EnderecoBuilder();
        this.documentoBuilder = new DocumentoBuilder();
        this.aptidaoBuilder = new AptidaoBuilder();
        this.escolaridadeBuilder = new EscolaridadeBuilder();
        this.trabalhoBuilder = new TrabalhoBuilder();
        this.pessoa = this.pessoaBuilder.getInstance();
        this.endereco = this.enderecoBuilder.getInstance();
        this.documento = this.documentoBuilder.getInstance();
        this.aptidao = this.aptidaoBuilder.getInstance();
        this.escolaridade = this.escolaridadeBuilder.getInstance();
        this.trabalho = this.trabalhoBuilder.getInstance();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.url = environment.usuario.selectPorUsername;
    }
    PessoaEditComponent.prototype.ngOnInit = function () {
        this.initializacao();
    };
    PessoaEditComponent.prototype.initializacao = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.pessoaId = Number(this.route.snapshot.paramMap.get('id'));
                        return [4 /*yield*/, this.getUsuarioAtual()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.carregarPaises()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.carregarFormasVida()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.carregarTipoDocumento()];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, this.carregarEstadosCivil()];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, this.carregarNiveisFormacao()];
                    case 6:
                        _a.sent();
                        return [4 /*yield*/, this.carregarEstadoVida()];
                    case 7:
                        _a.sent();
                        return [4 /*yield*/, this.carregarRegionais()];
                    case 8:
                        _a.sent();
                        return [4 /*yield*/, this.carregarLinguas()];
                    case 9:
                        _a.sent();
                        return [4 /*yield*/, this.carregarHabilidades()];
                    case 10:
                        _a.sent();
                        return [4 /*yield*/, this.carregarNiveisEscolaridade()];
                    case 11:
                        _a.sent();
                        return [4 /*yield*/, this.carregarMissoesVinculo()];
                    case 12:
                        _a.sent();
                        return [4 /*yield*/, this.carregarDifusoesVinculo()];
                    case 13:
                        _a.sent();
                        return [4 /*yield*/, this.carregarPessoaStatus()];
                    case 14:
                        _a.sent();
                        $("select[name='linguas-select']").select2({
                            theme: "bootstrap4"
                        });
                        $("select[name='habilidades-select']").select2({
                            theme: "bootstrap4"
                        });
                        return [4 /*yield*/, this.carregarPessoa(this.pessoaId)];
                    case 15:
                        _a.sent();
                        return [4 /*yield*/, this.encontrarDocumentosPorPessoaId(this.pessoaId)];
                    case 16:
                        _a.sent();
                        return [4 /*yield*/, this.encontrarTrabalhoPorPessoaId(this.pessoaId)];
                    case 17:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PessoaEditComponent.prototype.getUsuarioAtual = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuarioAtual = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PessoaEditComponent.prototype.carregarRegionais = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.regionalService.findAll()];
                    case 1:
                        response = _a.sent();
                        this.regionais = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.log(e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PessoaEditComponent.prototype.carregarOrigem = function () {
        if (this.regionalSelecionadoId != undefined) {
            if (this.tipoOrigem == "M") {
                this.carregarMissoes();
            }
            else if (this.tipoOrigem == "D") {
                this.carregarDifusoes();
            }
        }
    };
    PessoaEditComponent.prototype.carregarMissoesVinculo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.missaoService.findAll()];
                    case 1:
                        response = _a.sent();
                        this.missoesVinculo = response.entity;
                        this.missoesVinculo = this.missoesVinculo.sort(function (a, b) { return a.nome.localeCompare(b.nome); });
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PessoaEditComponent.prototype.carregarDifusoesVinculo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.difusaoService.findAll()];
                    case 1:
                        response = _a.sent();
                        this.difusoesVinculo = response.entity;
                        this.difusoesVinculo = this.difusoesVinculo.sort(function (a, b) { return a.nome.localeCompare(b.nome); });
                        return [3 /*break*/, 3];
                    case 2:
                        e_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PessoaEditComponent.prototype.carregarMissoes = function () {
        var _this = this;
        this.missaoService.porRegional(this.regionalSelecionadoId).then(function (response) {
            _this.missoes = response.entity;
            _this.difusoes = null;
        }).catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    PessoaEditComponent.prototype.carregarDifusoes = function () {
        var _this = this;
        this.difusaoService.porRegional(this.regionalSelecionadoId).then(function (response) {
            _this.difusoes = response.entity;
            _this.missoes = null;
        }).catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    PessoaEditComponent.prototype.ngAfterViewChecked = function () {
        if (!this.intlTelInputCalled && $('#pessoa-telefone').length == 1) {
            var input = document.querySelector("#pessoa-telefone");
            this.intlTellInputInstance = intlTelInput(input, {
                initialCountry: "auto",
                geoIpLookup: function (callback) {
                    fetch("https://ipapi.co/json")
                        .then(function (res) { return res.json(); })
                        .then(function (data) { return callback(data.country_code); })
                        .catch(function () { return callback("us"); });
                },
                utilsScript: 'assets/scripts/utils.js'
            });
            this.intlTelInputCalled = true;
        }
    };
    PessoaEditComponent.prototype.carregarPaises = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.paisService.findAll()];
                    case 1:
                        response = _a.sent();
                        this.paises = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_5.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PessoaEditComponent.prototype.carregarEstados = function (paisId) {
        var _this = this;
        var busca = {
            paisId: paisId,
            nome: undefined,
            pageNumber: 0,
            quantityOfElements: 1000
        };
        this.estadoService.buscarEstados(busca).then(function (lista) {
            _this.estados = lista.entity;
        }).catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    };
    PessoaEditComponent.prototype.carregarCidades = function (estadoId) {
        var _this = this;
        var busca = {
            estadoId: estadoId,
            nome: undefined,
            pageNumber: 0,
            quantityOfElements: 1000
        };
        this.cidadeService.buscarCidades(busca).then(function (lista) {
            _this.cidades = lista.entity;
        }).catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    };
    PessoaEditComponent.prototype.carregarPessoa = function (Id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, blogImage, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.pessoaService.find(Id)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        if (!(this.pessoa.imagemId != null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getPessoaImagem(this.pessoa.imagemId)];
                    case 2:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.pessoa);
                        _a.label = 3;
                    case 3:
                        if (this.pessoa.enderecoId != null) {
                            this.carregarEndereco(this.pessoa.enderecoId);
                        }
                        if (this.pessoa.usuarioId != null) {
                            $("select[name='usuario']").append(new Option(this.pessoa.usuarioUsername, this.pessoa.usuarioId.toString(), false, true));
                        }
                        if (this.pessoa.formaVidaId != null) {
                            this.setarFormaVidaSelecionada();
                        }
                        /* if(this.pessoa.obraId != null)
                        {
                            this.carregarObra(this.pessoa.obraId);
                        } */
                        /* if(this.pessoa.membroId != null)
                        {
                            if(this.pessoa.formaVidaValor == 'CA')
                            {
                                this.carregarMembroComunidadeAlianca(this.pessoa.membroId);
                            }
                            else
                            if(this.pessoa.formaVidaValor == 'CV')
                            {
                                this.carregarMembroComunidadeVida(this.pessoa.membroId);
                            }
                        } */
                        if (this.pessoa.aptidaoId != null) {
                            this.carregarAptidao(this.pessoa.aptidaoId);
                        }
                        if (this.pessoa.trabalhoIds.length > 0) {
                            this.encontrarTrabalhoPorPessoaId(this.pessoa.id);
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    PessoaEditComponent.prototype.carregarEndereco = function (enderecoId) {
        var _this = this;
        this.enderecoService.find(enderecoId).then(function (response) {
            _this.endereco = response.entity;
            _this.carregarEstados(_this.endereco.paisId);
            _this.carregarCidades(_this.endereco.estadoId);
        }).catch(function (err) {
            console.log(err);
        });
    };
    /* carregarObra(obraId: number): void {
        this.obraService.find(obraId).then((response: any) => {
            this.obra = response.entity;
        }).catch((err: any) => {
            console.log(err);
        });
    }

    carregarMembroComunidadeAlianca(membroComunidadeAliancaId: number): void {
        this.membroComunidadeAliancaService.find(membroComunidadeAliancaId).then((response: any) => {
            this.membroComunidadeAlianca = response.entity;
            this.regionalSelecionadoId = this.membroComunidadeAlianca.regionalId;
            if(this.membroComunidadeAlianca.missaoOrigemId != null)
            {
                this.tipoOrigem = 'M';
            }
            else if(this.membroComunidadeAlianca.difusaoOrigemId != null)
            {
                this.tipoOrigem = 'D';
            }
            this.carregarOrigem();
        }).catch((err: any) => {
            console.log(err);
        });
    }

    carregarMembroComunidadeVida(membroComunidadeVidaId: number): void {
        this.membroComunidadeVidaService.find(membroComunidadeVidaId).then((response: any) => {
            this.membroComunidadeVida = response.entity;
            this.regionalSelecionadoId = this.membroComunidadeVida.regionalId;
            if(this.membroComunidadeVida.missaoOrigemId != null)
            {
                this.tipoOrigem = 'M';
            }
            else if(this.membroComunidadeVida.difusaoOrigemId != null)
            {
                this.tipoOrigem = 'D';
            }
            this.carregarOrigem();
        }).catch((err: any) => {
            console.log(err);
        });
    } */
    PessoaEditComponent.prototype.carregarAptidao = function (aptidaoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.aptidaoService.find(aptidaoId)];
                    case 1:
                        response = _a.sent();
                        this.aptidao = response.entity;
                        this.encontrarEscolaridadesPorAptidaoId(this.aptidao.id);
                        $("select[name='linguas-select']").val(this.aptidao.linguaIds).trigger('change');
                        $("select[name='habilidades-select']").val(this.aptidao.habilidadeIds).trigger('change');
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        console.log(err_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/, null];
                }
            });
        });
    };
    PessoaEditComponent.prototype.encontrarDocumentosPorPessoaId = function (pessoaId) {
        var _this = this;
        this.documentoService.buscarPorPessoaId(pessoaId).then(function (response) {
            _this.documentos = response.entity;
        }).catch(function (err) {
            console.log(err);
        });
    };
    PessoaEditComponent.prototype.encontrarEscolaridadesPorAptidaoId = function (aptidaoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.escolaridadeService.findByAptidaoId(aptidaoId)];
                    case 1:
                        response = _a.sent();
                        this.escolaridades = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        err_3 = _a.sent();
                        console.log(err_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/, null];
                }
            });
        });
    };
    PessoaEditComponent.prototype.encontrarTrabalhoPorPessoaId = function (pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.trabalhoService.findByPessoaId(pessoaId)];
                    case 1:
                        response = _a.sent();
                        this.trabalhos = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        err_4 = _a.sent();
                        console.log(err_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/, null];
                }
            });
        });
    };
    PessoaEditComponent.prototype.carregarTipoDocumento = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var buscaDocumentos, response, e_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        buscaDocumentos = {
                            grupoNome: 'TIPOS_DOCUMENTOS_PESSOA'
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaDocumentos)];
                    case 2:
                        response = _a.sent();
                        this.tiposDocumento = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        e_6 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_6.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PessoaEditComponent.prototype.carregarEstadosCivil = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var buscaEstadosCivil, response, e_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        buscaEstadosCivil = {
                            grupoNome: 'ESTADO_CIVIL'
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaEstadosCivil)];
                    case 2:
                        response = _a.sent();
                        this.estadosCivil = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        e_7 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_7.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PessoaEditComponent.prototype.carregarFormasVida = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var buscaFormaVida, response, e_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        buscaFormaVida = {
                            grupoNome: 'FORMA_VIDA'
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFormaVida)];
                    case 2:
                        response = _a.sent();
                        this.formasVida = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        e_8 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_8.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PessoaEditComponent.prototype.carregarNiveisFormacao = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var busca, response, e_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        busca = {
                            grupoNome: 'NIVEL_FORMACAO'
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca)];
                    case 2:
                        response = _a.sent();
                        this.niveisFormacao = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        e_9 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_9.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PessoaEditComponent.prototype.carregarEstadoVida = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var busca, response, e_10;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        busca = {
                            grupoNome: 'ESTADO_VIDA'
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca)];
                    case 2:
                        response = _a.sent();
                        this.estadosVida = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        e_10 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_10.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PessoaEditComponent.prototype.carregarHabilidades = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var busca, response, e_11;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        busca = {
                            grupoNome: 'HABILIDADE'
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca)];
                    case 2:
                        response = _a.sent();
                        this.habilidades = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        e_11 = _a.sent();
                        console.log(e_11);
                        return [3 /*break*/, 4];
                    case 4:
                        ;
                        return [2 /*return*/, null];
                }
            });
        });
    };
    PessoaEditComponent.prototype.carregarLinguas = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var busca, response, e_12;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        busca = {
                            grupoNome: 'LINGUA'
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca)];
                    case 2:
                        response = _a.sent();
                        this.linguas = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        e_12 = _a.sent();
                        console.log(e_12);
                        return [3 /*break*/, 4];
                    case 4:
                        ;
                        return [2 /*return*/, null];
                }
            });
        });
    };
    PessoaEditComponent.prototype.carregarNiveisEscolaridade = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var busca, response, e_13;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        busca = {
                            grupoNome: 'NIVEL_ESCOLARIDADE'
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca)];
                    case 2:
                        response = _a.sent();
                        this.niveisEscolaridade = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        e_13 = _a.sent();
                        console.log(e_13);
                        return [3 /*break*/, 4];
                    case 4:
                        ;
                        return [2 /*return*/, null];
                }
            });
        });
    };
    PessoaEditComponent.prototype.carregarPessoaStatus = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var busca, response, e_14;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        busca = {
                            grupoNome: 'PESSOA_STATUS'
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca)];
                    case 2:
                        response = _a.sent();
                        this.pessoaStatus = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        e_14 = _a.sent();
                        console.log(e_14);
                        return [3 /*break*/, 4];
                    case 4:
                        ;
                        return [2 /*return*/, null];
                }
            });
        });
    };
    Object.defineProperty(PessoaEditComponent.prototype, "valorTipoDocumento", {
        get: function () {
            var _this = this;
            var tipoSelecionado = this.tiposDocumento.filter(function (tipo) {
                return (tipo.id == _this.documento.elementoGrupoDominioTipoId);
            });
            return tipoSelecionado.length > 0 ? tipoSelecionado[0].valor : '';
        },
        enumerable: true,
        configurable: true
    });
    PessoaEditComponent.prototype.logOnConsole = function (dadosPessoaisForm) {
        console.log(dadosPessoaisForm);
    };
    PessoaEditComponent.prototype.activateTab = function (tab) {
        $('.nav-tabs a[href="#' + tab + '"]').removeClass('disabled');
        $('.nav-tabs a[href="#' + tab + '"]').tab('show');
    };
    PessoaEditComponent.prototype.setarElementoSelecionado = function (valor) {
        this.pessoa.usuarioId = valor;
    };
    PessoaEditComponent.prototype.salvar = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, blogImage, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.pessoaService.update(pessoa.id, pessoa)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        if (!(this.pessoa.imagemId != null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getPessoaImagem(this.pessoa.imagemId)];
                    case 2:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.pessoa);
                        _a.label = 3;
                    case 3:
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 5];
                    case 4:
                        err_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_5.error.errors);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    PessoaEditComponent.prototype.salvarEndereco = function (endereco) {
        var _this = this;
        if (this.endereco.id == null) {
            this.enderecoService.create(endereco).then(function (response) {
                _this.endereco = response.entity;
                _this.pessoa.enderecoId = response.entity.id;
                _this.salvar(_this.pessoa);
            }).catch(function (err) {
                _this.swtAlert2Service.errorAlert(err.error.errors);
                console.log(err);
            });
        }
        else {
            this.enderecoService.update(endereco.id, endereco).then(function (response) {
                _this.endereco = response.entity;
                _this.swtAlert2Service.successAlert(response.message);
            }).catch(function (err) {
                _this.swtAlert2Service.errorAlert(err.error.errors);
                console.log(err);
            });
        }
    };
    PessoaEditComponent.prototype.salvarImagem = function () {
        var _this = this;
        this.imagemService.salvarCropped({ 'imageData': this.croppedImage }).then(function (response) {
            _this.swtAlert2Service.successAlert(response.message);
            _this.pessoa.imagemId = response.entity.id;
            _this.salvar(_this.pessoa);
            $('#modal-pessoa-imagem').modal('hide');
        }).catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    };
    PessoaEditComponent.prototype.salvarDocumento = function (documento) {
        var _this = this;
        documento.pessoaId = this.pessoa.id;
        this.documentoService.create(documento).then(function (response) {
            _this.documento = _this.documentoBuilder.getInstance();
            _this.pessoa.documentosId.push(response.entity.id);
            _this.encontrarDocumentosPorPessoaId(_this.pessoaId);
            _this.swtAlert2Service.successAlert(response.message);
        }).catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    };
    PessoaEditComponent.prototype.salvarAptidao = function (aptidao) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.aptidao.pessoaId = this.pessoa.id;
                        this.aptidao.linguaIds = $("select[name='linguas-select']").val();
                        this.aptidao.habilidadeIds = $("select[name='habilidades-select']").val();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        if (!(this.aptidao.id == null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.aptidaoService.createAsync(aptidao)];
                    case 2:
                        response = _a.sent();
                        this.aptidao = response.entity;
                        this.pessoa.aptidaoId = this.aptidao.id;
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.aptidaoService.updateAsync(aptidao.id, aptidao)];
                    case 4:
                        response = _a.sent();
                        this.aptidao = response.entity;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_6 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_6.error.errors);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/, null];
                }
            });
        });
    };
    PessoaEditComponent.prototype.salvarEscolaridade = function (escolaridade) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.aptidao.id == null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.salvarAptidao(this.aptidao)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.escolaridade.aptidaoId = this.aptidao.id;
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 5, , 6]);
                        return [4 /*yield*/, this.escolaridadeService.createAsync(escolaridade)];
                    case 4:
                        response = _a.sent();
                        this.escolaridade = response.entity;
                        this.aptidao.escolaridadeIds.push(this.escolaridade.id);
                        this.encontrarEscolaridadesPorAptidaoId(this.aptidao.id);
                        this.escolaridade = this.escolaridadeBuilder.getInstance();
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 6];
                    case 5:
                        err_7 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_7.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/, null];
                }
            });
        });
    };
    PessoaEditComponent.prototype.salvarTrabalho = function (trabalho) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.trabalho.pessoaId = this.pessoa.id;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.trabalhoService.createAsync(trabalho)];
                    case 2:
                        response = _a.sent();
                        this.pessoa.trabalhoIds.push(response.entity.id);
                        this.encontrarTrabalhoPorPessoaId(this.pessoa.id);
                        this.trabalho = this.trabalhoBuilder.getInstance();
                        this.swtAlert2Service.successAlert(response.message);
                        return [3 /*break*/, 4];
                    case 3:
                        err_8 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_8.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, null];
                }
            });
        });
    };
    PessoaEditComponent.prototype.adicionarTelefone = function () {
        if (this.intlTellInputInstance.getNumber().localeCompare("") != 0) {
            this.pessoa.telefones.push(this.intlTellInputInstance.getNumber());
            this.salvar(this.pessoa);
            $("#pessoa-telefone").val("");
        }
    };
    PessoaEditComponent.prototype.removerTelefone = function (tel) {
        if (confirm('Deseja deletar este telefone?')) {
            var index = this.pessoa.telefones.indexOf(tel);
            if (index > -1) {
                this.pessoa.telefones.splice(index, 1);
                this.salvar(this.pessoa);
            }
        }
    };
    PessoaEditComponent.prototype.removerDocumento = function (id) {
        var _this = this;
        if (confirm('Deseja deletar este documento?')) {
            this.documentoService.delete(id).then(function (response) {
                _this.swtAlert2Service.successAlert(response.message);
                _this.encontrarDocumentosPorPessoaId(_this.pessoaId);
            }).catch(function (err) {
                _this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    };
    PessoaEditComponent.prototype.removerEscolaridade = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!confirm('Deseja deletar esta escolaridade?')) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.escolaridadeService.deleteAsync(id)];
                    case 2:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        this.carregarAptidao(this.pessoa.aptidaoId);
                        return [3 /*break*/, 4];
                    case 3:
                        err_9 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_9.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, null];
                }
            });
        });
    };
    PessoaEditComponent.prototype.removerTrabalho = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_10;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!confirm('Deseja deletar este trabalho?')) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.trabalhoService.deleteAsync(id)];
                    case 2:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        this.carregarPessoa(this.pessoa.id);
                        this.encontrarTrabalhoPorPessoaId(this.pessoa.id);
                        return [3 /*break*/, 4];
                    case 3:
                        err_10 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_10.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, null];
                }
            });
        });
    };
    PessoaEditComponent.prototype.fileChangeEvent = function (event) {
        this.imageChangedEvent = event;
        console.log(event);
    };
    PessoaEditComponent.prototype.imageCropped = function (event) {
        this.croppedImage = event.base64;
    };
    PessoaEditComponent.prototype.imageLoaded = function () {
        console.log('Imagem carregada');
    };
    PessoaEditComponent.prototype.cropperReady = function () {
        console.log('Imagem cortada');
    };
    PessoaEditComponent.prototype.loadImageFailed = function () {
        console.log('Carregamento da imagem falhou!');
    };
    PessoaEditComponent.prototype.setarFormaVidaSelecionada = function () {
        for (var i = 0; i < this.formasVida.length; i++) {
            if (this.formasVida[i].id == this.pessoa.formaVidaId) {
                this.formaVidaSelecionada = this.formasVida[i];
            }
        }
    };
    PessoaEditComponent.prototype.setarNivelEscolaridadeSelecionado = function () {
        for (var i = 0; i < this.niveisEscolaridade.length; i++) {
            if (this.niveisEscolaridade[i].id == this.escolaridade.nivelEscolaridadeId) {
                this.nivelEscolaridadeSelecionado = this.niveisEscolaridade[i];
            }
        }
    };
    PessoaEditComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_11;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_11 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_11.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return PessoaEditComponent;
}());
export { PessoaEditComponent };
