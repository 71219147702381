import * as tslib_1 from "tslib";
import { AbstractBuilder } from './abstract.builder';
import { CriarLinkCadastroRequest } from '../request/criarlinkcadastro.request';
var CriarLinkCadastroRequestBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(CriarLinkCadastroRequestBuilder, _super);
    function CriarLinkCadastroRequestBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CriarLinkCadastroRequestBuilder.prototype.reset = function () {
        this.entity = new CriarLinkCadastroRequest(null, null, null);
    };
    return CriarLinkCadastroRequestBuilder;
}(AbstractBuilder));
export { CriarLinkCadastroRequestBuilder };
