import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
var RemocaoMembroCasaComunitariaComponent = /** @class */ (function () {
    function RemocaoMembroCasaComunitariaComponent(imagemService, missionarioMissaoAtualService, pessoaService, salvarMissionarioMissaoAtualBuilder, remocaoMembroCasaComunitariaBuilder, elementoGrupoDominioService, route, router, swtAlert2Service) {
        this.imagemService = imagemService;
        this.missionarioMissaoAtualService = missionarioMissaoAtualService;
        this.pessoaService = pessoaService;
        this.salvarMissionarioMissaoAtualBuilder = salvarMissionarioMissaoAtualBuilder;
        this.remocaoMembroCasaComunitariaBuilder = remocaoMembroCasaComunitariaBuilder;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.eventEmitter = new EventEmitter();
        this.remocaoMembroCasaComunitaria = this.remocaoMembroCasaComunitariaBuilder.getInstance();
        this.motivosSaida = new Array();
    }
    RemocaoMembroCasaComunitariaComponent.prototype.ngOnInit = function () {
        this.initializer();
    };
    RemocaoMembroCasaComunitariaComponent.prototype.initializer = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var blogImage;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.carregarMotivoSaidaCasaComunitaria();
                        if (!(this.membroCasaComunitaria != null && this.membroCasaComunitaria.imagemId != null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getPessoaImagem(this.membroCasaComunitaria.imagemId)];
                    case 1:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.membroCasaComunitaria);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    RemocaoMembroCasaComunitariaComponent.prototype.carregarMotivoSaidaCasaComunitaria = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var busca, response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        busca = {
                            grupoNome: 'MOTIVO_SAIDA_CASA_COMUNITARIA'
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca)];
                    case 2:
                        response = _a.sent();
                        this.motivosSaida = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_1.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    RemocaoMembroCasaComunitariaComponent.prototype.removerMembroCasaComunitaria = function (membroCasaComunitaria) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!confirm("Confirma a remoção do missionário da casa comunitária?")) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.remocaoMembroCasaComunitaria.missionarioMissaoAtualId = membroCasaComunitaria.missionarioMissaoAtualId;
                        return [4 /*yield*/, this.missionarioMissaoAtualService.removerMembroDaCasaComunitaria(this.remocaoMembroCasaComunitaria)];
                    case 2:
                        response = _a.sent();
                        this.resetarFormulario();
                        this.swtAlert2Service.successAlert("Missionário removido da casa comunitária com sucesso!");
                        this.eventEmitter.emit("recarregar_membros_casa_comunitaria");
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    RemocaoMembroCasaComunitariaComponent.prototype.resetarFormulario = function () {
        this.remocaoMembroCasaComunitaria = this.remocaoMembroCasaComunitariaBuilder.getInstance();
        this.exibirCampoDescricaoMotivoSaida = false;
        $('#' + this.modalId).modal('hide');
    };
    RemocaoMembroCasaComunitariaComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    RemocaoMembroCasaComunitariaComponent.prototype.exibirSeMotivoSaidaForOutro = function (idMotivoSaida) {
        var e_2, _a;
        if (this.motivosSaida.length > 0) {
            try {
                for (var _b = tslib_1.__values(this.motivosSaida), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var motivo = _c.value;
                    if (motivo.id == idMotivoSaida) {
                        if (motivo.valor == 'O') {
                            this.exibirCampoDescricaoMotivoSaida = true;
                            break;
                        }
                        else {
                            this.exibirCampoDescricaoMotivoSaida = false;
                            break;
                        }
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        else {
            this.exibirCampoDescricaoMotivoSaida = false;
        }
    };
    return RemocaoMembroCasaComunitariaComponent;
}());
export { RemocaoMembroCasaComunitariaComponent };
