import * as tslib_1 from "tslib";
var RouterManagerService = /** @class */ (function () {
    function RouterManagerService(router) {
        this.router = router;
    }
    RouterManagerService.prototype.verificarUsuario = function (usuarioAtual) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (!this.jaExecutado && window.location.pathname.match('/secured*') != null) {
                    this.jaExecutado = true;
                    if (usuarioAtual.autorizacoesNome.includes('ROLE_ADMIN')) {
                        this.router.navigate(['/secured/']);
                    }
                    else if (usuarioAtual.autorizacoesNome.includes('ROLE_MISSIONARIO')
                        ||
                            usuarioAtual.autorizacoesNome.includes('ROLE_FORMADOR_COMUNITARIO')) {
                        this.router.navigate(['/secured/local/bemvindo']);
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    return RouterManagerService;
}());
export { RouterManagerService };
