import * as tslib_1 from "tslib";
import { SalvarMissionarioMissaoAtual } from '../model/salvarmissionariomissaoatual.model';
import { AbstractBuilder } from './abstract.builder';
var SalvarMissionarioMissaoAtualBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(SalvarMissionarioMissaoAtualBuilder, _super);
    function SalvarMissionarioMissaoAtualBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SalvarMissionarioMissaoAtualBuilder.prototype.reset = function () {
        this.entity = new SalvarMissionarioMissaoAtual(null, null, null, null);
    };
    return SalvarMissionarioMissaoAtualBuilder;
}(AbstractBuilder));
export { SalvarMissionarioMissaoAtualBuilder };
