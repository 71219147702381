import * as tslib_1 from "tslib";
import { AbstractBuilder } from './abstract.builder';
import { QuestionarioFormando } from '../model/questionarioformando.model';
var QuestionarioFormandoBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(QuestionarioFormandoBuilder, _super);
    function QuestionarioFormandoBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    QuestionarioFormandoBuilder.prototype.reset = function () {
        this.entity = new QuestionarioFormando(null, null, null, null, null, null);
    };
    return QuestionarioFormandoBuilder;
}(AbstractBuilder));
export { QuestionarioFormandoBuilder };
