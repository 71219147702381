import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { MembroComunidadeBuilder } from "src/app/builder/membro.comunidade.builder";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
var ParecerCelulaAutoridadeComponent = /** @class */ (function () {
    function ParecerCelulaAutoridadeComponent(missaoConfiguracaoService, usuarioService, imagemService, pessoaService, celulaService, membroService, formadorComunitarioService, questionarioFormandoBuilder, questionarioFormandoService, parecerResponsavelBuilder, parecerResponsavelService, elementoGrupoDominioLocalService, caminhoFormativoService, route, router, swtAlert2Service) {
        this.missaoConfiguracaoService = missaoConfiguracaoService;
        this.usuarioService = usuarioService;
        this.imagemService = imagemService;
        this.pessoaService = pessoaService;
        this.celulaService = celulaService;
        this.membroService = membroService;
        this.formadorComunitarioService = formadorComunitarioService;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelBuilder = parecerResponsavelBuilder;
        this.parecerResponsavelService = parecerResponsavelService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.totalDePaginas = 1;
        this.pessoaBuilder = new PessoaBuilder();
        this.membrosCelula = new Array();
        this.membroComunidadeBuilder = new MembroComunidadeBuilder();
        this.membroComunidade = this.membroComunidadeBuilder.getInstance();
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
    }
    ParecerCelulaAutoridadeComponent.prototype.ngOnInit = function () {
        this.initializer();
        this.hasCelulaAssociada = false;
    };
    ParecerCelulaAutoridadeComponent.prototype.initializer = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.carregarUsuario()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.carregarPessoa()];
                    case 2:
                        _a.sent();
                        this.celulaId = Number(this.route.snapshot.paramMap.get("id"));
                        return [4 /*yield*/, this.carregarCorStatusVocacional()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.carregarMembrosCelula()];
                    case 4:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaAutoridadeComponent.prototype.carregarQuestionarioConfiguracao = function (questionarioId) {
        var _this = this;
        var missaoId = this.pessoa.vinculoMissao.missaoAtualId;
        this.missaoConfiguracaoService
            .obterConfiguracaoQuestionarioPorMissaoEQuestionario(missaoId, questionarioId)
            .subscribe(function (questionarioConfiguracao) {
            return (_this.questionarioConfiguracao = questionarioConfiguracao);
        });
    };
    ParecerCelulaAutoridadeComponent.prototype.carregarUsuario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuario = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaAutoridadeComponent.prototype.carregarPessoa = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.pessoaService.buscaPorUsuarioId(this.usuario.id)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.log(e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaAutoridadeComponent.prototype.carregarCorStatusVocacional = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, e_3;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this;
                        return [4 /*yield*/, this.elementoGrupoDominioLocalService.buscarPorGrupoDominioNomeModuloFormacao("COR_STATUS_VOCACIONAL")];
                    case 1:
                        _a.listaCorStatusVocacional =
                            _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _b.sent();
                        console.log(e_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaAutoridadeComponent.prototype.carregarQuestionario = function (pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.questionarioFormandoService.buscarPorPessoaId(pessoaId)];
                    case 1:
                        response = _a.sent();
                        if (response != undefined && response != null) {
                            return [2 /*return*/, response];
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaAutoridadeComponent.prototype.carregarMembrosCelula = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, questionarioFormando, err_2;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, , 5]);
                        _a = this;
                        return [4 /*yield*/, this.membroService.listarMembrosCelula(this.celulaId)];
                    case 1:
                        _a.membrosCelula = _b.sent();
                        this.totalDePaginasMembros = 1;
                        if (this.membrosCelula.length > 0) {
                            this.membrosCelula = this.membrosCelula.sort(function (a, b) {
                                return a.pessoaNome.localeCompare(b.pessoaNome);
                            });
                        }
                        this.membrosCelula.forEach(function (m) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var _a, blogImage;
                            return tslib_1.__generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        if (!(!this.questionarioFormando || !this.questionarioFormando.id)) return [3 /*break*/, 2];
                                        _a = this;
                                        return [4 /*yield*/, this.carregarQuestionario(m.pessoaId)];
                                    case 1:
                                        _a.questionarioFormando = _b.sent();
                                        if (this.questionarioFormando) {
                                            if (!this.questionarioConfiguracao) {
                                                this.carregarQuestionarioConfiguracao(this.questionarioFormando.questionarioId);
                                            }
                                        }
                                        _b.label = 2;
                                    case 2:
                                        if (!this.questionarioConfiguracao) {
                                            this.carregarQuestionarioConfiguracao(this.questionarioFormando.questionarioId);
                                        }
                                        if (!(m.imagemId != null)) return [3 /*break*/, 4];
                                        return [4 /*yield*/, this.getPessoaImagem(this.pessoa.imagemId)];
                                    case 3:
                                        blogImage = _b.sent();
                                        this.imagemService.createImage(blogImage, m);
                                        _b.label = 4;
                                    case 4: return [2 /*return*/];
                                }
                            });
                        }); });
                        if (!(this.membrosCelula.length > 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.carregarQuestionario(this.membrosCelula[0].pessoaId)];
                    case 2:
                        questionarioFormando = _b.sent();
                        if (questionarioFormando != null) {
                            if (!this.questionarioConfiguracao) {
                                this.carregarQuestionarioConfiguracao(questionarioFormando.questionarioId);
                            }
                        }
                        _b.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        err_2 = _b.sent();
                        this.swtAlert2Service.errorAlert(JSON.stringify(err_2.error));
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaAutoridadeComponent.prototype.cadastrarConsideracaoConselhoLocal = function (membro) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.caminhoFormativoService.cadastrarConsideracaoConselhoLocal(membro)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.carregarMembrosCelula()];
                    case 2:
                        _a.sent();
                        this.swtAlert2Service.successAlert("Parecer de consideração do Conselho Local atualizado com sucesso!");
                        return [3 /*break*/, 4];
                    case 3:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaAutoridadeComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaAutoridadeComponent.prototype.cliqueNoBotaoListener = function (botao) {
        this.carregarMembrosCelula();
    };
    ParecerCelulaAutoridadeComponent.prototype.concordanciaConselhoLocal = function (membro) {
        if (membro.consideracaoCondorda === true) {
            membro.caminhoFormativoFeedback =
                membro.caminhoFormativoSugeridoFormadorComunitario;
            membro.consideracaoConselhoLocal = "";
        }
        else {
            membro.caminhoFormativoFeedback = "";
        }
    };
    ParecerCelulaAutoridadeComponent.prototype.copiarTextoConsideracaoConselhoLocalParaFeedback = function (membro) {
        membro.caminhoFormativoFeedback = membro.consideracaoConselhoLocal;
    };
    ParecerCelulaAutoridadeComponent.prototype.obterCorDaLinha = function (membro) {
        var cssObject = {};
        if (membro.corStatusVocacionalValor != null) {
            cssObject["background-color"] = membro.corStatusVocacionalValor;
        }
        if (membro.corStatusVocacionalNome != null &&
            membro.corStatusVocacionalNome.localeCompare("Desligamento") == 0) {
            cssObject["color"] = "#fff";
        }
        return cssObject;
    };
    return ParecerCelulaAutoridadeComponent;
}());
export { ParecerCelulaAutoridadeComponent };
