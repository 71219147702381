import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var AceiteTermoTratamentoDadosComponent = /** @class */ (function () {
    function AceiteTermoTratamentoDadosComponent(cadastroPublicoService, aceiteTermoTratamentoDadosService, termoTratamentoDadosBuilder, aceiteTermoTratamentoDadosBuilder, swtAlert2Service, router) {
        this.cadastroPublicoService = cadastroPublicoService;
        this.aceiteTermoTratamentoDadosService = aceiteTermoTratamentoDadosService;
        this.termoTratamentoDadosBuilder = termoTratamentoDadosBuilder;
        this.aceiteTermoTratamentoDadosBuilder = aceiteTermoTratamentoDadosBuilder;
        this.swtAlert2Service = swtAlert2Service;
        this.router = router;
        this.termoVigente = this.termoTratamentoDadosBuilder.getInstance();
        this.aceiteTermoUso = this.aceiteTermoTratamentoDadosBuilder.getInstance();
    }
    AceiteTermoTratamentoDadosComponent.prototype.ngOnInit = function () {
        this.initialize();
    };
    AceiteTermoTratamentoDadosComponent.prototype.initialize = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.didUserAcceptDataProcessingTerm()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.carregarTermoVigente()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AceiteTermoTratamentoDadosComponent.prototype.didUserAcceptDataProcessingTerm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, usuarioAceitouTermoTratamentoDados, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.aceiteTermoTratamentoDadosService.usuarioAceitouTermo()];
                    case 1:
                        response = _a.sent();
                        usuarioAceitouTermoTratamentoDados = response.entity;
                        if (usuarioAceitouTermoTratamentoDados) {
                            this.router.navigate(['/secured/']);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_1.error.errors);
                        this.router.navigate(['/login']);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AceiteTermoTratamentoDadosComponent.prototype.carregarTermoVigente = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.cadastroPublicoService.getTermoTratamentoDadosVigente()];
                    case 1:
                        response = _a.sent();
                        this.termoVigente = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AceiteTermoTratamentoDadosComponent.prototype.aceitarTermoTratamentoDados = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_3;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.aceiteTermoTratamentoDadosService.aceitarTermoVigente()];
                    case 1:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.entity);
                        sessionStorage.setItem("usuarioAceitouTermoTratamentoDados", "1");
                        setTimeout(function () {
                            _this.router.navigate(['/secured/']);
                        }, 3000);
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_3.error.errors);
                        this.router.navigate(['/login']);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return AceiteTermoTratamentoDadosComponent;
}());
export { AceiteTermoTratamentoDadosComponent };
