import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { environment } from "src/environments/environment";
var ListagemPessoaInfoModalComponent = /** @class */ (function () {
    function ListagemPessoaInfoModalComponent(pessoaService, paisService, missaoService, wopGetAllPublic, swtAlert2Service, imagemService) {
        this.pessoaService = pessoaService;
        this.paisService = paisService;
        this.missaoService = missaoService;
        this.wopGetAllPublic = wopGetAllPublic;
        this.swtAlert2Service = swtAlert2Service;
        this.imagemService = imagemService;
        this.eventEmitter = new EventEmitter();
        this.pessoas = new Array();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.paises = new Array();
        this.missoes = new Array();
        this.difusoes = new Array();
        this.filtrosExtras = new Array();
        this.busca = {
            nome: null,
            sobrenome: null,
            genero: null,
            anonascimento: null,
            nacionalidadeId: null,
            missaoAtualId: null,
            pageNumber: 0,
            quantityOfElements: 10
        };
    }
    ListagemPessoaInfoModalComponent.prototype.ngOnInit = function () {
        this.carregarPaises();
        this.carregarMissoes();
        this.buscarPessoas();
    };
    ListagemPessoaInfoModalComponent.prototype.carregarPaises = function () {
        var _this = this;
        this.paisService.findAll().then(function (lista) {
            _this.paises = lista.entity.sort(function (a, b) { return a.nome.trim().localeCompare(b.nome.trim()); });
        }).catch(function (err) {
            console.log(err);
        });
    };
    ListagemPessoaInfoModalComponent.prototype.buscarPessoas = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, i, blogImage, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.busca.missionario = (this.onlyMissionaries != null && this.onlyMissionaries != undefined) ? this.onlyMissionaries : null;
                        if (this.bringNotInformedWayOfLife == true) {
                            this.busca.formaVidaId = 0;
                        }
                        else if (this.bringNotInformedWayOfLife == false) {
                            this.busca.formaVidaId = -1;
                        }
                        this.busca.filtrarPorAcessoUsuario = this.filterByUserAccess;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        return [4 /*yield*/, this.pessoaService.pesquisarPessoasInfoListagem(this.busca)];
                    case 2:
                        response = _a.sent();
                        this.pessoas = response.entity;
                        this.totalDePaginas = response.quantity;
                        i = 0;
                        _a.label = 3;
                    case 3:
                        if (!(i < this.pessoas.length)) return [3 /*break*/, 6];
                        if (!(this.pessoas[i].imagemId != null)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.getPessoaImagem(this.pessoas[i].imagemId)];
                    case 4:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.pessoas[i]);
                        _a.label = 5;
                    case 5:
                        i++;
                        return [3 /*break*/, 3];
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    ListagemPessoaInfoModalComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ListagemPessoaInfoModalComponent.prototype.carregarMissoes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        response = null;
                        if (!(this.filterByUserAccess == undefined || this.filterByUserAccess == true)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.missaoService.findAll()];
                    case 1:
                        response = _a.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        if (!(this.filterByUserAccess == false)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.wopGetAllPublic.getAllMissao()];
                    case 3:
                        response = _a.sent();
                        _a.label = 4;
                    case 4:
                        this.missoes = response.entity.sort(function (a, b) { return a.nome.localeCompare(b.nome.trim()); });
                        return [3 /*break*/, 6];
                    case 5:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ListagemPessoaInfoModalComponent.prototype.cliqueNoBotaoListener = function (botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.buscarPessoas();
    };
    ListagemPessoaInfoModalComponent.prototype.getUrlImagem = function (imagemId) {
        return this.urlBaseImagem + imagemId;
    };
    ListagemPessoaInfoModalComponent.prototype.pessoaSelecionada = function (pessoa) {
        this.eventEmitter.emit(pessoa);
    };
    ListagemPessoaInfoModalComponent.prototype.liberarFiltro = function (filtro) {
        return this.filtrosExtras.includes(filtro);
    };
    return ListagemPessoaInfoModalComponent;
}());
export { ListagemPessoaInfoModalComponent };
